import PropTypes from 'prop-types'

import './index.scss'

function BannerText({
	title,
	bordered,
	children,
	textNoBg,
	textBold,
	className,
}) {
	const textIsNoBg = textNoBg
		? ' SOUKIASSIAN-BannerText-text-containerNoBg'
		: ''
	const textIsBold = textBold ? ' SOUKIASSIAN-BannerText-textBold' : ''
	const classIsBordered = bordered
		? ' SOUKIASSIAN-BannerText-title-container-bordered'
		: ''

	return (
		<div className={`SOUKIASSIAN-BannerText ${className}`}>
			{title && (
				<div
					className={`SOUKIASSIAN-BannerText-title-container${classIsBordered}`}
				>
					<h2 className="SOUKIASSIAN-BannerText-title col-max-blocked">
						{title}
					</h2>
				</div>
			)}
			{children && (
				<div
					className={`SOUKIASSIAN-BannerText-text-container${textIsNoBg}`}
				>
					<p
						className={`SOUKIASSIAN-BannerText-text col-max-blocked${textIsBold}`}
					>
						{children}
					</p>
				</div>
			)}
		</div>
	)
}

BannerText.propTypes = {
	title: PropTypes.any,
	bordered: PropTypes.bool.isRequired,
	children: PropTypes.any,
	textNoBG: PropTypes.bool.isRequired,
	textBold: PropTypes.bool.isRequired,
	className: PropTypes.string,
}

BannerText.defaultProps = {
	bordered: false,
	textNoBG: false,
	textBold: false,
	className: '',
}

export default BannerText
