import {
	GET_CLIENTS,
	ADD_CLIENT,
	UPDATE_CLIENT,
	LOGIN_CLIENT,
	LOGOUT_CLIENT,
} from '../actions/client.action'

const initialState = { isConnected: false }

export default function clientReducer(state = initialState, action) {
	switch (action.type) {
		case GET_CLIENTS:
			return { ...state, list: [...action.payload] }
		case ADD_CLIENT:
			return { ...state, list: [...state.list, action.payload] }
		case UPDATE_CLIENT:
			if (action.isClient) {
				return {
					...state,
					client: action.payload,
				}
			} else {
				return {
					...state,
					list: updateById(state.list, action.payload),
				}
			}
		case LOGIN_CLIENT:
			return {
				...state,
				isConnected: action.payload.isConnected === true ? true : false,
				client: action.payload.client,
			}
		case LOGOUT_CLIENT:
			return { ...initialState }
		default:
			return { ...state }
	}
}

function updateById(clientList, updatedClient) {
	return clientList.map((client) => {
		if (updatedClient.id === client.id) {
			return { ...updatedClient }
		} else {
			return client
		}
	})
}
