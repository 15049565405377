import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import ImageTextContainer from '../../../../components/ImageTextContainer'
import ImageText from '../../../../components/ImageTextContainer/ImageText'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import Button from '../../../../components/Button'

import SessionLifeCoachingDoc from '../../../../assets/documents/Session/Brochure_Coacher-sa-vie.pdf'

function SessionLifeCoaching() {
	const pageId = 12

	return (
		<div className="SOUKIASSIAN-SessionLifeCoaching">
			<Header
				type="finalPage"
				title="SÉANCE POUR COACHER SA VIE"
				subTitle="Jeune et adulte"
			/>

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={1}
					text={
						<>
							<span>
								L'école L.C.C. © propose des séances
								individuelles innovantes en coaching de vie.
							</span>
							<span>
								Vous{' '}
								<span className="underline">
									développez votre potentiel véritable
								</span>{' '}
								et{' '}
								<span className="underline">
									accédez à une meilleure qualité de vie.
								</span>
							</span>
							<span>
								Accessibles à tous, les séances vous donnent{' '}
								<span className="underline">
									les solutions pour évoluer facilement en
									conscience.
								</span>
							</span>
							<span>
								Transformez votre vie en une aventure paisible
								et harmonieuse.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={2}
					flippedImage
					text={
						<>
							<span>
								Les séances comprennent une communication
								intelligente.
							</span>
							<span>
								Le coach accompagne depuis plus de 15 ans tous
								les objectifs de la vie personnelle, conjugale,
								familiale, sociale et professionnelle.
							</span>
						</>
					}
					left
				/>
				<ImageText
					pageId={pageId}
					entityId={3}
					flippedImage
					text={
						<>
							<span>
								Les séances s'articulent brillamment autour
								d'une communication intuitive et consciente.
							</span>
							<span>
								Nos séances offrent une découverte unique de soi
								pour des réalisations allant jusqu'à
								l'équilibre, la stabilité et l'accomplissement
								personnel.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={4}
					flippedImage
					text={
						<>
							<span>
								Séance 3/4h par téléphone ou visio conférence
								<br />
								Tarification : 65€
							</span>
							<span className="textCenter">
								<hr />
								<Button
									target="_blank"
									link={SessionLifeCoachingDoc}
								>
									Visualiser la brochure d'information
								</Button>
								<br />
								<Button link="/contact">
									Demander un rendez-vous
								</Button>
							</span>
						</>
					}
					left
				/>
			</ImageTextContainer>

			<BannerTestimonie
				pageId={pageId}
				entityId={5}
				name="Maeva"
				commentary={
					<>
						Ouf, ma vie était très agitée et maintenant tout
						s'enclenche correctement avec patience. Je réalise mes
						souhaits comme je l'entends et en plus je découvre
						comment le développement de mon potentiel humain m'amène
						à faire les choses plus facilement tous les jours,
						merci, merci, merci, Christophe.
					</>
				}
			/>

			<BannerImageText pageId={pageId} entityId={6}></BannerImageText>

			<Footer />
		</div>
	)
}

export default SessionLifeCoaching
