import PropTypes from 'prop-types'

import './index.scss'

function Info({ info, className }) {
	return <div className={`SOUKIASSIAN-Info ${className}`}>{info}</div>
}

Info.propTypes = {
	info: PropTypes.any.isRequired,
	className: PropTypes.string,
}

Info.defaultProps = {
	className: '',
}

export default Info
