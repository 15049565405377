import PropTypes from 'prop-types'

import './index.scss'

function ImageTextContainer({ children, className }) {
	return (
		<section
			className={`SOUKIASSIAN-ImageTextContainer col-max-blocked ${className}`}
		>
			{children}
		</section>
	)
}

ImageTextContainer.propTypes = {
	className: PropTypes.string,
}

ImageTextContainer.defaultProps = {
	className: '',
}

export default ImageTextContainer
