export const IS_DEV_ENVIRONMENT = false

export const BASE_URL_API = IS_DEV_ENVIRONMENT
	? 'https://soukiassian.vitrishop.fr/api/v1/'
	: '/api/v1/'

export const BASE_URL_IMAGE = IS_DEV_ENVIRONMENT
	? 'https://soukiassian.vitrishop.fr/images/'
	: '/images/'

export const RIGHTS_ALL = 1
export const RIGHTS_CLIENT_LIST = 2
export const RIGHTS_MODIFY_OR_ADD_CLIENT = 3
export const RIGHTS_IMAGE_LIST = 4
export const RIGHTS_MODIFY_OR_ADD_IMAGE = 5
export const RIGHTS_DELETE_IMAGE = 6
export const RIGHTS_REGISTRATION_LIST = 7
export const RIGHTS_MODIFY_OR_ADD_REGISTRATION = 8
export const RIGHTS_DELETE_REGISTRATION = 9
export const RIGHTS_BLOG_LIST = 10
export const RIGHTS_MODIFY_OR_ADD_BLOG = 11
export const RIGHTS_DELETE_BLOG = 12
