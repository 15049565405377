import axios from 'axios'

import { inProgressCheck, inProgressAdd, inProgressRemove } from '../utils'
import { BASE_URL_API } from '../utils/env'

const baseUrl = BASE_URL_API + 'user'

let listInProgress = []

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const NEW_PASSWORD = 'NEW_PASSWORD'

export const login = (user = false, password = false) => {
	if (!inProgressCheck(LOGIN, listInProgress)) {
		inProgressAdd(LOGIN, listInProgress)
		return (dispatch) => {
			return axios
				.post(
					baseUrl + '/login',
					JSON.stringify({ user: user, password: password }),
					{
						headers: {
							'Content-Type': 'application/json',
						},
						withCredentials: true,
					},
				)
				.then((result) => {
					if (result.status === 200) {
						dispatch({
							type: LOGIN,
							payload: result.data,
						})
					}
				})
				.catch((error) => {
					errorHandling(error, 'se connecter', LOGIN)
				})
				.finally(() => {
					inProgressRemove(LOGIN, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

export const logout = () => {
	if (!inProgressCheck(LOGOUT, listInProgress)) {
		inProgressAdd(LOGOUT, listInProgress)
		return (dispatch) => {
			return axios
				.post(baseUrl + '/logout', null, {
					withCredentials: true,
				})
				.then((result) => {
					if (result.status === 200) {
						dispatch({
							type: LOGOUT,
						})
					}
				})
				.catch((error) => {
					errorHandling(error, 'se déconnecter', LOGOUT)
				})
				.finally(() => {
					inProgressRemove(LOGOUT, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

export const newPassword = (user, password, newPassword) => {
	if (!inProgressCheck(NEW_PASSWORD, listInProgress)) {
		inProgressAdd(NEW_PASSWORD, listInProgress)
		return (dispatch) => {
			return axios
				.put(
					baseUrl + '/password',
					JSON.stringify({
						user: user,
						password: password,
						newPassword: newPassword,
					}),
					{
						headers: {
							'Content-Type': 'application/json',
						},
					},
				)
				.then((result) => {
					if (result.response.status === 200) {
						return true
					}
				})
				.catch((error) => {
					errorHandling(
						error,
						'changer le mot de passe',
						NEW_PASSWORD,
					)
				})
				.finally(() => {
					inProgressRemove(NEW_PASSWORD, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

const errorHandling = (error, title, actionType) => {
	let errorTitle = ''
	let errorMessage = ''

	if (error.response) {
		switch (error.response.status) {
			case 401:
				errorTitle = 'Erreur pour ' + title
				errorMessage = error.response.data.message
				break

			case 404:
				errorTitle = 'Erreur pour ' + title
				errorMessage = "La requête n'existe pas"
				break

			case 500:
				errorTitle = 'Erreur serveur pour ' + title
				errorMessage = error.response.data.message
				break

			default:
				errorTitle = 'Erreur pour ' + title
				errorMessage = 'Unknown error'
				break
		}
	} else if (error.request) {
		errorTitle = 'Erreur serveur pour ' + title
		errorMessage = 'Le serveur ne répond pas'
	} else {
		errorTitle = 'Erreur pour ' + title
		errorMessage = error.message
	}

	throw new Error(errorMessage, {
		cause: { type: actionType, title: errorTitle },
	})
}
