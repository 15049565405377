import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'

import {
	getIdByNameRegistrationTypeList,
	getByIdRegistrationTypeList,
	getDataNameFromRegistrationType,
	dateEnToFr,
	isRightAccess,
	filterRegistrationByType,
	registrationDataNameFormat,
} from '../../../utils'
import { RIGHTS_MODIFY_OR_ADD_REGISTRATION } from '../../../utils/env'

import {
	getRegistrations,
	getRegistrationsType,
} from '../../../actions/registration.action'

import HarmoniousTitle from '../../HarmoniousTitle'
import Button from '../../Button'
import ImageTextContainer from '../../ImageTextContainer'
import ImageText from '../../ImageTextContainer/ImageText'
import RegistrationForm from './Form'
import StringToNode from '../../StringToNode'

import '../../RegistrationList/index.scss'

function RegistrationList({ data }) {
	const [registrationTypeId, setRegistrationTypeId] = useState(0)
	const [isEdit, setIsEdit] = useState(0)

	const user = useSelector((state) => state.userReducer)
	const registrationList = useSelector(
		(state) => state.registrationReducer.list,
	)
	const registrationTypeList = useSelector(
		(state) => state.registrationReducer.typeList,
	)

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(getRegistrations())
		dispatch(getRegistrationsType())

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setRegistrationTypeId(
			getIdByNameRegistrationTypeList(
				data.category,
				data.subcategory,
				registrationTypeList,
			),
		)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [registrationTypeList])

	return (
		registrationTypeList &&
		registrationList &&
		registrationTypeId && (
			<div className="SOUKIASSIAN-RegistrationList">
				<div className="col-max-blocked center margined-y">
					<Button link="/admin/registration-handler">
						<FontAwesomeIcon icon={faReply} />
						{' REVENIR AUX INSCRIPTIONS'}
					</Button>
				</div>
				<HarmoniousTitle
					className="col-max-blocked"
					title={
						getByIdRegistrationTypeList(
							registrationTypeId,
							registrationTypeList,
						).title_registration
					}
				>
					<ImageTextContainer>
						{filterRegistrationByType(
							registrationTypeId,
							registrationList,
						).map((registration) => (
							<Fragment
								key={`registration_container_${registration.id}`}
							>
								{isEdit === registration.id ? (
									<ImageText
										title="MODIFIER"
										text={
											<RegistrationForm
												key={`registration_update_${registration.id}`}
												registrationId={registration.id}
												registrationTypeId={
													registration.id_registration_type
												}
												dataNameList={getDataNameFromRegistrationType(
													registrationTypeId,
													registrationTypeList,
												)}
												dataValueList={{
													...JSON.parse(
														registration.data,
													),
													event_date:
														registration.event_date,
												}}
												setIsEdit={setIsEdit}
											/>
										}
										noMinHeight
									/>
								) : (
									<ImageText
										key={`registration_${registration.id}`}
										text={
											<>
												<span>
													<b>Date du premier jour</b>{' '}
													:{' '}
													{dateEnToFr(
														registration.event_date,
													)}
												</span>
												{getDataNameFromRegistrationType(
													registrationTypeId,
													registrationTypeList,
													true,
												).map(
													(data_name_block, key) => (
														<span
															key={`data_name_block_${registration.id}${key}`}
														>
															{data_name_block.map(
																(data_name) => (
																	<Fragment
																		key={`data_name_${registration.id}${registrationDataNameFormat(data_name)}`}
																	>
																		<b>
																			{
																				data_name
																			}
																		</b>{' '}
																		:{' '}
																		<StringToNode
																			string={
																				JSON.parse(
																					registration.data,
																				)[
																					registrationDataNameFormat(
																						data_name,
																					)
																				]
																			}
																		/>
																		<br />
																	</Fragment>
																),
															)}
														</span>
													),
												)}
												{isRightAccess(
													user,
													RIGHTS_MODIFY_OR_ADD_REGISTRATION,
												) && (
													<span>
														<Button
															onClick={() =>
																setIsEdit(
																	registration.id,
																)
															}
														>
															MODIFIER
														</Button>
													</span>
												)}
											</>
										}
										noMinHeight
									/>
								)}
							</Fragment>
						))}

						{isRightAccess(
							user,
							RIGHTS_MODIFY_OR_ADD_REGISTRATION,
						) && (
							<ImageText
								title="AJOUTER"
								text={
									<RegistrationForm
										registrationTypeId={registrationTypeId}
										dataNameList={getDataNameFromRegistrationType(
											registrationTypeId,
											registrationTypeList,
										)}
										setIsEdit={setIsEdit}
									/>
								}
								noMinHeight
							/>
						)}
					</ImageTextContainer>
				</HarmoniousTitle>
			</div>
		)
	)
}

RegistrationList.propTypes = {
	data: PropTypes.object,
}

RegistrationList.defaultProps = {
	data: { id: 0 },
}

export default RegistrationList
