import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { UPDATE_CLIENT, updateClient } from '../../actions/client.action'

import Button from '../../components/Button'
import Input from '../../components/Input'
import Success from '../../components/Success'
import LoginClient from '../LoginClient'
import Error from '../Error'

import './index.scss'

function ClientForm() {
	const client = useSelector((state) => state.clientReducer)
	const isConnected = client.isConnected
	const [updateClientSending, setUpdateClientSending] = useState(false)
	const [errors, setErrors] = useState({})
	const [success, setSuccess] = useState(false)
	const dispatch = useDispatch()

	const errorHandling = (error) => {
		let errorContainer = { message: error.message }

		if (error.cause?.type === UPDATE_CLIENT) {
			errorContainer.title = error.cause.title
		} else {
			errorContainer.title = 'Erreur lors de la mise à jour'
		}
		setErrors(errorContainer)
	}

	const updateClientHandling = function (e) {
		e.preventDefault()

		if (!updateClientSending) {
			setUpdateClientSending(true)
			const form = new FormData(e.target)

			const dataList = {}

			form.forEach((data, name) => {
				dataList[name] = data
			})

			dispatch(updateClient(client.client.id, dataList, false, true))
				.then((errors) => {
					if (errors) {
						setErrors(errors)
					} else {
						setSuccess(true)
					}
				})
				.catch(errorHandling)
				.finally(() => {
					setUpdateClientSending(false)
				})
		}
	}
	const getClientData = function (dataName) {
		if (client.client) {
			return client.client[dataName] ? client.client[dataName] : ''
		} else {
			return ''
		}
	}

	return (
		<>
			{isConnected ? (
				<form
					className="SOUKIASSIAN-ClientForm"
					onSubmit={updateClientHandling}
				>
					<div className="SOUKIASSIAN-ClientForm-row col-11">
						<Input
							className="col-5 col-xs-12 col-xxs-12"
							name="firstName"
							message="Prénom"
							title="Votre prénom"
							maxLength="255"
							value={getClientData('firstName')}
							errorDirect={
								errors.firstName ? errors.firstName : null
							}
						/>
						<Input
							className="col-5 col-xs-12 col-xxs-12"
							name="secondName"
							message="NOM"
							title="Votre NOM"
							maxLength="255"
							value={getClientData('secondName')}
							errorDirect={
								errors.secondName ? errors.secondName : null
							}
						/>
					</div>
					<div className="SOUKIASSIAN-ClientForm-row col-11">
						<Input
							className="col-5 col-xs-12 col-xxs-12"
							name="email"
							message="Adresse e-mail"
							title="Votre e-mail"
							maxLength="255"
							value={getClientData('email')}
							readOnly
						/>
						<Input
							className="col-5 col-xs-12 col-xxs-12"
							type="tel"
							name="phone"
							message="Téléphone personnel"
							title="Votre téléphone personnel"
							maxLength="255"
							value={getClientData('phone')}
							errorDirect={errors.phone ? errors.phone : null}
						/>
					</div>
					<div className="SOUKIASSIAN-ClientForm-row col-11">
						<Input
							className="col-5 col-xs-12 col-xxs-12"
							name="business"
							message="Nom d'entreprise"
							title="Votre entreprise"
							maxLength="255"
							value={getClientData('business')}
							errorDirect={
								errors.business ? errors.business : null
							}
							colorPro
						/>
						<Input
							className="col-5 col-xs-12 col-xxs-12"
							type="tel"
							name="professionalPhone"
							message="Téléphone professionnel"
							title="Votre téléphone professionnel"
							maxLength="255"
							value={getClientData('professionalPhone')}
							errorDirect={
								errors.professionalPhone
									? errors.professionalPhone
									: null
							}
							colorPro
						/>
					</div>
					<div className="SOUKIASSIAN-ClientForm-row col-11">
						<Input
							className="col-5 col-xs-12 col-xxs-12"
							name="zip"
							message="Code postal"
							title="Votre code postal"
							maxLength="5"
							value={getClientData('zip')}
							errorDirect={errors.zip ? errors.zip : null}
						/>
						<Input
							className="col-5 col-xs-12 col-xxs-12"
							name="city"
							message="Ville"
							title="Votre ville"
							maxLength="255"
							value={getClientData('city')}
							errorDirect={errors.city ? errors.city : null}
						/>
					</div>
					<div className="SOUKIASSIAN-ClientForm-row col-11">
						<Input
							className="col-5 col-xs-12 col-xxs-12"
							name="country"
							message="Pays"
							title="Votre pays"
							maxLength="255"
							value={getClientData('country')}
							errorDirect={errors.country ? errors.country : null}
						/>
						<Input
							className="col-5 col-xs-12 col-xxs-12"
							name="address"
							message="Adresse"
							title="Votre adresse"
							maxLength="255"
							value={getClientData('address')}
							errorDirect={errors.address ? errors.address : null}
						/>
					</div>
					{(errors.message || errors.title) && (
						<Error
							className="col-11"
							error={`${errors.title} : ${errors.message}`}
							deleteError={() => {
								setErrors({})
							}}
						/>
					)}
					{success && (
						<Success
							className="col-11"
							success="Vos informations ont été mises à jour"
						/>
					)}
					<Button
						className="col-xxs-11 col-xs-11 col-4"
						isDisabled={updateClientSending}
					>
						VALIDER LES CHANGEMENTS
					</Button>
				</form>
			) : (
				<LoginClient />
			)}
		</>
	)
}

export default ClientForm
