import PropTypes from 'prop-types'

import PictureTextLink from '../PictureTextLink'

function ITFPChild({
	title,
	pageId,
	entityId,
	link,
	text,
	width,
	left,
	right,
	flippedImage,
	animate,
}) {
	const isRightClass = right
		? ' SOUKIASSIAN-ImageTextFilletPowder-childRight'
		: ''

	return (
		<article
			className={`SOUKIASSIAN-ImageTextFilletPowder-child${isRightClass}`}
		>
			{right && (
				<>
					<div className="SOUKIASSIAN-ImageTextFilletPowder-child-text">
						{text}
					</div>
					<div className="SOUKIASSIAN-ImageTextFilletPowder-child-filled-container">
						<div className="SOUKIASSIAN-ImageTextFilletPowder-child-filled"></div>
					</div>
				</>
			)}

			<PictureTextLink
				link={link}
				pageId={pageId}
				entityId={entityId}
				title={title}
				width={width}
				className="SOUKIASSIAN-ImageTextFilletPowder-child-PictureTextLink"
				flipped={flippedImage}
				animate={animate}
			/>

			{left && !right && (
				<>
					<div className="SOUKIASSIAN-ImageTextFilletPowder-child-filled-container">
						<div className="SOUKIASSIAN-ImageTextFilletPowder-child-filled"></div>
					</div>
					<div className="SOUKIASSIAN-ImageTextFilletPowder-child-text">
						{text}
					</div>
				</>
			)}
		</article>
	)
}

ITFPChild.propTypes = {
	title: PropTypes.string,
	pageId: PropTypes.number.isRequired,
	entityId: PropTypes.number.isRequired,
	link: PropTypes.string,
	text: PropTypes.string,
	width: PropTypes.string,
	left: PropTypes.bool,
	right: PropTypes.bool,
	flippedImage: PropTypes.bool,
	animate: PropTypes.bool,
}

ITFPChild.defaultProps = {
	title: '',
	text: '',
	left: true,
	right: false,
	flippedImage: false,
	animate: false,
}

export default ITFPChild
