import { LOGIN, LOGOUT } from '../actions/user.action'

const initialState = { isConnected: false }

export default function userReducer(state = initialState, action) {
	switch (action.type) {
		case LOGIN:
			return {
				...state,
				isConnected: action.payload.isConnected === true ? true : false,
				user: action.payload.user,
				rights: action.payload.rights?.split(';'),
			}
		case LOGOUT:
			return { ...initialState }
		default:
			return { ...state }
	}
}
