import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getPage, updateImageFromPage } from '../../actions/page.action'
import { getImages } from '../../actions/image.action'

import { getDataFromImageList, getImageIdFromPageList } from '../../utils'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage } from '@fortawesome/free-regular-svg-icons'
import { faQuestion } from '@fortawesome/free-solid-svg-icons'

import PropTypes from 'prop-types'

import Button from '../Button'
import AdminImageTable from '../admin/ImageTable'

import './index.scss'

function ImageInfo({ imgData, pageId, entityId }) {
	const isConnected = useSelector((state) => state.userReducer.isConnected)
	const pageList = useSelector((state) => state.pageReducer.list)
	const imageList = useSelector((state) => state.imageReducer.list)
	const [changeImage, setChangeImage] = useState(false)
	const rightAccess = true
	const dispatch = useDispatch()

	const disabled = true

	const imageInfoClass = changeImage
		? ' SOUKIASSIAN-ImageInfo-isChangeImage'
		: ''

	if (imgData === 'GET') {
		imgData = getDataFromImageList(
			getImageIdFromPageList(pageList, pageId, entityId),
			imageList,
		)
	}

	useEffect(() => {
		if (imgData === 'GET') {
			if (pageId != null) {
				dispatch(getPage(pageId))
			}
			dispatch(getImages())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const iconClickHandling = (e) => {
		e.preventDefault()
		const icon = e.target

		if (icon.classList.contains('SOUKIASSIAN-ImageInfo-icon')) {
			const imgDataModal = icon.nextElementSibling

			icon.classList.add('hide')
			imgDataModal.classList.remove('hide')
		}
	}

	const hideClickHandling = (e) => {
		e.preventDefault()
		const button = e.target

		if (button.classList.contains('SOUKIASSIAN-ImageInfo-buttonHide')) {
			const imgDataModal = button.parentElement
			const icon = button.parentElement.previousElementSibling

			icon.classList.remove('hide')
			imgDataModal.classList.add('hide')
		}
	}

	const changeImageHandling = (e, imgId) => {
		e.preventDefault()
		const elem = e.target

		if (elem.classList.contains('SOUKIASSIAN-ImageInfo-changeImage')) {
			setChangeImage(true)
		} else if (
			elem.classList.contains('SOUKIASSIAN-ImageInfo-changeImageCancel')
		) {
			setChangeImage(false)
		} else if (
			elem.classList.contains(
				'SOUKIASSIAN-AdminImageTable-ImageRow-image-selected',
			)
		) {
			dispatch(updateImageFromPage(pageId, entityId, imgId))
		}
	}

	return (
		<>
			{imgData && !disabled && (
				<div className={`SOUKIASSIAN-ImageInfo${imageInfoClass}`}>
					<div
						className="SOUKIASSIAN-ImageInfo-icon"
						onClick={iconClickHandling}
					>
						<FontAwesomeIcon icon={faQuestion} />
						<FontAwesomeIcon icon={faImage} />
					</div>
					<div className="SOUKIASSIAN-ImageInfo-data hide">
						{!changeImage ? (
							<>
								<span className="SOUKIASSIAN-ImageInfo-data-title">
									Information de l'image
								</span>
								<br />
								<br />
								{isConnected && rightAccess && (
									<>
										Identifiant : {imgData['id']}
										<br />
									</>
								)}
								{imgData['role'] !== 'non utilisée' && (
									<>
										Rôle : {imgData['role']}
										<br />
									</>
								)}
								{imgData['author'] && (
									<>
										Auteur : {imgData['author']}
										<br />
									</>
								)}
								{imgData['site'] && (
									<>
										Site : {imgData['site']}
										<br />
									</>
								)}
								{imgData['author_link'] && (
									<>
										Lien de l'auteur :{' '}
										<Link
											to={imgData['author_link']}
											target="_blank"
										>
											{imgData['author_link']}
										</Link>
										<br />
									</>
								)}
								{imgData['source_link'] && (
									<>
										Lien source :{' '}
										<Link
											to={imgData['source_link']}
											target="_blank"
										>
											{imgData['source_link']}
										</Link>
										<br />
									</>
								)}
								Extension actuelle : {imgData['extension']}
								<br />
								Orientation actuelle : {imgData['direction']}
								<br />
								Taille actuelle : {imgData['size']}
								<br />
								Poids actuel : {imgData['weight']}
								<br />
								{imgData['change_made'] && (
									<>
										Mofication effectuée :{' '}
										{imgData['change_made']}
										<br />
									</>
								)}
								<br />
								<Button
									className="SOUKIASSIAN-ImageInfo-buttonHide"
									onClick={hideClickHandling}
								>
									Cacher
								</Button>
								{isConnected && rightAccess && (
									<>
										<br />
										<Button
											className="SOUKIASSIAN-ImageInfo-changeImage"
											onClick={changeImageHandling}
										>
											Changer l'image
										</Button>
									</>
								)}
							</>
						) : (
							<>
								<Button
									className="SOUKIASSIAN-ImageInfo-changeImageCancel"
									onClick={changeImageHandling}
								>
									Annuler
								</Button>
								<AdminImageTable
									onClickImgPersonalized={changeImageHandling}
									forChangeImage
								/>
							</>
						)}
					</div>
				</div>
			)}
		</>
	)
}

ImageInfo.propTypes = {
	imgData: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf(['GET'])]),
	pageId: PropTypes.number,
	entityId: PropTypes.number,
}

export default ImageInfo
