import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'

import {
	getIdByNameRegistrationTypeList,
	getByIdRegistrationTypeList,
	getDataNameFromRegistrationType,
	filterRegistrationByType,
	filterRegistrationById,
	getDataNameClientFromRegistrationType,
	registrationDataNameFormat,
} from '../../utils'

import {
	getRegistrations,
	getRegistrationsType,
} from '../../actions/registration.action'

import HarmoniousTitle from '../HarmoniousTitle'
import Button from '../Button'
import ImageTextContainer from '../ImageTextContainer'
import ImageText from '../ImageTextContainer/ImageText'
import StringToNode from '../StringToNode'
import LoginClient from '../LoginClient'
import RegistrationForm from './Form'

import './index.scss'

function RegistrationList({ data }) {
	const [registrationTypeId, setRegistrationTypeId] = useState(0)

	const client = useSelector((state) => state.clientReducer)
	const isConnected = client.isConnected

	const registrationList = useSelector(
		(state) => state.registrationReducer.list,
	)
	const registrationTypeList = useSelector(
		(state) => state.registrationReducer.typeList,
	)

	const dispatch = useDispatch()

	const pageId = 29

	useEffect(() => {
		dispatch(getRegistrations())
		dispatch(getRegistrationsType())

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setRegistrationTypeId(
			getIdByNameRegistrationTypeList(
				data.category,
				data.subcategory,
				registrationTypeList,
			),
		)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [registrationTypeList])

	return (
		registrationTypeList &&
		registrationList &&
		registrationTypeId && (
			<div className="SOUKIASSIAN-RegistrationList">
				<div className="col-max-blocked center margined-y SOUKIASSIAN-RegistrationList-backButton">
					<Button
						link={
							'/registration' +
							(data?.registrationId === 0
								? ''
								: `/${data.category}/${data.subcategory}`)
						}
					>
						<FontAwesomeIcon icon={faReply} />
						{' REVENIR AUX INSCRIPTIONS'}
					</Button>
				</div>
				<HarmoniousTitle
					className="col-max-blocked"
					title={
						getByIdRegistrationTypeList(
							registrationTypeId,
							registrationTypeList,
						).title_registration
					}
				>
					{data?.registrationId === 0 ? ( //Registration List if no id
						<ImageTextContainer>
							{
								/*filterRegistrationByType(
								registrationTypeId,
								registrationList,
							).map((registration) => (
								<ImageText
									key={`registration_${registration.id}`}
									text={
										<>
											{getDataNameFromRegistrationType(
												registrationTypeId,
												registrationTypeList,
												true,
											).map((data_name_block, key) => (
												<span
													key={`data_name_block_${registration.id}${key}`}
												>
													{data_name_block.map(
														(data_name) => (
															<Fragment
																key={`data_name_${registration.id}${registrationDataNameFormat(data_name)}`}
															>
																<b>
																	{data_name}
																</b>{' '}
																:{' '}
																<StringToNode
																	string={
																		JSON.parse(
																			registration.data,
																		)[
																			registrationDataNameFormat(
																				data_name,
																			)
																		]
																	}
																/>
																<br />
															</Fragment>
														),
													)}
												</span>
											))}
											<Button
												link={`${registration.id}`}
											>
												S'INSCRIRE
											</Button>
										</>
									}
									noMinHeight
								/>
							))*/
								<ImageText
									text={
										<>
											<span>
												<Fragment>
													<b>Vous êtes intéressé ?</b>
													<br />
													<br />
													Contactez-nous en appuyant
													sur le bouton ci-dessous.
												</Fragment>
											</span>
											<Button link="/CONTACT">
												S'INSCRIRE
											</Button>
										</>
									}
									noMinHeight
								/>
							}
						</ImageTextContainer>
					) : isConnected ? ( //Registration by id if id exist and if client is connected
						<ImageTextContainer>
							{filterRegistrationById(
								data?.registrationId,
								registrationList,
							).map((registration) => (
								<Fragment
									key={`registration_${registration.id}`}
								>
									<ImageText
										text={
											<>
												{getDataNameFromRegistrationType(
													registrationTypeId,
													registrationTypeList,
													true,
												).map(
													(data_name_block, key) => (
														<span
															key={`data_name_block_${registration.id}${key}`}
														>
															{data_name_block.map(
																(data_name) => (
																	<Fragment
																		key={`data_name_${registration.id}${registrationDataNameFormat(data_name)}`}
																	>
																		<b>
																			{
																				data_name
																			}
																		</b>{' '}
																		:{' '}
																		<StringToNode
																			string={
																				JSON.parse(
																					registration.data,
																				)[
																					registrationDataNameFormat(
																						data_name,
																					)
																				]
																			}
																		/>
																		<br />
																	</Fragment>
																),
															)}
														</span>
													),
												)}
											</>
										}
										noMinHeight
									/>
									<ImageText
										title="VOS INFORMATIONS"
										text={
											<RegistrationForm
												registrationId={registration.id}
												clientId={client.client.id}
												dataNameClientList={getDataNameClientFromRegistrationType(
													registrationTypeId,
													registrationTypeList,
												)}
											/>
										}
										noMinHeight
									/>
								</Fragment>
							))}
						</ImageTextContainer>
					) : (
						//OR if id exist AND if client is not connected
						<LoginClient />
					)}
				</HarmoniousTitle>
			</div>
		)
	)
}

RegistrationList.propTypes = {
	data: PropTypes.object,
}

RegistrationList.defaultProps = {
	data: { id: 0 },
}

export default RegistrationList
