import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { sendMessage } from '../../actions/contact.action'

import Header from '../../components/Header'
import HarmoniousTitle from '../../components/HarmoniousTitle'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Footer from '../../components/Footer'
import Success from '../../components/Success'

import './index.scss'

function Contact() {
	const [doReset, setDoReset] = useState(false)
	const [errors, setErrors] = useState({})
	const [success, setSuccess] = useState(false)
	const dispatch = useDispatch()
	const pageId = 28

	const sendMessageHandling = function (e) {
		e.preventDefault()
		const form = new FormData(e.target)

		dispatch(sendMessage(form, setDoReset)).then((errors) => {
			if (errors) {
				setErrors(errors)
			} else {
				setSuccess(true)
			}
		})
	}

	return (
		<div className="SOUKIASSIAN-Contact">
			<Header type="finalPage" title="CONTACTEZ L'ECOLE L.C.C. ©" />

			<HarmoniousTitle
				className="col-max-blocked"
				title="Formulaire de contact"
			>
				<form
					className="SOUKIASSIAN-Contact-form"
					action="/contact"
					onSubmit={sendMessageHandling}
				>
					<div className="SOUKIASSIAN-Contact-form-row col-11">
						<div className="col-5 col-xs-12 col-xxs-12">
							<Input
								className="col-12"
								name="fullName"
								message="NOM et Prénom *"
								title="Votre nom et prénom"
								maxLength="255"
								errorDirect={
									errors.fullName ? errors.fullName : null
								}
								reset={[doReset]}
								required
							/>
							<Input
								className="col-12"
								type="email"
								name="email"
								message="Adresse e-mail *"
								title="Votre e-mail"
								maxLength="255"
								errorDirect={errors.email ? errors.email : null}
								reset={[doReset]}
								required
							/>
							<Input
								className="col-12"
								type="tel"
								name="personalPhone"
								message="Téléphone personnel"
								title="Votre téléphone personnel"
								maxLength="255"
								errorDirect={
									errors.personalPhone
										? errors.personalPhone
										: null
								}
								reset={[doReset]}
							/>
						</div>
						<div className="col-5 col-xs-12 col-xxs-12">
							<Input
								className="col-12"
								name="business"
								message="Nom d'entreprise"
								title="Votre entreprise"
								maxLength="255"
								errorDirect={
									errors.business ? errors.business : null
								}
								reset={[doReset]}
								colorPro
							/>
							<Input
								className="col-12"
								type="tel"
								name="professionalPhone"
								message="Téléphone professionnel"
								title="Votre téléphone professionnel"
								maxLength="255"
								errorDirect={
									errors.professionalPhone
										? errors.professionalPhone
										: null
								}
								reset={[doReset]}
								colorPro
							/>
						</div>
					</div>

					<Input
						className="col-11"
						name="subject"
						message="Objet du message"
						title="Votre objet du message"
						maxLength="255"
						errorDirect={errors.subject ? errors.subject : null}
						reset={[doReset]}
					/>
					<Input
						className="col-11"
						name="message"
						type="textarea"
						message="Message *"
						title="Votre message"
						maxLength="2000"
						errorDirect={errors.message ? errors.message : null}
						reset={[doReset, setDoReset]}
					/>
					<p className="SOUKIASSIAN-Contact-form-condition col-11">
						* Merci de remplir ces champs pour envoyer votre message
					</p>
					{success && (
						<Success
							className="col-11"
							success={
								<>
									Votre message nous a bien été envoyé.
									<br />
									<br />
									Merci de nous avoir contacté, nous
									reviendrons vers vous très prochainement.
									<br />
									<br />
									L'école L.C.C. ©
								</>
							}
						/>
					)}
					<Button className="col-xxs-11 col-xs-11 col-4">
						ENVOYER LE MESSAGE
					</Button>
				</form>
			</HarmoniousTitle>

			<Footer />
		</div>
	)
}

export default Contact
