import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import Error from '../Error'

import './index.scss'

function Input({
	type,
	name,
	title,
	message,
	value,
	minLength,
	maxLength,
	errorDirect,
	checkError,
	error,
	onChange,
	reset,
	required,
	readOnly,
	autoComplete,
	admin,
	colorPro,
	className,
}) {
	const [textValue, setTextValue] = useState(value)
	const [isError, setIsError] = useState(false)
	const [showPWD, setShowPWD] = useState(false)
	const [actualLength, setActualLength] = useState(value?.length)

	const classAdmin = admin ? ' SOUKIASSIAN-Input-admin' : ''
	const classColorPro = colorPro ? ' SOUKIASSIAN-Input-colorPro' : ''

	const changeValue = function (elem) {
		const value = elem.target.value

		if (checkError(value)) {
			setTextValue(value)
			setActualLength(elem.target.textLength)
			if (onChange) onChange(elem.target.name, value, elem.target)
		} else {
			setIsError(true)
		}
	}

	useEffect(() => {
		if (reset[0]) {
			setTextValue('')
			setActualLength(0)
			if (reset[1]) {
				reset[1](false)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reset[0]])

	if (maxLength && type === 'textarea') {
		if (message) {
			message += ` [${actualLength}/${maxLength}]`
		} else {
			message = `[${actualLength}/${maxLength}]`
		}
	}

	return (
		<div
			className={`SOUKIASSIAN-Input${classAdmin}${classColorPro} ${className}`}
		>
			{message && <p>{message}</p>}
			{type === 'textarea' ? (
				<textarea
					value={textValue}
					onChange={changeValue}
					name={name}
					placeholder={title}
					title={title}
					rows="5"
					minLength={minLength}
					maxLength={maxLength}
					required={required}
					readOnly={readOnly}
				></textarea>
			) : (
				<>
					<input
						value={textValue}
						onChange={changeValue}
						type={showPWD ? 'text' : type}
						name={name}
						placeholder={title}
						title={title}
						minLength={minLength}
						maxLength={maxLength}
						required={required}
						readOnly={readOnly}
						autoComplete={autoComplete}
					/>
					{type === 'password' && (
						<>
							<label className="SOUKIASSIAN-Input-showPWD">
								Afficher le mot de passe :
								<input
									type="checkbox"
									onChange={(elem) =>
										setShowPWD(elem.target.checked)
									}
								/>
							</label>
						</>
					)}
				</>
			)}

			{isError && (
				<Error
					error={error}
					deleteError={() => {
						setIsError(false)
					}}
				/>
			)}
			{errorDirect && <Error error={errorDirect} />}
		</div>
	)
}

Input.propTypes = {
	type: PropTypes.string.isRequired,
	name: PropTypes.string,
	title: PropTypes.string,
	message: PropTypes.string,
	value: PropTypes.string,
	minLength: PropTypes.string,
	maxLength: PropTypes.string,
	errorDirect: PropTypes.string,
	checkError: PropTypes.func,
	error: PropTypes.string,
	onChange: PropTypes.func,
	reset: PropTypes.array,
	required: PropTypes.bool,
	readOnly: PropTypes.bool,
	autoComplete: PropTypes.string,
	admin: PropTypes.bool,
	colorPro: PropTypes.bool,
	className: PropTypes.string,
}

Input.defaultProps = {
	type: 'text',
	value: '',
	checkError: (v) => true,
	reset: [false, () => {}],
	required: false,
	readOnly: false,
	autoComplete: '',
	admin: false,
	colorPro: false,
	className: '',
}

export default Input
