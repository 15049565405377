import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { registrationTypeListFormatting } from '../../../utils'
import { IS_DEV_ENVIRONMENT } from '../../../utils/env'

import { getRegistrationsType } from '../../../actions/registration.action'

import HarmoniousTitle from '../../../components/HarmoniousTitle'
import PictureTextLink from '../../../components/PictureTextLink'

import '../../RegistrationList/index.scss'

function RegistrationCategoryList() {
	const [registrationTypeListFormatted, setRegistrationTypeListFormatted] =
		useState({})
	const isConnectedUser = useSelector(
		(state) => state.userReducer.isConnected,
	)
	const registrationTypeList = useSelector(
		(state) => state.registrationReducer.typeList,
	)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(getRegistrationsType())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setRegistrationTypeListFormatted(
			registrationTypeListFormatting(registrationTypeList),
		)
	}, [registrationTypeList])

	return (
		<div className="SOUKIASSIAN-RegistrationList-Category">
			{registrationTypeListFormatted.event && (
				<HarmoniousTitle
					className="col-max-blocked"
					title={
						registrationTypeListFormatted.event.title_category[0]
					}
					sticky
				>
					<div className="SOUKIASSIAN-RegistrationList-Category-pictureList">
						{registrationTypeListFormatted.event.list.map(
							(event) => {
								return (
									<PictureTextLink
										className="col-xxs-12 col-xs-12 col-5"
										key={event.id}
										link={event.link}
										pageId={event.id_page}
										entityId={event.id_image}
										title={event.title_subcategory}
										onClick={(e) => {
											if (
												!IS_DEV_ENVIRONMENT &&
												!isConnectedUser
											) {
												e.preventDefault()
												alert(
													"Page en cours d'actualisation septembre 2024",
												)
											}
										}}
									/>
								)
							},
						)}
					</div>
				</HarmoniousTitle>
			)}

			{registrationTypeListFormatted.stage && (
				<HarmoniousTitle
					className="col-xl-max-blocked"
					title={
						registrationTypeListFormatted.stage.title_category[0]
					}
					sticky
				>
					<div className="SOUKIASSIAN-RegistrationList-Category-pictureList SOUKIASSIAN-RegistrationList-Category-pictureList-reverse">
						{registrationTypeListFormatted.stage.list.map(
							(stage) => {
								return (
									<PictureTextLink
										className="col-xxs-12 col-xs-12 col-5"
										key={stage.id}
										link={stage.link}
										pageId={stage.id_page}
										entityId={stage.id_image}
										title={stage.title_subcategory}
										onClick={(e) => {
											if (
												!IS_DEV_ENVIRONMENT &&
												!isConnectedUser
											) {
												e.preventDefault()
												alert(
													"Page en cours d'actualisation septembre 2024",
												)
											}
										}}
									/>
								)
							},
						)}
					</div>
				</HarmoniousTitle>
			)}

			{registrationTypeListFormatted.training && (
				<>
					<HarmoniousTitle
						className="col-xl-max-blocked"
						title={
							registrationTypeListFormatted.training
								.title_category[0]
						}
					>
						<div className="SOUKIASSIAN-RegistrationList-Category-pictureList">
							{registrationTypeListFormatted.training.list.map(
								(training) => {
									if (
										training.subcategory ===
										'training-life-coaching'
									) {
										return (
											<PictureTextLink
												className="col-xxs-12 col-xs-12 col-5"
												key={training.id}
												link={training.link}
												pageId={training.id_page}
												entityId={training.id_image}
												title={
													training.title_subcategory
												}
												onClick={(e) => {
													if (
														!IS_DEV_ENVIRONMENT &&
														!isConnectedUser
													) {
														e.preventDefault()
														alert(
															"Page en cours d'actualisation septembre 2024",
														)
													}
												}}
											/>
										)
									}
									return false
								},
							)}
						</div>
					</HarmoniousTitle>

					<HarmoniousTitle
						className="col-xl-max-blocked"
						title={
							registrationTypeListFormatted.training
								.title_category[1]
						}
					>
						<div className="SOUKIASSIAN-RegistrationList-Category-pictureList">
							{registrationTypeListFormatted.training.list.map(
								(training) => {
									if (
										training.subcategory ===
										'training-become-coach'
									) {
										return (
											<PictureTextLink
												className="col-xxs-12 col-xs-12 col-5"
												key={training.id}
												link={training.link}
												pageId={training.id_page}
												entityId={training.id_image}
												title={
													training.title_subcategory
												}
												onClick={(e) => {
													if (
														!IS_DEV_ENVIRONMENT &&
														!isConnectedUser
													) {
														e.preventDefault()
														alert(
															"Page en cours d'actualisation septembre 2024",
														)
													}
												}}
											/>
										)
									}
									return false
								},
							)}
						</div>
					</HarmoniousTitle>
				</>
			)}
		</div>
	)
}

export default RegistrationCategoryList
