import Header from '../../../components/Header'
import BannerText from '../../../components/BannerText'
import HarmoniousTitle from '../../../components/HarmoniousTitle'
import Footer from '../../../components/Footer'
import ImageTextFilletPowder from '../../../components/ImageTextFilletPowder'
import ITFPChild from '../../../components/ImageTextFilletPowder/ITFPChild'

import './index.scss'

function Events() {
	const pageId = 3

	return (
		<div className="SOUKIASSIAN-Events">
			<Header
				type="intermediatePage"
				title="RENCONTREZ CHRISTOPHE SOUKIASSIAN"
				pageId={pageId}
				entityId={1}
			/>

			<BannerText
				title={
					<>
						ÉCHANGEONS ENSEMBLE
						<br />
						Lors de quatre événements passionnants.
					</>
				}
				bordered
			/>

			<HarmoniousTitle title="LES ÉVÉNEMENTS À DISTANCE ET EN PRÉSENTIEL">
				<ImageTextFilletPowder>
					<ITFPChild
						title="WEBINAIRE"
						pageId={pageId}
						entityId={2}
						link="webinars"
						text="Participez à notre webinaire exclusif pour des échanges enrichissants et des discussions captivantes."
						animate
					/>
					<ITFPChild
						title="SALON"
						pageId={pageId}
						entityId={3}
						link="tradeshows"
						text="Venez nous rencontrer lors d'un salon et bénéficiez de nos solutions innovantes pour un avenir meilleur."
						width="103%"
						animate
						right
					/>
					<ITFPChild
						title="CONFÉRENCE"
						pageId={pageId}
						entityId={4}
						link="conferences"
						text="Plongez au cœur de notre conférence où un expert partage des idées novatrices avec des perspectives inspirantes."
						animate
					/>
					<ITFPChild
						title="ATELIER"
						pageId={pageId}
						entityId={5}
						link="workshops"
						text="Expérimentez notre atelier unique pour coacher votre vie tout en développant votre conscience et aider les autres à faire de même."
						width="103%"
						animate
						right
					/>
				</ImageTextFilletPowder>
			</HarmoniousTitle>

			<Footer />
		</div>
	)
}

export default Events
