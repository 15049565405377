import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getPage } from '../../actions/page.action'
import { getImages } from '../../actions/image.action'

import { getUrlFromImageList, getImageIdFromPageList } from '../../utils'

import PropTypes from 'prop-types'

import HeaderMenu from './HeaderMenu'
import HeaderMenuAdmin from './HeaderMenuAdmin'
import Button from '../Button'
import Img from '../Img'

import './index.scss'

function Header({
	type,
	title,
	subTitle,
	description,
	buttonText,
	pageId,
	entityId,
	imgTop,
}) {
	const pageList = useSelector((state) => state.pageReducer.list)
	const imageList = useSelector((state) => state.imageReducer.list)
	const dispatch = useDispatch()

	const imgBackgroundId =
		type === 'intermediatePage'
			? getImageIdFromPageList(pageList, pageId, entityId)
			: null

	useEffect(() => {
		if (pageId != null) {
			dispatch(getPage(pageId))
		}
		dispatch(getImages())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<header className="SOUKIASSIAN-Header">
			<div className="SOUKIASSIAN-Header-background">
				{type === 'admin' ? <HeaderMenuAdmin /> : <HeaderMenu />}

				{type === 'home' && (
					<div className="SOUKIASSIAN-Header-presentation-container">
						<Img pageId={pageId} entityId={entityId} />
						<div className="SOUKIASSIAN-Header-presentation">
							<div className="SOUKIASSIAN-Header-presentation-titleOverflow">
								<h1>{title}</h1>
								<p>{subTitle}</p>
							</div>
							<Button
								className="SOUKIASSIAN-Header-presentation-ButtonCTA"
								link="/events"
							>
								{buttonText}
							</Button>
						</div>
					</div>
				)}

				{type === 'finalPage' && (
					<>
						<h1 className="SOUKIASSIAN-Header-fP-title">{title}</h1>
						<div className="SOUKIASSIAN-Header-fP-line"></div>
						<p className="SOUKIASSIAN-Header-fP-subTitle">
							{subTitle}
						</p>
					</>
				)}

				{type === 'blog' && (
					<div className="SOUKIASSIAN-Header-blog col-max-blocked center">
						<h1 className="SOUKIASSIAN-Header-blog-title">
							{title}
						</h1>
						<div className="SOUKIASSIAN-Header-blog-image-container">
							<Img pageId={pageId} entityId={entityId} />
							<div>
								<h2 className="SOUKIASSIAN-Header-blog-subTitle">
									{subTitle}
								</h2>
								<p className="SOUKIASSIAN-Header-blog-description">
									{description}
								</p>
							</div>
						</div>
					</div>
				)}
			</div>

			{type === 'intermediatePage' && (
				<>
					<h1 className="SOUKIASSIAN-Header-iP-title">{title}</h1>
					<div
						className="SOUKIASSIAN-Header-iP-imgBackground"
						style={
							imgTop
								? {
										backgroundImage: `url(${getUrlFromImageList(imgBackgroundId, imageList)})`,
										backgroundPosition: 'top',
									}
								: {
										backgroundImage: `url(${getUrlFromImageList(imgBackgroundId, imageList)})`,
									}
						}
					></div>
				</>
			)}
		</header>
	)
}

Header.propTypes = {
	type: PropTypes.oneOf([
		'none',
		'home',
		'intermediatePage',
		'finalPage',
		'blog',
		'admin',
	]).isRequired,
	title: PropTypes.any,
	subTitle: PropTypes.any,
	description: PropTypes.any,
	buttonText: PropTypes.string,
	pageId: PropTypes.number,
	entityId: PropTypes.number,
	imgTop: PropTypes.bool,
}

Header.defaultProps = {
	type: 'none',
	imgTop: false,
}

export default Header
