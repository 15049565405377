import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import ImageTextContainer from '../../../../components/ImageTextContainer'
import ImageText from '../../../../components/ImageTextContainer/ImageText'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import Button from '../../../../components/Button'

import SessionBecomeCoachDoc from '../../../../assets/documents/Session/Brochure_Devenir-coach.pdf'

function SessionBecomeCoach() {
	const pageId = 13

	return (
		<div className="SOUKIASSIAN-SessionBecomeCoach">
			<Header
				type="finalPage"
				title="SÉANCE DE SUPERVISION POUR DEVENIR COACH"
				subTitle="Spécialiste de la relation d'aide"
			/>

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={1}
					flippedImage
					text={
						<>
							<span>
								L'école L.C.C. © propose des séances
								individuelles innovantes en supervision des
								aidants.
							</span>
							<span>
								Vous{' '}
								<span className="underline">
									développez votre potentiel véritable
								</span>{' '}
								et{' '}
								<span className="underline">
									accédez à une meilleure stabilité de vie.
								</span>
							</span>
							<span>
								Accessibles à tous, les séances vous donnent{' '}
								<span className="underline">
									les solutions pour exercer avec efficience.
								</span>
							</span>
							<span>
								Développez{' '}
								<span className="underline">
									vos savoir-faire professionnels.
								</span>
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={2}
					flippedImage
					text={
						<>
							<span>
								La supervision comprend une communication
								intelligente.
							</span>
							<span>
								Le coach accompagne depuis 2014 tous les
								objectifs de la stabilité de vie afin d'exercer
								le métier d'aidant avec professionnalisme.
							</span>
						</>
					}
					left
				/>
				<ImageText
					pageId={pageId}
					entityId={3}
					text={
						<>
							<span>
								La supervision s'articule harmonieusement autour
								d'une communication consciente intuitive.
							</span>
							<span>
								Nos accompagnements développent les potentiels
								en relation d'aide.
							</span>
							<span className="underline">
								Bénéficiez de la technologie la plus avancée en
								supervision.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={4}
					text={
						<>
							<span>
								Séance 3/4h par téléphone ou visio conférence
								<br />
								Tarification : 90€
							</span>
							<span className="textCenter">
								<hr />
								<Button
									target="_blank"
									link={SessionBecomeCoachDoc}
								>
									Visualiser la brochure d'information
								</Button>
								<br />
								<Button link="/contact">
									Demander un rendez-vous
								</Button>
							</span>
						</>
					}
					left
				/>
			</ImageTextContainer>

			<BannerTestimonie
				pageId={pageId}
				entityId={5}
				name="Nadia Satin"
				commentary={
					<>
						Tout me va, alors qu'avant tout était flou. Je peux agir
						en m'épanouissant dans l'activité de mes rêves, la
						relation d'aide.
						<br />
						<br />
						Avec les supervisions de coaching L.C.C. tout a bien
						changé, moi, mon environnement et mon activité
						professionnelle. Dans ma relation avec mes clients, avec
						chaque nouvelle personne en séance, j'augmente la
						qualité de mes accompagnements. Quelle chance!
					</>
				}
			/>

			<BannerImageText pageId={pageId} entityId={6}></BannerImageText>

			<Footer />
		</div>
	)
}

export default SessionBecomeCoach
