import { useState } from 'react'
import { useDispatch } from 'react-redux'
import ReactQuill from 'react-quill'

import {
	UPDATE_TEXT_FROM_PAGE,
	updateTextFromPage,
} from '../../actions/page.action'
import {
	UPDATE_TEXT_FROM_BLOG,
	updateTextFromBlog,
} from '../../actions/blog.action'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faFloppyDisk,
	faRectangleXmark,
} from '@fortawesome/free-regular-svg-icons'
import Success from '../Success'
import Error from '../Error'

import 'react-quill/dist/quill.snow.css'

function QuillTextEditor({ text, pageId, textId, isBlog, setIsEdit }) {
	const [registrationSending, setRegistrationSending] = useState(false)
	const [errors, setErrors] = useState({})
	const [success, setSuccess] = useState(false)
	const [textEdit, setTextEdit] = useState(text ? text : '')
	const dispatch = useDispatch()

	const quillModule = {
		toolbar: [
			['bold', 'italic', 'underline', 'strike', 'link'],
			//[{ color: [] }, { background: [] }],
			[{ align: [] }],
			['clean'],
		],
	}

	const errorHandling = (error, errorList) => {
		let errorContainer = { ...errorList, message: error.message }

		if (
			error.cause?.type === UPDATE_TEXT_FROM_PAGE ||
			error.cause?.type === UPDATE_TEXT_FROM_BLOG
		) {
			errorContainer.title = error.cause.title
		} else {
			errorContainer.title = 'Erreur des actualités'
		}
		setErrors(errorContainer)
	}

	const updateTextHandling = () => {
		if (!registrationSending) {
			setRegistrationSending(true)
			let errorList = {}

			const updateText = isBlog ? updateTextFromBlog : updateTextFromPage

			dispatch(updateText(pageId, textId, text, setSuccess))
				.then((error) => {
					setErrors({})
					if (error) {
						errorList = error
						setErrors(error)
					}
				})
				.catch((e) => {
					errorHandling(e, errorList)
				})
				.finally(() => {
					setRegistrationSending(false)
				})
		}
	}

	return (
		<>
			<ReactQuill
				modules={quillModule}
				theme="snow"
				value={textEdit}
				onChange={setTextEdit}
			/>
			<FontAwesomeIcon
				icon={faRectangleXmark}
				className="SOUKIASSIAN-TextEdit-icon-cancel"
				onClick={() => {
					setIsEdit(false)
				}}
			/>
			<FontAwesomeIcon
				icon={faFloppyDisk}
				className="SOUKIASSIAN-TextEdit-icon-save"
				onClick={() => {
					updateTextHandling()
				}}
			/>
			{success ? (
				<Success
					className="col-11 center"
					success="L'actualisation a été mise à jour"
				/>
			) : (
				(errors.message || errors.title) && (
					<Error
						className="col-11 center"
						error={`${errors.title} : ${errors.message}`}
						deleteError={() => {
							setErrors({})
						}}
					/>
				)
			)}
		</>
	)
}

export default QuillTextEditor
