import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { updateImage } from '../../../actions/image.action'

import { BASE_URL_IMAGE } from '../../../utils/env'

import PropTypes from 'prop-types'

import Button from '../../Button'
import Input from '../../Input'

function ImageHandling({
	imgId,
	url,
	role,
	author,
	site,
	author_link,
	source_link,
	change_made,
	onExit,
}) {
	const [doReset, setDoReset] = useState(false)
	const [errors, setErrors] = useState({})
	const dispatch = useDispatch()

	const updateImageHandling = function (e) {
		e.preventDefault()
		const form = new FormData(e.target)

		dispatch(updateImage(imgId, form, onExit)).then((errors) => {
			if (errors) {
				setErrors(errors)
			}
		})
	}

	return (
		<form
			action=""
			encType="multipart/form-data"
			onSubmit={updateImageHandling}
			className="SOUKIASSIAN-AdminImageTable-ImageHandling"
		>
			<div className="SOUKIASSIAN-AdminImageTable-ImageHandling-image">
				<Input
					type="file"
					name="image"
					title="Image"
					errorDirect={errors.image ? errors.image : null}
					onChange={() => {
						setDoReset(true)
					}}
				/>
				<img src={`${BASE_URL_IMAGE}${url}`} alt={role} />
			</div>
			<div className="SOUKIASSIAN-AdminImageTable-ImageHandling-data">
				<div>
					<span>Rôle de l'image :</span>
					<Input
						value={role ? role : ''}
						type="text"
						name="role"
						title="Role"
						errorDirect={errors.role ? errors.role : null}
					/>
				</div>
				<div>
					<span>Auteur de l'image :</span>
					<Input
						value={author ? author : ''}
						type="text"
						name="author"
						title="Auteur"
						errorDirect={errors.author ? errors.author : null}
						reset={[doReset]}
					/>
				</div>
				<div>
					<span>Site de l'image :</span>
					<Input
						value={site ? site : ''}
						type="text"
						name="site"
						title="Site"
						errorDirect={errors.site ? errors.site : null}
						reset={[doReset]}
					/>
				</div>
				<div>
					<span>Lien de l'auteur :</span>
					<Input
						value={author_link ? author_link : ''}
						type="text"
						name="author_link"
						title="Lien auteur"
						errorDirect={
							errors.author_link ? errors.author_link : null
						}
						reset={[doReset]}
					/>
				</div>
				<div>
					<span>Lien de l'image originale :</span>
					<Input
						value={source_link ? source_link : ''}
						type="text"
						name="source_link"
						title="Lien source"
						errorDirect={
							errors.source_link ? errors.source_link : null
						}
						reset={[doReset, setDoReset]}
					/>
				</div>
				<div>
					<span>Modification effectuée :</span>
					<Input
						value={change_made ? change_made : ''}
						type="text"
						name="change_made"
						title="Modification"
						errorDirect={
							errors.change_made ? errors.change_made : null
						}
					/>
				</div>
				<Button>Modifier l'image</Button>
				<Button
					onClick={(e) => {
						e.preventDefault()
						onExit()
					}}
				>
					Annuler
				</Button>
			</div>
		</form>
	)
}

ImageHandling.propTypes = {
	imgId: PropTypes.number.isRequired,
	url: PropTypes.string,
	role: PropTypes.string,
	author: PropTypes.string,
	site: PropTypes.string,
	author_link: PropTypes.string,
	source_link: PropTypes.string,
	change_made: PropTypes.string,
	onExit: PropTypes.func,
}

ImageHandling.defaultProps = {
	role: '',
	author: '',
	site: '',
	author_link: '',
	source_link: '',
	change_made: '',
	onExit: () => true,
}

export default ImageHandling
