import { useLoaderData, useOutlet } from 'react-router-dom'

import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import BannerText from '../../components/BannerText'
import BlogEtiquette from '../../components/BlogEtiquette'
import Button from '../../components/Button'

import './index.scss'

function Blog({ isIdBlog }) {
	const outlet = useOutlet()
	const data = useLoaderData()
	const pageId = 31

	return (
		<>
			{outlet || (
				<div className="SOUKIASSIAN-Blog">
					{!isIdBlog ? (
						<>
							<Header
								type="finalPage"
								title="FIL D'ACTUALITÉ"
								subTitle="“Découvrez notre fil d'actualité et renforcez la connaissance de vous-même.”"
							/>
							<BlogEtiquette
								title="Transformation Positive et Durable"
								subTitle="Coacher sa Vie"
								description={
									<>
										« Rendons à l’humain son humanité »
										<br />
										<br />— Christophe Soukiassian
									</>
								}
								pageId={2}
								entityId={8}
								blogId={1}
							/>
						</>
					) : (
						<>
							<Header
								type="blog"
								title="Transformation Positive et Durable"
								subTitle="Coacher sa Vie"
								description={
									<>
										« Rendons à l’humain son humanité »
										<br />
										<br />— Christophe Soukiassian
									</>
								}
								pageId={2}
								entityId={8}
							/>
							<BannerText
								className="SOUKIASSIAN-Blog-BannerText"
								title="Christophe Soukiassian : un Accompagnateur Unique en France"
							/>
							<div className="col-max-blocked center margined-y">
								<Button link={'/blog'}>
									<FontAwesomeIcon icon={faReply} />
									{' REVENIR AUX ACTUALITÉS'}
								</Button>
							</div>
							<section className="SOUKIASSIAN-Blog-section col-max-blocked center">
								<p>
									<b>
										Je suis Christophe Soukiassian, coach
										certifié depuis plus de 15 ans
									</b>
									, spécialisé dans l'exploration des états
									inconscients à travers le monde, avec des{' '}
									<u>maîtrises</u> en thérapie
									comportementaliste, PNL, états de conscience
									modifiés et coaching intuitif.
									<br />
									<br />
									<b>À cette expertise</b> s'ajoutent trois
									spécialités essentielles dans mon
									accompagnement : la psychologie
									comportementaliste, la Gestalt-thérapie et
									l’Analyse transactionnelle.
									<br />
									<br />
									<b>
										Je pratique des séances de coaching dans
										divers domaines
									</b>{' '}
									: vie personnelle, scolaire, santé,
									relations, recherche d’emploi, compétences
									professionnelles, conduite...
									<br />
									<br />
									<b>
										Depuis 2009, j'ai développé une approche
										innovante
									</b>{' '}
									en communication intuitive consciente,
									facilitant la gestion des états émotionnels,
									psychologiques et physiologiques, et
									favorisant des moteurs puissants de
									croissance personnelle.
									<br />
									<br />
									<b>L’approche repose</b> sur une
									communication bio sensorielle, apportant des
									régulations aux modes de pensée influençant
									nos comportements et actions. Elle facilite
									des <u>changements positifs rapides</u>,
									favorisant une{' '}
									<u>évolution continue au quotidien</u>.
									<br />
									<br />
									<br />
									<b>
										Voici les Trois Concepts Novateurs de
										l’École L.C.C :
									</b>
								</p>
								<ul>
									<li>
										E.E.N.B : l’Écoute et l’Expression Non
										Blessantes des états inconscients.
									</li>
									<li>
										I.T : l’Interprétation et la
										Transformation des états inconscients.
									</li>
									<li>
										R.C.G : le Ressenti en Compréhension et
										Gestion des états inconscients.
									</li>
								</ul>
							</section>
						</>
					)}

					<Footer />
				</div>
			)}
		</>
	)
}

Header.propTypes = {
	isIdBlog: PropTypes.bool,
}

Header.defaultProps = {
	isIdBlog: false,
}

export default Blog
