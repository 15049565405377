import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import Error from '../Error'

import './index.scss'

function Select({ title, name, multiple, error, options, reset, className }) {
	const [isError, setIsError] = useState(false)

	const selectHandling = function (evt) {
		const optionPseudo = evt.target
		const optionElement =
			optionPseudo.parentElement.previousElementSibling.querySelector(
				`[data-id="${optionPseudo.dataset.id}"]`,
			)

		if (multiple) {
			if (optionElement.selected) {
				optionPseudo.classList.remove(
					'SOUKIASSIAN-Select-option-selected',
				)
				optionElement.selected = false
			} else {
				optionPseudo.classList.add('SOUKIASSIAN-Select-option-selected')
				optionElement.selected = true
			}
		} else {
			const optionPseudoList =
				optionPseudo.parentElement.querySelectorAll(
					'.SOUKIASSIAN-Select-option-selected',
				)
			const optionElementList =
				optionPseudo.parentElement.previousElementSibling.querySelectorAll(
					'option',
				)

			optionElementList.forEach((optionElement) => {
				optionElement.selected = false
			})

			optionPseudoList.forEach((optionPseudo) => {
				optionPseudo.classList.remove(
					'SOUKIASSIAN-Select-option-selected',
				)
			})

			optionPseudo.classList.add('SOUKIASSIAN-Select-option-selected')
			optionElement.selected = true
		}
	}

	useEffect(() => {
		if (reset[0]) {
			document
				.querySelectorAll('.SOUKIASSIAN-Select-container select option')
				.forEach((optionElement) => {
					optionElement.selected = false
				})
			document
				.querySelectorAll(
					'.SOUKIASSIAN-Select .SOUKIASSIAN-Select-option-selected',
				)
				.forEach((optionPseudo) => {
					optionPseudo.classList.remove(
						'SOUKIASSIAN-Select-option-selected',
					)
				})
			if (reset[1]) {
				reset[1](false)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reset[0]])

	return (
		<div className={`SOUKIASSIAN-Select-container ${className}`}>
			{title && <div className="SOUKIASSIAN-Select-title">{title}</div>}

			<select name={name} multiple={multiple} autoComplete="off" hidden>
				{options.map((option, id) => (
					<option
						key={`select${id}${option.title}`}
						value={option.value ? option.value : option.title}
						data-id={id}
					></option>
				))}
			</select>

			<div className="SOUKIASSIAN-Select">
				{options.map((option, id) => (
					<div
						key={`selectdiv${id}${option.title}`}
						className="SOUKIASSIAN-Select-option"
						onClick={selectHandling}
						data-id={id}
					>
						{option.title}
					</div>
				))}
			</div>

			{isError && (
				<Error
					error={error}
					deleteError={() => {
						setIsError(false)
					}}
				/>
			)}
		</div>
	)
}

Select.propTypes = {
	title: PropTypes.string,
	name: PropTypes.string,
	multiple: PropTypes.bool,
	error: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
	reset: PropTypes.array,
	className: PropTypes.string,
}

Select.defaultProps = {
	multiple: false,
	options: [],
	className: '',
}

export default Select
