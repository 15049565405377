import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

import './index.scss'

function Error({ error, deleteError, className }) {
	return (
		<div className={`SOUKIASSIAN-Error ${className}`}>
			{deleteError && (
				<span
					className="SOUKIASSIAN-Error-delete"
					onClick={deleteError}
				>
					<FontAwesomeIcon icon={faTrashCan} />
				</span>
			)}
			<FontAwesomeIcon
				icon={faTriangleExclamation}
				className="SOUKIASSIAN-Error-exclamation"
			/>
			{error}
		</div>
	)
}

Error.propTypes = {
	error: PropTypes.string.isRequired,
	deleteError: PropTypes.func,
	className: PropTypes.string,
}

Error.defaultProps = {
	className: '',
}

export default Error
