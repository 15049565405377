import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { updateClient } from '../../../actions/client.action'

import { isRightAccess } from '../../../utils'
import { RIGHTS_MODIFY_OR_ADD_CLIENT } from '../../../utils/env'

import Input from '../../Input'
import Select from '../../Select'
import Button from '../../Button'

function ClientRow({
	id,
	type,
	firstName,
	secondName,
	phone,
	email,
	address,
	zip,
	city,
	country,
	note,
}) {
	const [typeIsEdit, setTypeIsEdit] = useState(false)
	const [firstNameIsEdit, setFirstNameIsEdit] = useState(false)
	const [secondNameIsEdit, setSecondNameIsEdit] = useState(false)
	const [phoneIsEdit, setPhoneIsEdit] = useState(false)
	const [emailIsEdit, setEmailIsEdit] = useState(false)
	const [addressIsEdit, setAddressIsEdit] = useState(false)
	const [noteIsEdit, setNoteIsEdit] = useState(false)
	const user = useSelector((state) => state.userReducer)

	const dispatch = useDispatch()

	const updateClientHandling = function (e, setIsEdit) {
		const dataContainer = e.target.parentElement

		const dataList = {}

		dataContainer
			.querySelectorAll('input, textarea')
			.forEach((dataElement) => {
				dataList[dataElement.name] = dataElement.value
			})

		dispatch(updateClient(id, dataList, setIsEdit))
	}

	const isEditHandling = (setIsEdit) => {
		setIsEdit(isRightAccess(user, RIGHTS_MODIFY_OR_ADD_CLIENT))
	}

	return (
		<tr className="SOUKIASSIAN-AdminClientTable-ClientRow">
			<td></td>
			<td>{id}</td>
			<td onDoubleClick={setTypeIsEdit}>
				{typeIsEdit ? <Select /> : <span data-name="type">{type}</span>}
			</td>
			<td
				onDoubleClick={() => {
					isEditHandling(setFirstNameIsEdit)
				}}
			>
				{firstNameIsEdit ? (
					<>
						<Input
							name="firstName"
							title="Prénom"
							value={firstName}
							admin
						/>
						<Button
							onClick={(e) =>
								updateClientHandling(e, setFirstNameIsEdit)
							}
						>
							Sauvegarder
						</Button>
						<Button onClick={() => setFirstNameIsEdit(false)}>
							Annuler
						</Button>
					</>
				) : (
					<span data-name="firstName">{firstName}</span>
				)}
			</td>
			<td
				onDoubleClick={() => {
					isEditHandling(setSecondNameIsEdit)
				}}
			>
				{secondNameIsEdit ? (
					<>
						<Input
							name="secondName"
							title="Nom"
							value={secondName}
							admin
						/>
						<Button
							onClick={(e) =>
								updateClientHandling(e, setSecondNameIsEdit)
							}
						>
							Sauvegarder
						</Button>
						<Button onClick={() => setSecondNameIsEdit(false)}>
							Annuler
						</Button>
					</>
				) : (
					<span data-name="secondName">{secondName}</span>
				)}
			</td>
			<td
				onDoubleClick={() => {
					isEditHandling(setPhoneIsEdit)
				}}
			>
				{phoneIsEdit ? (
					<>
						<Input
							name="phone"
							title="Téléphone"
							message="Séparé par un ;"
							value={phone}
							admin
						/>
						<Button
							onClick={(e) =>
								updateClientHandling(e, setPhoneIsEdit)
							}
						>
							Sauvegarder
						</Button>
						<Button onClick={() => setPhoneIsEdit(false)}>
							Annuler
						</Button>
					</>
				) : (
					<span data-name="phone">{phone}</span>
				)}
			</td>
			<td
				onDoubleClick={() => {
					isEditHandling(setEmailIsEdit)
				}}
			>
				{emailIsEdit ? (
					<>
						<Input
							name="email"
							title="Email"
							message="Séparé par un ;"
							value={email}
							admin
						/>
						<Button
							onClick={(e) =>
								updateClientHandling(e, setEmailIsEdit)
							}
						>
							Sauvegarder
						</Button>
						<Button onClick={() => setEmailIsEdit(false)}>
							Annuler
						</Button>
					</>
				) : (
					<span data-name="email">{email}</span>
				)}
			</td>
			<td
				onDoubleClick={() => {
					isEditHandling(setAddressIsEdit)
				}}
			>
				{addressIsEdit ? (
					<>
						<Input
							name="address"
							title="Numéro et Adresse"
							value={address}
							admin
						/>
					</>
				) : (
					<span data-name="address">{address}</span>
				)}
				<br />
				{addressIsEdit ? (
					<>
						<Input
							name="zip"
							title="Code postal"
							value={zip}
							admin
						/>
					</>
				) : (
					<span data-name="zip">{zip}</span>
				)}{' '}
				{addressIsEdit ? (
					<>
						<Input name="city" title="Ville" value={city} admin />
					</>
				) : (
					<span data-name="city">{city}</span>
				)}
				<br />
				{addressIsEdit ? (
					<>
						<Input
							name="country"
							title="Pays"
							value={country}
							admin
						/>
						<Button
							onClick={(e) =>
								updateClientHandling(e, setAddressIsEdit)
							}
						>
							Sauvegarder
						</Button>
						<Button onClick={() => setAddressIsEdit(false)}>
							Annuler
						</Button>
					</>
				) : (
					<span data-name="country">{country}</span>
				)}
			</td>
			<td
				onDoubleClick={() => {
					isEditHandling(setNoteIsEdit)
				}}
			>
				{noteIsEdit ? (
					<>
						<Input
							type="textarea"
							name="note"
							title="Note"
							value={note}
							admin
						/>
						<Button
							onClick={(e) =>
								updateClientHandling(e, setNoteIsEdit)
							}
						>
							Sauvegarder
						</Button>
						<Button onClick={() => setNoteIsEdit(false)}>
							Annuler
						</Button>
					</>
				) : (
					<span data-name="note">{note}</span>
				)}
			</td>
		</tr>
	)
}

export default ClientRow
