import AdminHome from '../../pages/routeAdmin/Home'
import AdminClientManagement from '../../pages/routeAdmin/ClientManagement'
import AdminImageHandler from '../../pages/routeAdmin/ImageHandler'
import AdminRegistrationHandler from '../../pages/routeAdmin/RegistrationHandler'
import AdminBlogHandler from '../../pages/routeAdmin/AdminBlogHandler'
import Error404 from '../../pages/Error/404'

import { initPage } from '../../utils'

import registrationListLoader from './registrationListLoader'

export const pathAdminPages = [
	{
		path: '/admin/home',
		element: <AdminHome />,
		loader: () => initPage('Admin Accueil'),
	},
	{
		path: '/admin/client-management',
		element: <AdminClientManagement />,
		loader: () => initPage('Admin Gestion Client'),
	},
	{
		path: '/admin/image-handler',
		element: <AdminImageHandler />,
		loader: () => initPage('Admin Gestion Image'),
	},
	{
		path: '/admin/registration-handler',
		element: <AdminRegistrationHandler />,
		errorElement: <Error404 />,
		loader: () => initPage('Admin Gestion Inscription'),
		children: [
			{
				path: ':category/:subcategory',
				element: <AdminRegistrationHandler isListRegistration />,
				loader: registrationListLoader,
			},
		],
	},
	{
		path: '/admin/blog-handler',
		element: <AdminBlogHandler />,
		loader: () => initPage('Admin Gestion Actualité'),
	},
]
