import { useOutlet, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Collapse from '../../components/Collapse'
import ClientForm from '../../components/ClientForm'
import ClientNewPassword from '../../components/ClientNewPassword'
import ClientConfirmAccount from './ConfirmAccount'
import LoginClient from '../../components/LoginClient'

import './index.scss'

function ClientAccount({ isNewPassword, isConfirmAccount }) {
	const { email, token } = useParams()
	const outlet = useOutlet()
	const isConnected = useSelector((state) => state.clientReducer.isConnected)

	return (
		<>
			{outlet || (
				<div className="SOUKIASSIAN-ClientAccount">
					<Header type="finalPage" title="Compte client L.C.C. ©" />

					{isConnected || isNewPassword || isConfirmAccount ? (
						<div className="SOUKIASSIAN-ClientAccount-content col-max-blocked center">
							{!isNewPassword && !isConfirmAccount && (
								<>
									<Collapse
										title="Information du compte"
										open
									>
										<ClientForm />
									</Collapse>
									<Collapse title="Changer le mot de passe">
										<ClientNewPassword />
									</Collapse>
									<Collapse title="Action du compte"></Collapse>
								</>
							)}

							{isNewPassword && (
								<Collapse
									title="Initialisez le mot de passe"
									open
								>
									<ClientNewPassword
										noOldPassword={isNewPassword}
										email={email}
										token={token}
									/>
								</Collapse>
							)}

							{isConfirmAccount && (
								<ClientConfirmAccount
									email={email}
									token={token}
								/>
							)}
						</div>
					) : (
						<LoginClient />
					)}

					<Footer />
				</div>
			)}
		</>
	)
}

ClientAccount.propTypes = {
	isNewPassword: PropTypes.bool.isRequired,
	isConfirmAccount: PropTypes.bool.isRequired,
}

ClientAccount.defaultProps = {
	isNewPassword: false,
	isConfirmAccount: false,
}

export default ClientAccount
