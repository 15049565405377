import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
	LOGIN_CLIENT,
	CREATE_ACCOUNT_CLIENT,
	loginClient,
	createAccount,
} from '../../actions/client.action'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleUser } from '@fortawesome/free-regular-svg-icons'
import { faReply } from '@fortawesome/free-solid-svg-icons'

import Input from '../Input'
import Button from '../Button'
import Error from '../Error'
import Success from '../Success'

import './index.scss'

function LoginClient() {
	const [mailCreateSending, setMailCreateSending] = useState(false)
	const [connectSending, setConnectSending] = useState(false)
	const [doReset, setDoReset] = useState({})
	const [errors, setErrors] = useState({})
	const [success, setSuccess] = useState({})
	const [countdown, setCountdown] = useState(0)
	const [stage, setStage] = useState('Connect or Create')
	const dispatch = useDispatch()

	const errorHandling = (error) => {
		let errorContainer = { ...errors, message: error.message }

		if (
			error.cause?.type === LOGIN_CLIENT ||
			error.cause?.type === CREATE_ACCOUNT_CLIENT
		) {
			errorContainer.title = error.cause.title
		} else {
			errorContainer.title = 'Erreur de connexion'
		}
		setErrors(errorContainer)
	}

	const signInHandling = (evt) => {
		evt.preventDefault()
		if (!connectSending) {
			setConnectSending(true)

			const formData = new FormData(evt.target)

			const email = formData.get('email')
			const password = formData.get('password')

			dispatch(loginClient(email, password))
				.then((errors) => {
					if (errors) {
						setErrors(errors)
					}
				})
				.catch(errorHandling)
				.finally(() => {
					setConnectSending(false)
				})
		}
	}

	const createAccountHandling = (evt) => {
		evt.preventDefault()
		if (!mailCreateSending) {
			setMailCreateSending(true)

			const formData = new FormData(evt.target)

			const email = formData.get('email')

			setErrors({})

			dispatch(createAccount(email, setSuccess))
				.then((errors) => {
					if (errors) {
						setErrors(errors)
					}
				})
				.catch(errorHandling)
		}
	}

	const countdownHandling = (seconds) => {
		let countdown = seconds
		const countdownID = setInterval(() => {
			if (countdown > 0) {
				countdown--
				setCountdown(countdown)
			} else {
				clearInterval(countdownID)
				countdown = 0
				setCountdown(countdown)
				setSuccess({})
				setMailCreateSending(false)
			}
		}, 1000)
	}

	useEffect(() => {
		if (mailCreateSending && Object.keys(success).length !== 0) {
			countdownHandling(60)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [success])

	return (
		<div className="SOUKIASSIAN-LoginClient">
			<span className="SOUKIASSIAN-LoginClient-title">
				{(stage === 'Connect' || stage === 'Create') && (
					<FontAwesomeIcon
						icon={faReply}
						className="SOUKIASSIAN-LoginClient-title-iconBack"
						onClick={() => setStage('Connect or Create')}
					/>
				)}

				<FontAwesomeIcon
					icon={faCircleUser}
					className="SOUKIASSIAN-LoginClient-title-iconUser"
				/>

				{(stage === 'Connect' || stage === 'Connect or Create') && (
					<>CONNEXION</>
				)}
				{stage === 'Create' && <>CRÉATION</>}
			</span>

			{stage === 'Connect or Create' && (
				<>
					<Button onClick={() => setStage('Connect')}>
						Se connecter
					</Button>
					<Button onClick={() => setStage('Create')}>
						Créer son compte
					</Button>
				</>
			)}

			{stage === 'Connect' && (
				<>
					<form onSubmit={signInHandling}>
						{errors.message && (
							<Error
								error={`${errors.title} : ${errors.message}`}
								deleteError={() => {
									setErrors({})
								}}
							/>
						)}

						<label>E-mail :</label>
						<Input
							name="email"
							type="email"
							title="Votre e-mail"
							maxLength="255"
							errorDirect={
								errors.connect?.email
									? errors.connect.email
									: null
							}
							required
							reset={[doReset]}
						/>
						<label>Mot de passe :</label>
						<Input
							type="password"
							name="password"
							title="Votre mot de passe"
							maxLength="255"
							errorDirect={
								errors.connect?.password
									? errors.connect.password
									: null
							}
							required
							reset={[doReset, setDoReset]}
						/>

						<Button isDisabled={connectSending}>
							Se connecter
						</Button>
					</form>
				</>
			)}

			{stage === 'Create' && (
				<>
					<form onSubmit={createAccountHandling}>
						{errors.message && (
							<Error
								error={`${errors.title} : ${errors.message}`}
								deleteError={() => {
									setErrors({})
								}}
							/>
						)}
						{success.create && (
							<Success
								success={`${success.create}`}
								deleteSuccess={() => {
									setSuccess({})
								}}
							/>
						)}

						<label>E-mail :</label>
						<Input
							name="email"
							type="email"
							title="Votre e-mail"
							maxLength="255"
							errorDirect={
								errors.create?.email
									? errors.create.email
									: null
							}
							required
							reset={[doReset, setDoReset]}
						/>
						<p>
							Un mail vous sera envoyé, il faudra alors suivre les
							instructions.
							<br />
							(Si vous ne recevez pas le mail, pensez à vérifier
							vos spams)
						</p>

						<Button isDisabled={mailCreateSending}>
							Envoyer le mail {countdown > 0 && <>{countdown}</>}
						</Button>
					</form>
				</>
			)}
		</div>
	)
}

export default LoginClient
