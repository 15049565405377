import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import Login from '../../components/Login'
import Button from '../../components/Button'

import './index.scss'

function Error403({ redirectLink }) {
	const isConnected = useSelector((state) => state.userReducer.isConnected)

	document.title = 'LCC - 403 - La page vous est interdit !'

	return (
		<>
			{isConnected ? (
				<main className="SOUKIASSIAN-Error">
					<h1 className="SOUKIASSIAN-Error-title">403</h1>
					<p className="SOUKIASSIAN-Error-description">
						Vous n'avez pas le droit d'entrer ici !
					</p>

					<Button link={redirectLink}>
						Retourner en lieu sûr...
					</Button>
				</main>
			) : (
				<Login />
			)}
		</>
	)
}

Error403.propTypes = {
	redirectLink: PropTypes.string,
}

export default Error403
