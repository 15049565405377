import PropTypes from 'prop-types'

import './index.scss'

function PercentTextContainer({ title, description, children, textBottom }) {
	return (
		<section className="SOUKIASSIAN-PercentTextContainer col-max-blocked">
			<div className="SOUKIASSIAN-PercentTextContainer-line"></div>
			{title && <h2>{title}</h2>}
			{description && <p>{description}</p>}
			{children}
			<div className="SOUKIASSIAN-PercentTextContainer-line"></div>
			{textBottom && <p>{textBottom}</p>}
		</section>
	)
}

PercentTextContainer.propTypes = {
	titleTop: PropTypes.any,
	descriptionTop: PropTypes.any,
	children: PropTypes.any.isRequired,
	textBottom: PropTypes.any,
}

export default PercentTextContainer
