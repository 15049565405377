import axios from 'axios'

import { inProgressCheck, inProgressAdd, inProgressRemove } from '../utils'
import { BASE_URL_API } from '../utils/env'

const baseUrl = BASE_URL_API + 'client'

let listInProgress = []

export const GET_CLIENTS = 'GET_CLIENTS'
export const ADD_CLIENT = 'ADD_CLIENT'
export const UPDATE_CLIENT = 'UPDATE_CLIENT'
export const CREATE_ACCOUNT_CLIENT = 'CREATE_ACCOUNT_CLIENT'
export const CONFIRM_ACCOUNT_CLIENT = 'CONFIRM_ACCOUNT_CLIENT'
export const NEW_PASSWORD_CLIENT = 'NEW_PASSWORD_CLIENT'
export const LOGIN_CLIENT = 'LOGIN_CLIENT'
export const LOGOUT_CLIENT = 'LOGOUT_CLIENT'

export const getClients = () => {
	if (!inProgressCheck(GET_CLIENTS, listInProgress)) {
		inProgressAdd(GET_CLIENTS, listInProgress)
		return async (dispatch) => {
			return axios
				.get(baseUrl + '/getAll', { withCredentials: true })
				.then((result) => {
					if (result.status === 200) {
						dispatch({
							type: GET_CLIENTS,
							payload: result.data,
						})
					}
				})
				.catch((error) => {
					errorHandling(error, 'récupérer les clients', GET_CLIENTS)
				})
				.finally(() => {
					inProgressRemove(GET_CLIENTS, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

export const addClient = (client, reset = false, setClientFilter = false) => {
	if (!inProgressCheck(ADD_CLIENT, listInProgress)) {
		inProgressAdd(ADD_CLIENT, listInProgress)
		return async (dispatch) => {
			return axios
				.post(baseUrl + '/add', JSON.stringify(client), {
					headers: {
						'Content-Type': 'application/json',
					},
					withCredentials: true,
				})
				.then((result) => {
					if (result.status === 200) {
						if (reset) reset(true)
						if (setClientFilter)
							setClientFilter({
								dataName: 'all',
								value: true,
							})
						dispatch({
							type: ADD_CLIENT,
							payload: result.data,
						})
					}
				})
				.catch((error) => {
					if (error.response.status === 400) {
						return error.response.data
					}
					errorHandling(error, 'ajouter un client', ADD_CLIENT)
				})
				.finally(() => {
					inProgressRemove(ADD_CLIENT, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

export const updateClient = (
	idClient,
	dataList,
	setIsEdit = false,
	fromClient = false,
) => {
	if (!inProgressCheck(UPDATE_CLIENT, listInProgress)) {
		inProgressAdd(UPDATE_CLIENT, listInProgress)
		return async (dispatch) => {
			return axios
				.put(
					baseUrl + '/update/' + idClient,
					JSON.stringify(dataList),
					{
						headers: {
							'Content-Type': 'application/json',
						},
						withCredentials: true,
					},
				)
				.then((result) => {
					if (result.status === 200) {
						if (setIsEdit) {
							setIsEdit(false)
						}
						dispatch({
							type: UPDATE_CLIENT,
							payload: result.data,
							isClient: fromClient,
						})
					}
				})
				.catch((error) => {
					if (error.response.status === 400) {
						return error.response.data.errors
					}
					errorHandling(
						error,
						'la mise à jour du client',
						UPDATE_CLIENT,
					)
				})
				.finally(() => {
					inProgressRemove(UPDATE_CLIENT, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

export const createAccount = (email = false, setSuccess) => {
	if (!inProgressCheck(CREATE_ACCOUNT_CLIENT, listInProgress)) {
		inProgressAdd(CREATE_ACCOUNT_CLIENT, listInProgress)
		return async () => {
			return axios
				.post(
					baseUrl + '/create_account',
					JSON.stringify({ email: email }),
					{
						headers: {
							'Content-Type': 'application/json',
						},
						withCredentials: true,
					},
				)
				.then((result) => {
					if (result.status === 200) {
						setSuccess(result.data.success)
					}
				})
				.catch((error) => {
					if (error.response.status === 400) {
						return error.response.data.errors
					}
					errorHandling(
						error,
						'la création du compte',
						CREATE_ACCOUNT_CLIENT,
					)
				})
				.finally(() => {
					inProgressRemove(CREATE_ACCOUNT_CLIENT, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

export const confirmAccount = (email = false, token = false, setSuccess) => {
	if (!inProgressCheck(CONFIRM_ACCOUNT_CLIENT, listInProgress)) {
		inProgressAdd(CONFIRM_ACCOUNT_CLIENT, listInProgress)
		return async () => {
			return axios
				.put(
					baseUrl + '/confirm_account',
					JSON.stringify({ email: email, token: token }),
					{
						headers: {
							'Content-Type': 'application/json',
						},
						withCredentials: true,
					},
				)
				.then((result) => {
					if (result.status === 200) {
						setSuccess(result.data.success)
					}
				})
				.catch((error) => {
					if (error.response.status === 400) {
						return error.response.data
					}
					errorHandling(
						error,
						'la confirmation du compte',
						CONFIRM_ACCOUNT_CLIENT,
					)
				})
				.finally(() => {
					inProgressRemove(CONFIRM_ACCOUNT_CLIENT, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

export const newPasswordClient = (
	email = false,
	token = false,
	oldPassword = false,
	newPassword = false,
	setSuccess,
) => {
	if (!inProgressCheck(NEW_PASSWORD_CLIENT, listInProgress)) {
		inProgressAdd(NEW_PASSWORD_CLIENT, listInProgress)
		return async () => {
			return axios
				.put(
					baseUrl + '/password',
					JSON.stringify({
						email: email,
						token: token,
						oldPassword: oldPassword,
						newPassword: newPassword,
					}),
					{
						headers: {
							'Content-Type': 'application/json',
						},
						withCredentials: true,
					},
				)
				.then((result) => {
					if (result.status === 200) {
						setSuccess(true)
					}
				})
				.catch((error) => {
					if (error.response.status === 400) {
						return error.response.data.errors
					}
					errorHandling(
						error,
						'la création du mot de passe',
						NEW_PASSWORD_CLIENT,
					)
				})
				.finally(() => {
					inProgressRemove(NEW_PASSWORD_CLIENT, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

export const loginClient = (email = false, password = false) => {
	if (!inProgressCheck(LOGIN_CLIENT, listInProgress)) {
		inProgressAdd(LOGIN_CLIENT, listInProgress)
		return (dispatch) => {
			return axios
				.post(
					baseUrl + '/login',
					JSON.stringify({ email: email, password: password }),
					{
						headers: {
							'Content-Type': 'application/json',
						},
						withCredentials: true,
					},
				)
				.then((result) => {
					if (result.status === 200) {
						dispatch({
							type: LOGIN_CLIENT,
							payload: result.data,
						})
					}
				})
				.catch((error) => {
					if (error.response.status === 400) {
						return error.response.data.errors
					}
					errorHandling(error, 'se connecter', LOGIN_CLIENT)
				})
				.finally(() => {
					inProgressRemove(LOGIN_CLIENT, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

export const logoutClient = () => {
	if (!inProgressCheck(LOGOUT_CLIENT, listInProgress)) {
		inProgressAdd(LOGOUT_CLIENT, listInProgress)
		return (dispatch) => {
			return axios
				.post(baseUrl + '/logout', null, {
					withCredentials: true,
				})
				.then((result) => {
					if (result.status === 200) {
						dispatch({
							type: LOGOUT_CLIENT,
						})
					}
				})
				.catch((error) => {
					errorHandling(error, 'se déconnecter', LOGOUT_CLIENT)
				})
				.finally(() => {
					inProgressRemove(LOGOUT_CLIENT, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

const errorHandling = (error, title, actionType) => {
	let errorTitle = ''
	let errorMessage = ''

	if (error.response) {
		switch (error.response.status) {
			case 401:
				errorTitle = 'Erreur de connexion'
				errorMessage = error.response.data.message
				break

			case 404:
				errorTitle = 'Erreur pour ' + title
				errorMessage = "La requête n'existe pas"
				break

			case 500:
				errorTitle = 'Erreur serveur pour ' + title
				errorMessage = error.response.data.message
				break

			default:
				errorTitle = 'Erreur pour ' + title
				errorMessage = 'Erreur inconnue'
				break
		}
	} else if (error.request) {
		errorTitle = 'Erreur serveur pour ' + title
		errorMessage = 'Le serveur ne répond pas'
	} else {
		errorTitle = 'Erreur pour ' + title
		errorMessage = error.message
	}

	throw new Error(errorMessage, {
		cause: { type: actionType, title: errorTitle },
	})
}
