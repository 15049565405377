import { useLoaderData, useOutlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { isRightAccess } from '../../../utils'
import { RIGHTS_REGISTRATION_LIST } from '../../../utils/env'

import Login from '../../../components/Login'
import Header from '../../../components/Header'
import RegistrationList from '../../../components/admin/RegistrationList/'
import RegistrationCategoryList from '../../../components/admin/RegistrationList/Category'

import Error403 from '../../Error/403'

function AdminRegistrationHandler({ isListRegistration }) {
	const outlet = useOutlet()
	const data = useLoaderData()
	const user = useSelector((state) => state.userReducer)

	return (
		<>
			{user.isConnected ? (
				<>
					{outlet || (
						<div className="SOUKIASSIAN-AdminRegistrationHandler">
							{isRightAccess(user, RIGHTS_REGISTRATION_LIST) ? (
								<>
									<Header type="admin" />
									{isListRegistration ? (
										<RegistrationList data={data} />
									) : (
										<RegistrationCategoryList />
									)}
								</>
							) : (
								<>
									<Header type="admin" />
									<Error403 redirectLink="/admin/home" />
								</>
							)}
						</div>
					)}
				</>
			) : (
				<Login />
			)}
		</>
	)
}

AdminRegistrationHandler.propTypes = {
	isListRegistration: PropTypes.bool.isRequired,
}

AdminRegistrationHandler.defaultProps = {
	isListRegistration: false,
}

export default AdminRegistrationHandler
