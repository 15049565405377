import {
	GET_IMAGES,
	GET_IMAGES_BY_IDS,
	ADD_IMAGE,
	UPDATE_IMAGE,
	DELETE_IMAGE,
} from '../actions/image.action'

const initialState = { list: {} }

export default function imageReducer(state = initialState, action) {
	switch (action.type) {
		case GET_IMAGES:
			return {
				...state,
				list: imageListToObjectById(state.list, action.payload),
			}
		case GET_IMAGES_BY_IDS:
			return {
				...state,
				list: imageListToObjectById(state.list, action.payload),
			}
		case ADD_IMAGE:
			return {
				...state,
				list: updateById(state.list, action.payload),
			}
		case UPDATE_IMAGE:
			return {
				...state,
				list: updateById(state.list, action.payload),
			}
		case DELETE_IMAGE:
			return {
				...state,
				list: { ...deleteById(state.list, action.payload) },
			}
		default:
			return { ...state }
	}
}

function imageListToObjectById(oldObjectById, imageList) {
	let newImageList = {}

	for (const key in imageList) {
		newImageList[imageList[key].id] = imageList[key]
	}

	return { ...oldObjectById, ...newImageList }
}

function updateById(imageList, updatedImage) {
	let newImageList = { ...imageList }
	newImageList[updatedImage.id] = updatedImage
	return newImageList
}

function deleteById(oldImageList, deleteImageId) {
	let newImageList = {}

	for (const id in oldImageList) {
		if (id === deleteImageId) {
			newImageList[id] = null
		} else {
			newImageList[id] = oldImageList[id]
		}
	}

	return newImageList
}
