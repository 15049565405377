import PropTypes from 'prop-types'

function PercentText({ percent, text }) {
	return (
		<div className="SOUKIASSIAN-PercentTextContainer-child">
			<div className="SOUKIASSIAN-PercentTextContainer-child-percent">
				{percent}
			</div>
			<p>{text}</p>
		</div>
	)
}

PercentText.propTypes = {
	percent: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
}

export default PercentText
