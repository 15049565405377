import { initPage } from '../../utils'

import Events from '../../pages/intermediate/Events'
import Stages from '../../pages/intermediate/Stages'
import TrainerTrainings from '../../pages/intermediate/TrainerTrainings'

import Webinars from '../../pages/final/events/Webinars'
import Tradeshows from '../../pages/final/events/Tradeshows'
import Conferences from '../../pages/final/events/Conferences'
import Workshops from '../../pages/final/events/Workshops'

import ConsciousnessInitiation from '../../pages/final/stages/ConsciousnessInitiation'
import Sagacity from '../../pages/final/stages/Sagacity'
import Meditation from '../../pages/final/stages/Meditation'
import EatWell from '../../pages/final/stages/EatWell'
import ForeignTrip from '../../pages/final/stages/ForeignTrip'

import TrainerSupervisor from '../../pages/final/trainerTrainings/TrainerSupervisor'
import TrainerConsciousCommunication from '../../pages/final/trainerTrainings/TrainerConsciousCommunication'
import TrainerMindfulnessCoaching from '../../pages/final/trainerTrainings/TrainerMindfulnessCoaching'
import TrainerBusinessCoaching from '../../pages/final/trainerTrainings/TrainerBusinessCoaching'

import LccSchool from '../../pages/final/LccSchool'
import Method from '../../pages/final/Method'
import Christophe from '../../pages/final/Christophe'

import TrainingLifeCoaching from '../../pages/final/trainings/TrainingLifeCoaching'
import TrainingBecomeCoach from '../../pages/final/trainings/TrainingBecomeCoach'
import TrainingBusinessCoaching from '../../pages/final/trainings/TrainingBusinessCoaching'

import SessionLifeCoaching from '../../pages/final/sessions/SessionLifeCoaching'
import SessionBecomeCoach from '../../pages/final/sessions/SessionBecomeCoach'
import SessionBusinessCoaching from '../../pages/final/sessions/SessionBusinessCoaching'

//* Path : Intermediate page
//** From Home page : components Button
export const pathIntermediatePages = [
	{
		path: '/events/',
		element: <Events />,
		loader: () => initPage('Événements'),
	},
	{
		path: '/stages/',
		element: <Stages />,
		loader: () => initPage('Stages'),
	},
	{
		path: '/trainer-trainings/', //Picture alone
		element: <TrainerTrainings />,
		loader: () => initPage('Formations de formateur'),
	},
]
//* Path END : Intermediate page

//* Path : Final page
//** From Events : component ImageTextFilletPowder
export const pathEventPages = [
	//  From Events : first ITFPChild
	{
		path: '/events/webinars',
		element: <Webinars />,
		loader: () => initPage('Événement : Webinaires'),
	},
	//  From Events : second ITFPChild
	{
		path: '/events/tradeshows',
		element: <Tradeshows />,
		loader: () => initPage('Événement : Salons'),
	},
	//  From Events : third ITFPChild
	{
		path: '/events/conferences',
		element: <Conferences />,
		loader: () => initPage('Événement : Conférences'),
	},
	//  From Events : fourth ITFPChild
	{
		path: '/events/workshops',
		element: <Workshops />,
		loader: () => initPage('Événement : Ateliers'),
	},
]
//** From Stages : component ImageTextFilletPowder
export const pathStagePages = [
	//*** From Stages : first component ITFPChild
	{
		path: '/stages/consciousness-initiation',
		element: <ConsciousnessInitiation />,
		loader: () => initPage('Stage : Initiation conscience'),
	},
	//*** From Stages : second component ITFPChild
	{
		path: '/stages/sagacity',
		element: <Sagacity />,
		loader: () => initPage('Stage : Sagesse'),
	},
	//*** From Stages : third component ITFPChild
	{
		path: '/stages/meditation',
		element: <Meditation />,
		loader: () => initPage('Stage : Méditation'),
	},
	//*** From Stages : fourth component ITFPChild
	{
		path: '/stages/eat-well',
		element: <EatWell />,
		loader: () => initPage('Stage : Bien se nourrir'),
	},
	//*** From Stages : fifth component ITFPChild
	{
		path: '/stages/foreign-trip',
		element: <ForeignTrip />,
		loader: () => initPage("Stage : Séjour à l'étranger"),
	},
]
//** From TrainerTrainings : component ImageTextFilletPowder
export const pathTrainerTrainingPages = [
	//  From TrainerTrainings : first ITFPChild
	{
		path: '/trainer-trainings/supervisor',
		element: <TrainerSupervisor />,
		loader: () => initPage('Formation formateur : Superviseur'),
	},
	//  From TrainerTrainings : second ITFPChild
	{
		path: '/trainer-trainings/conscious-communication',
		element: <TrainerConsciousCommunication />,
		loader: () =>
			initPage('Formation formateur : Communication consciente'),
	},
	//  From TrainerTrainings : third ITFPChild
	{
		path: '/trainer-trainings/mindfulness-coaching',
		element: <TrainerMindfulnessCoaching />,
		loader: () =>
			initPage('Formation formateur : Coaching pleine conscience'),
	},
	//  From TrainerTrainings : fourth ITFPChild
	{
		path: '/trainer-trainings/business-coaching',
		element: <TrainerBusinessCoaching />,
		loader: () => initPage("Formation formateur : Coaching d'entreprise"),
	},
]

//** From Home page : first component BannerThreePictureLink
export const pathPresentationPages = [
	{
		path: '/lcc-school',
		element: <LccSchool />,
		loader: () => initPage("L'école L.C.C. ©"),
	},
	{
		path: '/method',
		element: <Method />,
		loader: () => initPage('La méthode'),
	},
	{
		path: '/Christophe-Soukiassian',
		element: <Christophe />,
		loader: () => initPage('Christophe'),
	},
]
//** From Home page : second component BannerThreePictureLink
export const pathTrainingPages = [
	{
		path: '/training-life-coaching',
		element: <TrainingLifeCoaching />,
		loader: () => initPage('Formation : Coacher sa vie'),
	},
	{
		path: '/training-become-coach',
		element: <TrainingBecomeCoach />,
		loader: () => initPage('Formation : Devenir coach'),
	},
	{
		path: '/training-business-coaching',
		element: <TrainingBusinessCoaching />,
		loader: () => initPage("Formation : Coaching d'entreprise"),
	},
]
//** From Home page : third component BannerThreePictureLink
export const pathSessionPages = [
	{
		path: '/session-life-coaching',
		element: <SessionLifeCoaching />,
		loader: () => initPage('Séance : Coacher sa vie'),
	},
	{
		path: '/session-become-coach',
		element: <SessionBecomeCoach />,
		loader: () => initPage('Séance : Devenir coach'),
	},
	{
		path: '/session-business-coaching',
		element: <SessionBusinessCoaching />,
		loader: () => initPage("Séance : Coaching d'entreprise"),
	},
]
//* Path END : Final page
