import { useSelector } from 'react-redux'

import Login from '../../../components/Login'
import Header from '../../../components/Header'

function AdminHome() {
	const isConnected = useSelector((state) => state.userReducer.isConnected)

	return (
		<>
			{isConnected ? (
				<div className="SOUKIASSIAN-AdminHome">
					<Header type="admin" />
					Accueil
				</div>
			) : (
				<Login />
			)}
		</>
	)
}

export default AdminHome
