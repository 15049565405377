import { useSelector } from 'react-redux'

import './index.scss'

function ClientInfo({ requiredList }) {
	const client = useSelector((state) => state.clientReducer)

	const getClientData = function (dataName) {
		const required = requiredList.includes(dataName) ? true : false
		if (client.client) {
			return (
				<>
					{client.client[dataName] ? (
						client.client[dataName]
					) : (
						<span className="SOUKIASSIAN-ClientInfo-row-info-data-void">
							inconnu
						</span>
					)}
					{required && (
						<div className="SOUKIASSIAN-ClientInfo-row-info-data-required">
							Information nécessaire à l'inscription
						</div>
					)}
				</>
			)
		} else {
			return ''
		}
	}

	return (
		<div className="SOUKIASSIAN-ClientInfo">
			<div className="SOUKIASSIAN-ClientInfo-row col-12">
				<div className="SOUKIASSIAN-ClientInfo-row-info col-5 col-xs-12 col-xxs-12">
					<div className="SOUKIASSIAN-ClientInfo-row-info-title">
						Votre prénom
					</div>
					<div className="SOUKIASSIAN-ClientInfo-row-info-data">
						{getClientData('firstName')}
					</div>
				</div>
				<div className="SOUKIASSIAN-ClientInfo-row-info col-5 col-xs-12 col-xxs-12">
					<div className="SOUKIASSIAN-ClientInfo-row-info-title">
						Votre NOM
					</div>
					<div className="SOUKIASSIAN-ClientInfo-row-info-data">
						{getClientData('secondName')}
					</div>
				</div>
			</div>
			<div className="SOUKIASSIAN-ClientInfo-row col-12">
				<div className="SOUKIASSIAN-ClientInfo-row-info col-5 col-xs-12 col-xxs-12">
					<div className="SOUKIASSIAN-ClientInfo-row-info-title">
						Votre e-mail
					</div>
					<div className="SOUKIASSIAN-ClientInfo-row-info-data">
						{getClientData('email')}
					</div>
				</div>
				<div className="SOUKIASSIAN-ClientInfo-row-info col-5 col-xs-12 col-xxs-12">
					<div className="SOUKIASSIAN-ClientInfo-row-info-title">
						Votre téléphone personnel
					</div>
					<div className="SOUKIASSIAN-ClientInfo-row-info-data">
						{getClientData('phone')}
					</div>
				</div>
			</div>
			<div className="SOUKIASSIAN-ClientInfo-row col-12">
				<div className="SOUKIASSIAN-ClientInfo-row-info SOUKIASSIAN-ClientInfo-row-infoColorPro col-5 col-xs-12 col-xxs-12">
					<div className="SOUKIASSIAN-ClientInfo-row-info-title">
						Votre entreprise
					</div>
					<div className="SOUKIASSIAN-ClientInfo-row-info-data">
						{getClientData('business')}
					</div>
				</div>
				<div className="SOUKIASSIAN-ClientInfo-row-info SOUKIASSIAN-ClientInfo-row-infoColorPro col-5 col-xs-12 col-xxs-12">
					<div className="SOUKIASSIAN-ClientInfo-row-info-title">
						Votre téléphone professionnel
					</div>
					<div className="SOUKIASSIAN-ClientInfo-row-info-data">
						{getClientData('professionalPhone')}
					</div>
				</div>
			</div>
			<div className="SOUKIASSIAN-ClientInfo-row col-12">
				<div className="SOUKIASSIAN-ClientInfo-row-info col-5 col-xs-12 col-xxs-12">
					<div className="SOUKIASSIAN-ClientInfo-row-info-title">
						Votre code postal
					</div>
					<div className="SOUKIASSIAN-ClientInfo-row-info-data">
						{getClientData('zip')}
					</div>
				</div>
				<div className="SOUKIASSIAN-ClientInfo-row-info col-5 col-xs-12 col-xxs-12">
					<div className="SOUKIASSIAN-ClientInfo-row-info-title">
						Votre ville
					</div>
					<div className="SOUKIASSIAN-ClientInfo-row-info-data">
						{getClientData('city')}
					</div>
				</div>
			</div>
			<div className="SOUKIASSIAN-ClientInfo-row col-12">
				<div className="SOUKIASSIAN-ClientInfo-row-info col-5 col-xs-12 col-xxs-12">
					<div className="SOUKIASSIAN-ClientInfo-row-info-title">
						Votre pays
					</div>
					<div className="SOUKIASSIAN-ClientInfo-row-info-data">
						{getClientData('country')}
					</div>
				</div>
				<div className="SOUKIASSIAN-ClientInfo-row-info col-5 col-xs-12 col-xxs-12">
					<div className="SOUKIASSIAN-ClientInfo-row-info-title">
						Votre adresse
					</div>
					<div className="SOUKIASSIAN-ClientInfo-row-info-data">
						{getClientData('address')}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ClientInfo
