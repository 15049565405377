import ReactDOM from 'react-dom/client'

import reportWebVitals from './reportWebVitals'

import Index from './components/Router'

import './main.scss'

// REDUX
import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducers'
import { login as loginUser } from './actions/user.action'
import { loginClient } from './actions/client.action'

import { IS_DEV_ENVIRONMENT } from './utils/env'

const store = configureStore({
	reducer: rootReducer,
	devTools: IS_DEV_ENVIRONMENT,
})

await store.dispatch(loginUser())
await store.dispatch(loginClient())

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<Provider store={store}>
		<Index />
	</Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
