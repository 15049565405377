import { Link } from 'react-router-dom'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Collapse from '../../components/Collapse'

import './index.scss'

function LegalNotice() {
	const pageId = 30

	return (
		<div className="SOUKIASSIAN-LegalNotice">
			<Header type="finalPage" title="MENTIONS LÉGALES" />

			<div className="col-max-blocked center">
				<Collapse title="Informations légales liées au site web" open>
					<p>
						<b>Développeur du site web</b>
						<br />
						Le site internet https://laconsciencecommunicative.com
						est édité par STEPHANE PACHOT
						<br />
						Adresse : Isère, France
						<br />
						Numéro de SIRET : 880 497 904 00016
						<br />
						Code APE : 6201Z - Programmation informatique
						<br />
						Direction : Stéphane Pachot
						<br />
						<Link to="https://pachot-web.fr" target="_blank">
							pachot-web.fr
						</Link>
					</p>
					<p>
						<b>Le contact du développeur du site</b>
						<br />
						pachot.web@gmail.com
					</p>
					<p>
						<b>Création de la maquette</b>
						<br />
						Partenariat entre{' '}
						<Link
							to="https://www.linkedin.com/in/ambre-moerman-7912331b1"
							target="_blank"
						>
							Ambre Moerman
						</Link>{' '}
						et le fondateur de l'école L.C.C. ©
					</p>
					<p>
						<b>Hébergement du site</b>
						<br />
						IONOS SARL
						<br />
						431 303 775 RCS Sarreguemines
						<br />
						7, place de la Gare
						<br />
						BP 70109
						<br />
						57200 Sarreguemines Cedex
						<br />
					</p>
					<p>
						<br />
						<br />
						<b>Propriétaire du site internet</b>
						<br />
						La Conscience Communicative ©
						<br />
						Christophe Soukiassian, nommé PROPRIETAIRE dans ce
						présent contrat
						<br />
						109 impasse du verger 71220 Verosvres
						<br />
						<br />
						<b>Le contact de l'école L.C.C. ©</b>
						<br />
						ecole@laconsciencecommunicative.com
					</p>
				</Collapse>

				<Collapse title="Dispositions légales">
					<p>
						Les divers éléments du site web (la forme, la mise en
						page, le fonds, la structure ...) sont protégés par le
						droit des dessins et modèles, le droit d'auteur, le
						droit des marques ainsi que le droit à l'image et ils ne
						peuvent être copiés ou imités en tout ou partie sauf
						autorisation expresse de STEPHANE PACHOT et du
						PROPRIETAIRE. Toute personne ne respectant pas les
						dispositions légales applicables se rend coupable du
						délit de contrefaçon et est passible des sanctions
						pénales prévues par la loi.
					</p>
				</Collapse>
				<Collapse title="Droits d'auteurs">
					<p>
						Le présent site Internet constitue une œuvre dont
						STEPHANE PACHOT et le PROPRIETAIRE sont les auteurs au
						sens des articles L. 111.1 et suivants du Code de la
						propriété intellectuelle. Les photographies, textes,
						logos, pictogrammes, ainsi que toutes œuvres intégrées
						dans le site sont la propriété de STEPHANE PACHOT et du
						PROPRIETAIRE ou de tiers ayant autorisé STEPHANE PACHOT
						ou le PROPRIETAIRE à les utiliser. Les reproductions,
						les transmissions, les modifications, les
						réutilisations, sur un support papier ou informatique,
						du dit site Internet et des œuvres qui y sont
						reproduites ne sont autorisées que pour un usage
						personnel et privé conforme aux dispositions de
						l'article L 122-5 du Code de la Propriété
						Intellectuelle. Ces reproductions devront ainsi
						notamment indiquer clairement la source et l'auteur du
						site et/ou de ces œuvres multimédias. En aucun cas ces
						reproductions ne sauraient porter préjudice aux droits
						des tiers. Les reproductions, les transmissions, les
						modifications, les réutilisations à des fins
						publicitaires, commerciales ou d'information, de tout ou
						partie du site, sont totalement interdites.
					</p>
				</Collapse>
				<Collapse title="Droit des bases de données">
					<p>
						Les bases de données sont protégées par la loi du 1er
						juillet 1998 et le régime français du droit d'auteur.
					</p>
				</Collapse>
				<Collapse title="Établissements de liens vers le site">
					<p>
						STEPHANE PACHOT et le PROPRIETAIRE autorisent la mise en
						place d'un lien hypertexte vers leur site pour tous les
						sites Internet, à l'exclusion de ceux diffusant des
						informations à caractère polémique, violent,
						pornographique, xénophobe ou pouvant, dans une plus
						large mesure porter atteinte à la sensibilité du plus
						grand nombre. Le lien doit aboutir à la page d'accueil
						du site (home page) et le site doit apparaître dans une
						nouvelle fenêtre. Les pages du site ne doivent en aucun
						cas être intégrées à l'intérieur des pages d'un autre
						site (Frame). Dans tous les cas d'espèce, STEPHANE
						PACHOT et le PROPRIETAIRE se réservent le droit de
						demander la suppression d'un lien s'ils estiment que le
						site cible ne respecte pas les règles ainsi définies.
					</p>
				</Collapse>
				<Collapse title="Liens vers des sites tiers depuis le site">
					<p>
						Les liens hypertextes mis en œuvre au sein du site en
						direction d'autres sites et/ou de pages personnelles et
						d'une manière générale vers toutes ressources existantes
						sur Internet ne sauraient engager la responsabilité de
						STEPHANE PACHOT et le PROPRIETAIRE quant aux liens
						qu'ils contiennent ou aux changements ou mises à jour
						qui leurs sont apportés.
					</p>
				</Collapse>
				<Collapse title="Mise en garde générale">
					<p>
						Nos services mettent tout en œuvre pour offrir aux
						visiteurs de ce site web des informations fiables et
						vérifiées. Cependant, malgré tous les soins apportés, le
						site peut comporter des inexactitudes, des défauts de
						mise à jour ou des erreurs. Nous remercions les
						utilisateurs du site de nous faire part d'éventuelles
						omissions, erreurs ou corrections par mail.
					</p>
				</Collapse>
				<Collapse title="Utilisation de cookie">
					<p>
						<b>Le cookie obligatoire</b>
						<br />
						En continuant votre navigation sur ce site, vous
						acceptez l’utilisation d'un cookie fonctionnel, qui est
						dans le cas de ce site un cookie de session, celui-ci
						sert à créer un identifiant de navigation, ce qui permet
						d'éviter les abus d'utilisation du site et les spams.
						<br />
						Ce cookie est automatiquement supprimé à la fermeture du
						navigateur, sauf comportement contraire du navigateur
						utilisé.
						<br />
						<br />
						<b>La session identifiée par le cookie</b>
						<br />
						Cette session n'est pas persistante, toutes informations
						collectées pendant celle-ci ne sont pas enregistrées et
						sont automatiquement supprimées à expiration de la
						session.
						<br />
						<br />
						<b>La connexion au compte utilisateur</b>
						<br />
						La session sert également à permettre la connexion au
						compte utilisateur, ce compte est en lien avec la base
						de données et enregistre vos données fournies par les
						formulaires d'inscription, de connexion et ceux présents
						dans la gestion du compte.
						<br />
						Les informations collectées en tant qu'utilisateur
						connecté, ne sont utilisées que dans le cadre de l'école
						La Conscience Communicative et de ses moyens de
						communication.
					</p>
				</Collapse>
			</div>

			<Footer />
		</div>
	)
}

export default LegalNotice
