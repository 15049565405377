import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getClients } from '../../../actions/client.action'

import { isRightAccess } from '../../../utils'
import {
	RIGHTS_CLIENT_LIST,
	RIGHTS_MODIFY_OR_ADD_CLIENT,
} from '../../../utils/env'

import AddClientRow from './AddClientRow'
import ClientRow from './ClientRow'

import './index.scss'

function AdminClientTable() {
	const [clientFilter, setClientFilter] = useState({
		dataName: 'all',
		value: true,
	})
	const clientList = useSelector((state) => state.clientReducer.list)
	const user = useSelector((state) => state.userReducer)
	const dispatch = useDispatch()

	const clientFilterHandling = (client) => {
		if (clientFilter.dataName === 'all' && clientFilter.value === true)
			return true
		else {
			const search = clientFilter.value
				.normalize('NFD')
				.replace(/\p{Diacritic}/gu, '')
				.toLowerCase() //Enlève les accents

			const clientValue = client[clientFilter.dataName]
				.normalize('NFD')
				.replace(/\p{Diacritic}/gu, '')
				.toLowerCase() //Enlève les accents

			if (clientValue.includes(search)) return true
			else return false
		}
	}

	useEffect(() => {
		dispatch(getClients())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return (
		<>
			{isRightAccess(user, RIGHTS_CLIENT_LIST) && (
				<div className="SOUKIASSIAN-AdminClientTable-container">
					<table className="SOUKIASSIAN-AdminClientTable">
						<thead>
							<tr>
								<th>Action</th>
								<th>Id</th>
								<th>Type</th>
								<th>Prénom</th>
								<th>Nom</th>
								<th>Téléphone</th>
								<th>Email</th>
								<th>Adresse</th>
								<th>Note</th>
							</tr>
						</thead>

						<tbody>
							{isRightAccess(
								user,
								RIGHTS_MODIFY_OR_ADD_CLIENT,
							) && (
								<AddClientRow
									setClientFilter={setClientFilter}
								/>
							)}

							{clientList &&
								clientList
									.filter(clientFilterHandling)
									.map((client) => (
										<ClientRow
											key={client.id}
											id={client.id}
											type={client.type}
											firstName={client.firstName}
											secondName={client.secondName}
											phone={client.phone}
											email={client.email}
											address={client.address}
											zip={client.zip}
											city={client.city}
											country={client.country}
											note={client.note}
										/>
									))}
						</tbody>
					</table>
				</div>
			)}
		</>
	)
}

export default AdminClientTable
