import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getPage } from '../../actions/page.action'
import { getImages } from '../../actions/image.action'

import { BASE_URL_IMAGE } from '../../utils/env'
import { getDataFromImageList, getImageIdFromPageList } from '../../utils'

import PropTypes from 'prop-types'

import ImageInfo from '../ImageInfo'

import './index.scss'

function Img({ pageId, entityId, className, noInfoData, flipped, style }) {
	const pageList = useSelector((state) => state.pageReducer.list)
	const imageList = useSelector((state) => state.imageReducer.list)
	const dispatch = useDispatch()

	const imgData = getDataFromImageList(
		getImageIdFromPageList(pageList, pageId, entityId),
		imageList,
	)

	const isFlippedClass = flipped ? ' SOUKIASSIAN-ImgFlipped' : ''

	useEffect(() => {
		if (pageId != null) {
			dispatch(getPage(pageId))
		}
		dispatch(getImages())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			{imgData ? (
				<>
					<img
						className={`SOUKIASSIAN-Img${isFlippedClass} ${className}`}
						src={BASE_URL_IMAGE + imgData['url']}
						alt={imgData['role']}
						style={style}
					/>
					{!noInfoData && (
						<ImageInfo
							imgData={imgData}
							pageId={pageId}
							entityId={entityId}
						/>
					)}
				</>
			) : (
				<img className={`SOUKIASSIAN-Img ${className}`} src="" alt="" />
			)}
		</>
	)
}

Img.propTypes = {
	pageId: PropTypes.number.isRequired,
	entityId: PropTypes.number.isRequired,
	className: PropTypes.string.isRequired,
	noInfoData: PropTypes.bool.isRequired,
	flipped: PropTypes.bool.isRequired,
	style: PropTypes.object,
}

Img.defaultProps = {
	className: '',
	noInfoData: false,
	flipped: false,
}

export default Img
