import { Link, NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { IS_DEV_ENVIRONMENT } from '../../utils/env'

import { logoutClient } from '../../actions/client.action'

// import ImageInfo from '../ImageInfo'

import './index.scss'

function Footer() {
	const isConnected = useSelector((state) => state.clientReducer.isConnected)
	const dispatch = useDispatch()

	return (
		<footer className="SOUKIASSIAN-Footer">
			<div className="col-xxs-12 col-xs-8 col-s-6 col-x-4 col-4 SOUKIASSIAN-Footer-nav">
				<h4>La Conscience Communicative</h4>
				<ul>
					<li>
						<NavLink
							to="https://maps.app.goo.gl/rWjUQkunXF5GwRRk7"
							target="_blank"
						>
							109 impasse du verger, 71220 Verosvres
						</NavLink>
					</li>
					<li>
						<NavLink to="/Christophe-Soukiassian">
							Découvrir le fondateur de l'école L.C.C. ©
						</NavLink>
					</li>
					{IS_DEV_ENVIRONMENT && (
						<li>
							<NavLink
								to="/client-account"
								onClick={(e) => {
									if (!IS_DEV_ENVIRONMENT) {
										e.preventDefault()
										alert(
											"Page en cours d'actualisation septembre 2024",
										)
									}
								}}
							>
								{isConnected
									? 'Mon compte client L.C.C. ©'
									: 'Se connecter en tant que client'}
							</NavLink>
						</li>
					)}
					{isConnected && (
						<li>
							<NavLink
								onClick={(e) => {
									e.preventDefault()
									dispatch(logoutClient())
								}}
							>
								Se déconnecter
							</NavLink>
						</li>
					)}
				</ul>
			</div>

			<div className="col-xxs-12 col-xs-8 col-s-6 col-x-4 col-4">
				<h4>Nous suivre sur les réseaux</h4>
				<ul>
					<li className="SOUKIASSIAN-Footer-RS">
						<Link
							to="https://www.facebook.com/groups/lescommunicateursconscients"
							target="_blank"
							rel="noopener noreferrer"
						>
							<svg
								className="SOUKIASSIAN-Footer-RS-logoFB"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 512 512"
							>
								<path
									fill="white"
									d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z"
								/>
							</svg>
							{/* <ImageInfo
							imgData={{
								id: 0,
								role: 'Logo de FaceBook',
								author: 'Font Awesome Free 6.5.2 by @fontawesome',
								site: 'fontawesome.com',
								author_link: 'https://fontawesome.com',
								source_link:
									'https://fontawesome.com/icons/facebook?f=brands&s=solid',
								extension: 'svg',
								direction: 'Paysage',
								size: 'Inconnue',
								weight: 'Inconnue',
								change_made: 'Couleur mise en blanc.',
							}}
						/> */}
							Les communicateurs conscients
						</Link>
					</li>
					<li className="SOUKIASSIAN-Footer-RS">
						<Link
							to="https://www.facebook.com/laconsciencecommunicative"
							target="_blank"
							rel="noopener noreferrer"
						>
							<svg
								className="SOUKIASSIAN-Footer-RS-logoFB"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 512 512"
							>
								<path
									fill="white"
									d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z"
								/>
							</svg>
							{/* <ImageInfo
								imgData={{
									id: 0,
									role: 'Logo de FaceBook',
									author: 'Font Awesome Free 6.5.2 by @fontawesome',
									site: 'fontawesome.com',
									author_link: 'https://fontawesome.com',
									source_link:
										'https://fontawesome.com/icons/facebook?f=brands&s=solid',
									extension: 'svg',
									direction: 'Paysage',
									size: 'Inconnue',
									weight: 'Inconnue',
									change_made: 'Couleur mise en blanc.',
								}}
							/> */}
							Facebook de l'école L.C.C. ©
						</Link>
					</li>
					{/* <li className="SOUKIASSIAN-Footer-RS">
						<Link
							to="https://www.youtube.com/channel/UCptqNaDBofNdthizLfPJqwA"
							target="_blank"
							rel="noopener noreferrer"
						>
							<svg
								className="SOUKIASSIAN-Footer-RS-logoYT"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 576 512"
							>
								<path
									fill="white"
									d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z"
								/>
							</svg>
							Youtube
						</Link>
					</li> */}
				</ul>
			</div>

			<div className="col-xxs-12 col-xs-8 col-s-12 col-x-4 col-4 SOUKIASSIAN-Footer-nav">
				<h4>Contact et mentions</h4>
				<ul>
					<li>
						<NavLink to="/contact">Nous contacter</NavLink>
					</li>
					<li className="SOUKIASSIAN-Footer-noNav">
						ecole@laconsciencecommunicative.com
					</li>
					<li>
						<NavLink to="/legal-notice">Mentions légales</NavLink>
					</li>
					<li className="SOUKIASSIAN-Footer-noNav">
						© Copyright 2024 La Conscience Communicative.{' '}
						<span className="noBreak">Tous droits reservés</span>
						<br />
						<Link to="https://pachot-web.fr" target="_blank">
							Propulsé par Pachot-Web
						</Link>
					</li>
					<li className="SOUKIASSIAN-Footer-noNav">
						Mise à jour septembre 2024
					</li>
				</ul>
			</div>
		</footer>
	)
}

export default Footer
