import { combineReducers } from 'redux'
import pageReducer from './page.reducer'
import blogReducer from './blog.reducer'
import imageReducer from './image.reducer'
import userReducer from './user.reducer'
import clientReducer from './client.reducer'
import contactReducer from './contact.reducer'
import registrationReducer from './registration.reducer'

export default combineReducers({
	pageReducer,
	blogReducer,
	imageReducer,
	userReducer,
	clientReducer,
	contactReducer,
	registrationReducer,
})
