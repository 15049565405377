import { Link } from 'react-router-dom'

import './index.scss'

function Error404() {
	document.title = "LCC - 404 - La page n'existe pas !"

	return (
		<main className="SOUKIASSIAN-Error">
			<h1 className="SOUKIASSIAN-Error-title">404</h1>
			<p className="SOUKIASSIAN-Error-description">
				Oups! La page que vous demandez n'existe pas.
			</p>
			<Link to="/" className="SOUKIASSIAN-Error-link">
				Retourner sur la page d'accueil
			</Link>
		</main>
	)
}

export default Error404
