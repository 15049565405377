import { useEffect } from 'react'

import Header from '../../components/Header'
import BannerText from '../../components/BannerText'
import BannerThreePicturesLink from '../../components/BannerThreePicturesLink'
import Button from '../../components/Button'
import HarmoniousTitle from '../../components/HarmoniousTitle'
import Footer from '../../components/Footer'
import PictureTextLink from '../../components/PictureTextLink'
import TestimoniesHome from '../../components/TestimoniesHome'

import './index.scss'

function Home() {
	const buttonStageId = 'buttonHomeStage'
	const pageId = 2

	useEffect(() => {
		const buttonStageElement = document.getElementById(buttonStageId)

		if (typeof IntersectionObserver !== 'undefined') {
			const observer = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting) {
					buttonStageElement.classList.add(
						'SOUKIASSIAN-Home-ButtonCTA-stage-viewed',
					)
				} else {
					buttonStageElement.classList.remove(
						'SOUKIASSIAN-Home-ButtonCTA-stage-viewed',
					)
				}
			})

			observer.observe(buttonStageElement)

			return () => observer.disconnect()
		} else {
			buttonStageElement.classList.add(
				'SOUKIASSIAN-Home-ButtonCTA-stage-viewedIsNotAvailable',
			)
		}
	}, [])

	return (
		<div className="SOUKIASSIAN-Home">
			<Header
				type="home"
				pageId={pageId}
				entityId={1}
				title="C'est le moment en 2024 de trouver votre potentiel véritable."
				subTitle={
					<>
						Osez une vie authentique.
						<br />
						Transcendez vos difficultés.
						<br />
						Brillez en conscience.
					</>
				}
				buttonText="NOUS REJOINDRE LORS D'UN ÉVÉNEMENT"
			/>

			<BannerText
				className="SOUKIASSIAN-Home-headerSubTitle"
				title={
					<>
						<span> FRANCE</span>
						<span> Suisse</span>
						<span> CANADA</span>
						<span> Espagne</span>
						<span> AUSTRALIE</span>
					</>
				}
				textNoBg
			>
				Cette année concentrons-nous sur l'épanouissement de l'être
				humain,
				<br />
				dans la réalisation de ses projets personnels et professionnels.
				<br />
				Engageons-nous vers une vie harmonieuse. Aidons les autres à
				faire de même.
			</BannerText>

			<BannerThreePicturesLink
				picture1={{
					title: "L'ÉCOLE",
					pageId: pageId,
					entityId: 2,
					link: '/lcc-school',
					flipped: true,
				}}
				picture2={{
					title: 'LA MÉTHODE',
					pageId: pageId,
					entityId: 3,
					link: '/method',
				}}
				picture3={{
					title: 'CHRISTOPHE SOUKIASSIAN',
					pageId: pageId,
					entityId: 4,
					link: '/Christophe-Soukiassian',
				}}
			/>

			<BannerText>
				Découvrez-vous en explorant de nouvelles perspectives avec
				l'École L.C.C. © ; ses stages, formations et séances vous
				guideront pour progresser,
				<br />
				comblant ainsi l'écart entre votre situation actuelle et vos
				aspirations.
			</BannerText>

			<Button
				id={buttonStageId}
				className="SOUKIASSIAN-Home-ButtonCTA SOUKIASSIAN-Home-ButtonCTA-stage col-xxs-11 col-xs-11 col-6"
				link="/stages"
			>
				DÉCOUVREZ NOS STAGES
			</Button>

			<HarmoniousTitle
				className="SOUKIASSIAN-Home-HarmoniousTitle"
				title="LES FORMATIONS POUR TOUS"
				subTitle="HARMONISEZ-VOUS"
			>
				<BannerThreePicturesLink
					picture1={{
						title: 'COACHER SA VIE',
						pageId: pageId,
						entityId: 5,
						link: '/training-life-coaching',
					}}
					picture2={{
						title: 'DEVENIR COACH',
						pageId: pageId,
						entityId: 6,
						link: '/training-become-coach',
					}}
					picture3={{
						title: "COACHING D'ENTREPRISE",
						pageId: pageId,
						entityId: 7,
						link: '/training-business-coaching',
					}}
					bordered
				/>
			</HarmoniousTitle>

			<HarmoniousTitle
				className="SOUKIASSIAN-Home-HarmoniousTitle"
				title="LES SÉANCES POUR TOUS"
				subTitle="RÉALISEZ-VOUS"
			>
				<BannerThreePicturesLink
					picture1={{
						title: 'COACHER SA VIE',
						pageId: pageId,
						entityId: 8,
						link: '/session-life-coaching',
					}}
					picture2={{
						title: 'DEVENIR COACH',
						pageId: pageId,
						entityId: 9,
						link: '/session-become-coach',
					}}
					picture3={{
						title: "COACHING D'ENTREPRISE",
						pageId: pageId,
						entityId: 10,
						link: '/session-business-coaching',
						flipped: true,
					}}
					bordered
				/>
			</HarmoniousTitle>

			<HarmoniousTitle
				className="SOUKIASSIAN-Home-HarmoniousTitle SOUKIASSIAN-Home-HarmoniousTitle-trainerTrainings"
				title="LES FORMATIONS DE SUPERVISEUR ET FORMATEURS"
				subTitle="ACCOMPLISSEZ-VOUS"
			>
				<PictureTextLink
					className="SOUKIASSIAN-Home-PictureTextLink"
					title="DEVENEZ FORMATEUR"
					pageId={pageId}
					entityId={11}
					link="/trainer-trainings"
					bordered
					width="calc(100% + 26px)"
					animate
				/>
			</HarmoniousTitle>

			<TestimoniesHome
				pageId={pageId}
				entityId={12}
				entityIdList={[13, 14, 15]}
			/>

			<Footer />
		</div>
	)
}

export default Home
