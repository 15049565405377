import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'

import './index.scss'

function Success({ success, deleteSuccess, className }) {
	return (
		<div className={`SOUKIASSIAN-Success ${className}`}>
			{deleteSuccess && (
				<span
					className="SOUKIASSIAN-Success-delete"
					onClick={deleteSuccess}
				>
					<FontAwesomeIcon icon={faTrashCan} />
				</span>
			)}
			<FontAwesomeIcon
				icon={faCircleCheck}
				className="SOUKIASSIAN-Success-check"
			/>
			<br />
			{success}
		</div>
	)
}

Success.propTypes = {
	success: PropTypes.any.isRequired,
	deleteSuccess: PropTypes.func,
	className: PropTypes.string,
}

Success.defaultProps = {
	className: '',
}

export default Success
