import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import {
	getIdByNameRegistrationTypeList,
	getDataNameFromRegistrationType,
	filterRegistrationByType,
	registrationDataNameFormat,
} from '../../utils'

import {
	getRegistrations,
	getRegistrationsType,
} from '../../actions/registration.action'

import ImageTextContainer from '../ImageTextContainer'
import ImageText from '../ImageTextContainer/ImageText'
import Button from '../Button'
import StringToNode from '../StringToNode'

function RegistrationFinalPageShow({ pageId, category, subcategory }) {
	const [registrationTypeId, setRegistrationTypeId] = useState(0)
	const [registrationListTypeId, setRegistrationListTypeId] = useState([])

	const registrationList = useSelector(
		(state) => state.registrationReducer.list,
	)
	const registrationTypeList = useSelector(
		(state) => state.registrationReducer.typeList,
	)

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(getRegistrations())
		dispatch(getRegistrationsType())

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setRegistrationTypeId(
			getIdByNameRegistrationTypeList(
				category,
				subcategory,
				registrationTypeList,
			),
		)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [registrationTypeList])

	useEffect(() => {
		if (registrationTypeList && registrationList && registrationTypeId) {
			setRegistrationListTypeId(
				filterRegistrationByType(registrationTypeId, registrationList),
			)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [registrationTypeList, registrationList, registrationTypeId])

	return (
		<ImageTextContainer>
			{registrationListTypeId.map((registration, key) => (
				<ImageText
					key={`registration_${registration.id}`}
					pageId={pageId}
					entityId={key + 3}
					left={Boolean(key % 2)}
					text={
						<>
							{getDataNameFromRegistrationType(
								registrationTypeId,
								registrationTypeList,
								true,
							).map((data_name_block, key) => (
								<span
									key={`data_name_block_${registration.id}${key}`}
								>
									{data_name_block.map((data_name) => (
										<Fragment
											key={`data_name_${registration.id}${registrationDataNameFormat(data_name)}`}
										>
											<b>{data_name}</b> :{' '}
											<StringToNode
												string={
													JSON.parse(
														registration.data,
													)[
														registrationDataNameFormat(
															data_name,
														)
													]
												}
											/>
											<br />
										</Fragment>
									))}
								</span>
							))}
							<Button
								link={`/registration/event/webinars/${registration.id}`}
							>
								S'INSCRIRE
							</Button>
						</>
					}
				/>
			))}

			{registrationListTypeId.length === 0 && (
				<ImageText pageId={pageId} entityId={3} />
			)}
			{registrationListTypeId.length <= 1 && (
				<ImageText pageId={pageId} entityId={4} left />
			)}
			{registrationListTypeId.length <= 2 && (
				<ImageText pageId={pageId} entityId={5} />
			)}
		</ImageTextContainer>
	)
}

RegistrationFinalPageShow.propTypes = {
	pageId: PropTypes.number.isRequired,
	category: PropTypes.string.isRequired,
	subcategory: PropTypes.string.isRequired,
}

export default RegistrationFinalPageShow
