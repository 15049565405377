import { BASE_URL_IMAGE } from './env'

const setTitlePage = (title) => (document.title = 'LCC - ' + title)
const toTop = function () {
	window.scrollTo(0, 0)
}

export const initPage = (title = false) => {
	toTop()
	if (title) setTitlePage(title)
	return null
}

export const getUrlFromImageList = (id, imageList) => {
	if (imageList != null && imageList[id] != null) {
		return BASE_URL_IMAGE + imageList[id]['url']
	}

	return ''
}

export const getDataFromImageList = (id, imageList) => {
	if (imageList != null && imageList[id] != null) {
		return imageList[id]
	}

	return null
}

export const filterImageListToArray = (imageList, filterFunction) => {
	const filteredImageList = []

	for (const id in imageList) {
		if (filterFunction(imageList[id])) {
			filteredImageList.push(imageList[id])
		}
	}

	return filteredImageList
}

export const getImageIdFromPageList = (pageList, idPage, idEntity) => {
	if (
		pageList != null &&
		pageList[idPage] != null &&
		pageList[idPage]['images'][idEntity] != null
	) {
		return pageList[idPage]['images'][idEntity]
	}

	return null
}

export const getTextFromPageList = (pageList, idPage, idText) => {
	if (
		pageList != null &&
		pageList[idPage] != null &&
		pageList[idPage]['texts'][idText] != null
	) {
		return pageList[idPage]['texts'][idText]
	}

	return null
}

export const getAllImageIdFromPageList = (pageList) => {
	let listId = []

	if (pageList != null) {
		for (const key in pageList) {
			const page = pageList[key]

			for (const key in page['images']) {
				const imageId = parseInt(page['images'][key])
				listId.push(imageId)
			}
		}
	}

	return listId
}

export const getAllFolderFromPageList = (pageList) => {
	let listFolder = []

	if (pageList != null) {
		for (const key in pageList) {
			const page = pageList[key]

			listFolder.push(page)
		}
	}

	return listFolder
}

export const folderExist = (pageList, folderId) => {
	if (pageList != null) {
		if (Object.hasOwnProperty.call(pageList, folderId)) {
			return true
		}
	}

	return false
}

export const getAllImageIdFromFolder = (folder) => {
	let listId = []

	if (folder != null) {
		for (const key in folder['images']) {
			const imageId = parseInt(folder['images'][key])
			listId.push(imageId)
		}
	}

	return listId
}

export const getTextFromBlogList = (blogList, idPage, idText) => {
	if (
		blogList != null &&
		blogList[idPage] != null &&
		blogList[idPage]['texts'][idText] != null
	) {
		return blogList[idPage]['texts'][idText]
	}

	return null
}

export const isRightAccess = (user, rightAccessId) => {
	if (
		user?.rights.includes(rightAccessId.toString()) ||
		user?.rights.includes('1')
	) {
		return user?.isConnected
	}
}

export const inProgressCheck = (name, listName) => {
	return listName.includes(name)
}
export const inProgressAdd = (name, listName) => {
	if (!listName.includes(name)) {
		listName.push(name)
	}
}
export const inProgressRemove = (name, listName) => {
	const idName = listName.indexOf(name)

	if (idName !== -1) {
		listName.splice(idName, 1)
	}
}

export const registrationTypeListFormatting = (registrationTypeList = {}) => {
	const registrationTypeListFormatted = {}

	for (const key in registrationTypeList) {
		const category = registrationTypeList[key]
		const categoryName = category.category
		const categoryTitle = category.title_category

		if (!registrationTypeListFormatted[categoryName]) {
			registrationTypeListFormatted[categoryName] = {
				list: [],
				title_category: [],
			}
		}
		if (
			!registrationTypeListFormatted[
				categoryName
			].title_category.includes(categoryTitle)
		) {
			registrationTypeListFormatted[categoryName].title_category.push(
				categoryTitle,
			)
		}

		registrationTypeListFormatted[categoryName].list.push(category)
	}

	return registrationTypeListFormatted
}

export const filterRegistrationByType = (
	registrationTypeId,
	registrationList = {},
) => {
	return registrationList.filter(
		(registration) =>
			registration.id_registration_type === registrationTypeId,
	)
}

export const filterRegistrationById = (
	registrationId,
	registrationList = {},
) => {
	for (const key in registrationList) {
		const registration = registrationList[key]

		if (registration.id === parseInt(registrationId)) {
			return [registration]
		}
	}

	return false
}

export const getIdByNameRegistrationTypeList = (
	category_name,
	subcategory_name,
	registrationTypeList = {},
) => {
	for (const key in registrationTypeList) {
		const category = registrationTypeList[key]

		if (
			category.category === category_name &&
			category.subcategory === subcategory_name
		) {
			return category.id
		}
	}

	return 0
}

export const getByIdRegistrationTypeList = (
	registrationTypeId,
	registrationTypeList = {},
) => {
	for (const key in registrationTypeList) {
		const registrationType = registrationTypeList[key]

		if (registrationType.id === registrationTypeId) {
			return registrationType
		}
	}

	return false
}

export const getDataNameFromRegistrationType = (
	registrationTypeId,
	registrationTypeList = {},
	withBlock = false,
) => {
	for (const key in registrationTypeList) {
		const registrationType = registrationTypeList[key]

		if (registrationType.id === registrationTypeId) {
			if (withBlock) {
				return JSON.parse(registrationType.data_name_list)
			} else {
				const data_name_list = []

				JSON.parse(registrationType.data_name_list).forEach(
					(data_name_block) => {
						data_name_block.forEach((data_name) =>
							data_name_list.push(data_name),
						)
					},
				)

				return data_name_list
			}
		}
	}

	return false
}

export const getDataNameClientFromRegistrationType = (
	registrationTypeId,
	registrationTypeList = {},
) => {
	for (const key in registrationTypeList) {
		const registrationType = registrationTypeList[key]

		if (registrationType.id === registrationTypeId) {
			return JSON.parse(registrationType.data_name_client)
		}
	}

	return false
}

export const registrationDataNameFormat = (dataName = '') => {
	return dataName.replaceAll(' ', '_')
}

export const dateEnToFr = (date) => {
	const dateSplitted = date.split('-')

	return `${dateSplitted[2]}/${dateSplitted[1]}/${dateSplitted[0]}`
}
