import { useSelector } from 'react-redux'

import HarmoniousTitle from '../../components/HarmoniousTitle'
import PictureTextLink from '../../components/PictureTextLink'

import { IS_DEV_ENVIRONMENT } from '../../utils/env'

import './index.scss'

function RegistrationCategoryList() {
	const isConnectedUser = useSelector(
		(state) => state.userReducer.isConnected,
	)
	const homePageId = 2
	const eventPageId = 3
	const stagePageId = 4

	return (
		<div className="SOUKIASSIAN-RegistrationList-Category">
			<HarmoniousTitle
				className="col-xl-max-blocked"
				title="S'INSCRIRE À L'ÉVÉNEMENT"
				sticky
			>
				<div className="SOUKIASSIAN-RegistrationList-Category-pictureList">
					<PictureTextLink
						link="event/webinars"
						pageId={eventPageId}
						entityId={2}
						title="WEBINAIRE"
					/>
				</div>
			</HarmoniousTitle>

			<HarmoniousTitle
				className="col-xl-max-blocked"
				title="S'INSCRIRE AUX STAGES"
				sticky
			>
				<div className="SOUKIASSIAN-RegistrationList-Category-pictureList">
					<PictureTextLink
						className="col-xxs-12 col-xs-12 col-5"
						link="stage/consciousness-initiation"
						pageId={stagePageId}
						entityId={2}
						title="INITIATION CONSCIENCE"
					/>

					<PictureTextLink
						className="col-xxs-12 col-xs-12 col-5"
						link="stage/sagacity"
						pageId={stagePageId}
						entityId={3}
						title="SAGESSE"
					/>
					<PictureTextLink
						className="col-xxs-12 col-xs-12 col-5"
						link="stage/meditation"
						pageId={stagePageId}
						entityId={4}
						title="MÉDITATION"
						flipped
					/>
					<PictureTextLink
						className="col-xxs-12 col-xs-12 col-5"
						link="stage/eat-well"
						pageId={stagePageId}
						entityId={5}
						title="BIEN SE NOURRIR"
					/>
					<PictureTextLink
						className="col-xxs-12 col-xs-12 col-5"
						link="stage/foreign-trip"
						pageId={stagePageId}
						entityId={6}
						title="ÉLÉVATION CONSCIENCE"
					/>
				</div>
			</HarmoniousTitle>

			<HarmoniousTitle
				className="col-xl-max-blocked"
				title="S'INSCRIRE À LA FORMATION<br>COMMUNICATION CONSCIENTE L.C.C. ©"
			>
				<div className="SOUKIASSIAN-RegistrationList-Category-pictureList">
					<PictureTextLink
						link="training/training-life-coaching"
						pageId={homePageId}
						entityId={5}
						title="COACHER SA VIE"
					/>
				</div>
			</HarmoniousTitle>

			<HarmoniousTitle
				className="col-xl-max-blocked"
				title="S'INSCRIRE À LA FORMATION<br>COACH PLEINE CONSCIENCE L.C.C. ©"
			>
				<div className="SOUKIASSIAN-RegistrationList-Category-pictureList">
					<PictureTextLink
						link="training/training-become-coach"
						pageId={homePageId}
						entityId={6}
						title="DEVENIR COACH"
					/>
				</div>
			</HarmoniousTitle>
		</div>
	)
}

export default RegistrationCategoryList
