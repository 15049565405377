import axios from 'axios'

import { inProgressCheck, inProgressAdd, inProgressRemove } from '../utils'
import { BASE_URL_API } from '../utils/env'

const baseUrl = BASE_URL_API + 'image'

let listInProgress = []

export const GET_IMAGES = 'GET_IMAGES'
export const GET_IMAGES_BY_IDS = 'GET_IMAGES_BY_IDS'
export const ADD_IMAGE = 'ADD_IMAGE'
export const UPDATE_IMAGE = 'UPDATE_IMAGE'
export const DELETE_IMAGE = 'DELETE_IMAGE'

export const getImages = () => {
	if (!inProgressCheck(GET_IMAGES, listInProgress)) {
		inProgressAdd(GET_IMAGES, listInProgress)
		return async (dispatch) => {
			return axios
				.get(baseUrl + '/all', { withCredentials: true })
				.then((result) => {
					if (result.status === 200) {
						dispatch({
							type: GET_IMAGES,
							payload: result.data,
						})
					} else {
						inProgressRemove(GET_IMAGES, listInProgress)
					}
				})
				.catch((error) => {
					inProgressRemove(GET_IMAGES, listInProgress)
					errorHandling(error, 'récupérer les images', GET_IMAGES)
				})
		}
	} else {
		return async () => {}
	}
}

export const getImagesByIds = (idList) => {
	return async (dispatch) => {
		return axios
			.get(baseUrl + '/byIds/' + idList.join(','), {
				withCredentials: true,
			})
			.then((result) => {
				if (result.status === 200) {
					dispatch({
						type: GET_IMAGES_BY_IDS,
						payload: result.data,
					})
				}
			})
			.catch((error) => {
				errorHandling(error, 'récupérer les images', GET_IMAGES_BY_IDS)
			})
	}
}

export const addImage = (formData, reset = false) => {
	if (!inProgressCheck(ADD_IMAGE, listInProgress)) {
		inProgressAdd(ADD_IMAGE, listInProgress)
		return async (dispatch) => {
			return axios
				.post(baseUrl, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					withCredentials: true,
				})
				.then((result) => {
					if (result.status === 200) {
						if (reset) reset(true)
						dispatch({
							type: ADD_IMAGE,
							payload: result.data,
						})
					}
				})
				.catch((error) => {
					if (error.response.status === 400) {
						return error.response.data
					}
					errorHandling(error, 'ajouter une image', ADD_IMAGE)
				})
				.finally(() => {
					inProgressRemove(ADD_IMAGE, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

export const updateImage = (idImage, formData, doExit) => {
	if (!inProgressCheck(UPDATE_IMAGE, listInProgress)) {
		inProgressAdd(UPDATE_IMAGE, listInProgress)
		return async (dispatch) => {
			return axios
				.put(baseUrl + '/' + idImage, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					withCredentials: true,
				})
				.then((result) => {
					if (result.status === 200) {
						doExit()
						dispatch({
							type: UPDATE_IMAGE,
							payload: result.data,
						})
					}
				})
				.catch((error) => {
					if (error.response.status === 400) {
						return error.response.data
					}
					errorHandling(error, 'modifier une image', UPDATE_IMAGE)
				})
				.finally(() => {
					inProgressRemove(UPDATE_IMAGE, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

export const deleteImage = (idImage) => {
	if (!inProgressCheck(DELETE_IMAGE, listInProgress)) {
		inProgressAdd(DELETE_IMAGE, listInProgress)
		return async (dispatch) => {
			return axios
				.delete(baseUrl + '/' + idImage, {
					withCredentials: true,
				})
				.then((result) => {
					if (result.status === 200) {
						dispatch({
							type: DELETE_IMAGE,
							payload: idImage,
						})
					}
				})
				.catch((error) => {
					errorHandling(error, 'supprimer une image', DELETE_IMAGE)
				})
				.finally(() => {
					inProgressRemove(DELETE_IMAGE, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

const errorHandling = (error, title, actionType) => {
	let errorTitle = ''
	let errorMessage = ''

	if (error.response) {
		switch (error.response.status) {
			case 404:
				errorTitle = 'Erreur pour ' + title
				errorMessage = "La requête n'existe pas"
				break

			case 500:
				errorTitle = 'Erreur serveur pour ' + title
				errorMessage = error.response.data.message
				break

			default:
				errorTitle = 'Erreur pour ' + title
				errorMessage = 'Unknown error'
				break
		}
	} else if (error.request) {
		errorTitle = 'Erreur serveur pour ' + title
		errorMessage = 'Le serveur ne répond pas'
	} else {
		errorTitle = 'Erreur pour ' + title
		errorMessage = error.message
	}

	throw new Error(errorMessage, {
		cause: { type: actionType, title: errorTitle },
	})
}
