import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'

import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import Button from '../../../../components/Button'
import RegistrationFinalPageShow from '../../../../components/RegistrationList/FinalPageShow'

import './index.scss'

function Workshops() {
	const pageId = 18

	return (
		<div className="SOUKIASSIAN-Workshops">
			<Header
				type="finalPage"
				title="ATELIER"
				subTitle={
					<>
						<br />
						Animé par Christophe Soukiassian
						<br />
						<br />
						“Explorez votre potentiel véritable, traversez en
						conscience vos difficultés et apprenez à rayonner dans
						votre vie.”
					</>
				}
			/>

			<div className="col-max-blocked center margined-y">
				<Button link="/events">
					<FontAwesomeIcon icon={faReply} />
					{' REVENIR AUX ÉVÉNEMENTS'}
				</Button>
			</div>

			<RegistrationFinalPageShow
				pageId={pageId}
				category="event"
				subcategory="workshops"
			/>

			<BannerTestimonie
				pageId={pageId}
				entityId={1}
				name="Michael Bourgeon"
				commentary="Ouf, impressionnant de découvrir cet atelier et la méthode LCC à l'œuvre. J'ai beaucoup aimé voir et interpréter mes états internes en transformation positive."
			/>

			<BannerImageText
				pageId={pageId}
				entityId={2}
				text={
					<>
						<span>ATELIER</span>
						<br />
						Lyon et autres villes de France
					</>
				}
			></BannerImageText>

			<Footer />
		</div>
	)
}

export default Workshops
