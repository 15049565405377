import axios from 'axios'

import { inProgressCheck, inProgressAdd, inProgressRemove } from '../utils'
import { BASE_URL_API } from '../utils/env'

const baseUrl = BASE_URL_API + 'contact'

let listInProgress = []

export const SEND_MESSAGE = 'SEND_MESSAGE'

export const sendMessage = (formData, reset = false) => {
	if (!inProgressCheck(SEND_MESSAGE, listInProgress)) {
		inProgressAdd(SEND_MESSAGE, listInProgress)
		return async (dispatch) => {
			return axios
				.post(baseUrl + '/sendMessage', formData, {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
					withCredentials: true,
				})
				.then((result) => {
					if (result.status === 200) {
						if (reset) reset(true)
						dispatch({
							type: SEND_MESSAGE,
							payload: result.data,
						})
					}
				})
				.catch((error) => {
					if (error.response.status === 400) {
						return error.response.data
					}
					errorHandling(error, 'envoyer le message', SEND_MESSAGE)
				})
				.finally(() => {
					inProgressRemove(SEND_MESSAGE, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

const errorHandling = (error, title, actionType) => {
	let errorTitle = ''
	let errorMessage = ''

	if (error.response) {
		switch (error.response.status) {
			case 404:
				errorTitle = 'Erreur pour ' + title
				errorMessage = "La requête n'existe pas"
				break

			case 500:
				errorTitle = 'Erreur serveur pour ' + title
				errorMessage = error.response.data.message
				break

			default:
				errorTitle = 'Erreur pour ' + title
				errorMessage = 'Unknown error'
				break
		}
	} else if (error.request) {
		errorTitle = 'Erreur serveur pour ' + title
		errorMessage = 'Le serveur ne répond pas'
	} else {
		errorTitle = 'Erreur pour ' + title
		errorMessage = error.message
	}

	throw new Error(errorMessage, {
		cause: { type: actionType, title: errorTitle },
	})
}
