import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'

import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import Button from '../../../../components/Button'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import RegistrationFinalPageShow from '../../../../components/RegistrationList/FinalPageShow'

function Webinars() {
	const pageId = 15

	return (
		<div className="SOUKIASSIAN-Webinars">
			<Header
				type="finalPage"
				title="WEBINAIRE"
				subTitle={
					<>
						Animé par Christophe Soukiassian
						<br />
						<br />
						“Découvrez votre potentiel conscience”
					</>
				}
			/>

			<div className="col-max-blocked center margined-y">
				<Button link="/events">
					<FontAwesomeIcon icon={faReply} />
					{' REVENIR AUX ÉVÉNEMENTS'}
				</Button>
			</div>

			<RegistrationFinalPageShow
				pageId={pageId}
				category="event"
				subcategory="webinars"
			/>

			<BannerTestimonie
				pageId={pageId}
				entityId={1}
				name="Mia Sem"
				commentary="Un webinaire captivant qui m'éclaire dans mon épanouissement personnel, avec des outils pratiques pour embellir ma vie."
			/>

			<BannerImageText
				pageId={pageId}
				entityId={2}
				text={
					<>
						<span>WEBINAIRE</span>
						<br />
						Partout dans le monde
					</>
				}
			></BannerImageText>

			<Footer />
		</div>
	)
}

export default Webinars
