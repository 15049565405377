import axios from 'axios'

import { inProgressCheck, inProgressAdd, inProgressRemove } from '../utils'
import { BASE_URL_API } from '../utils/env'

const baseUrl = BASE_URL_API + 'registration'

let listInProgress = []

export const GET_REGISTRATIONS = 'GET_REGISTRATIONS'
export const GET_REGISTRATIONS_TYPE = 'GET_REGISTRATIONS_TYPE'
export const ADD_REGISTRATION = 'ADD_REGISTRATION'
export const UPDATE_REGISTRATION = 'UPDATE_REGISTRATION'
export const LINK_REGISTRATION_TO_CLIENT = 'LINK_REGISTRATION_TO_CLIENT'

const UPDATE_CLIENT = 'UPDATE_CLIENT'

export const getRegistrations = () => {
	if (!inProgressCheck(GET_REGISTRATIONS, listInProgress)) {
		inProgressAdd(GET_REGISTRATIONS, listInProgress)
		return async (dispatch) => {
			return axios
				.get(baseUrl + '/all', { withCredentials: true })
				.then((result) => {
					if (result.status === 200) {
						dispatch({
							type: GET_REGISTRATIONS,
							payload: result.data,
						})
					}
				})
				.catch((error) => {
					errorHandling(
						error,
						'récupérer les inscriptions',
						GET_REGISTRATIONS,
					)
				})
				.finally(() => {
					inProgressRemove(GET_REGISTRATIONS, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

export const getRegistrationsType = () => {
	if (!inProgressCheck(GET_REGISTRATIONS_TYPE, listInProgress)) {
		inProgressAdd(GET_REGISTRATIONS_TYPE, listInProgress)
		return async (dispatch) => {
			return axios
				.get(baseUrl + '_type/all', { withCredentials: true })
				.then((result) => {
					if (result.status === 200) {
						dispatch({
							type: GET_REGISTRATIONS_TYPE,
							payload: result.data,
						})
					}
				})
				.catch((error) => {
					errorHandling(
						error,
						"récupérer les catégories d'inscriptions",
						GET_REGISTRATIONS_TYPE,
					)
				})
				.finally(() => {
					inProgressRemove(GET_REGISTRATIONS_TYPE, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

export const addRegistration = (registration, setSuccess, setDoReset) => {
	if (!inProgressCheck(ADD_REGISTRATION, listInProgress)) {
		inProgressAdd(ADD_REGISTRATION, listInProgress)
		return async (dispatch) => {
			return axios
				.post(baseUrl, registration, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					withCredentials: true,
				})
				.then((result) => {
					if (result.status === 200) {
						setSuccess && setSuccess(true)
						setDoReset && setDoReset(true)
						dispatch({
							type: ADD_REGISTRATION,
							payload: result.data,
						})
					}
				})
				.catch((error) => {
					if (error.response.status === 400) {
						return error.response.data.errors
					}
					errorHandling(
						error,
						'ajouter une inscription',
						ADD_REGISTRATION,
					)
				})
				.finally(() => {
					inProgressRemove(ADD_REGISTRATION, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

export const updateRegistration = (registrationId, dataList, setIsEdit) => {
	if (!inProgressCheck(UPDATE_REGISTRATION, listInProgress)) {
		inProgressAdd(UPDATE_REGISTRATION, listInProgress)
		return async (dispatch) => {
			return axios
				.put(baseUrl + '/' + registrationId, JSON.stringify(dataList), {
					headers: {
						'Content-Type': 'application/json',
					},
					withCredentials: true,
				})
				.then((result) => {
					if (result.status === 200) {
						if (setIsEdit) {
							setIsEdit(0)
						}
						dispatch({
							type: UPDATE_REGISTRATION,
							payload: result.data,
						})
					}
				})
				.catch((error) => {
					if (error.response.status === 400) {
						return error.response.data.errors
					}
					errorHandling(
						error,
						"la mise à jour de l'inscription",
						UPDATE_REGISTRATION,
					)
				})
				.finally(() => {
					inProgressRemove(UPDATE_REGISTRATION, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

export const linkRegistrationToClient = (
	registrationId,
	clientId,
	setSuccess,
) => {
	if (!inProgressCheck(LINK_REGISTRATION_TO_CLIENT, listInProgress)) {
		inProgressAdd(LINK_REGISTRATION_TO_CLIENT, listInProgress)
		return async (dispatch) => {
			return axios
				.put(
					baseUrl + '/' + registrationId + '/link/' + clientId,
					null,
					{
						headers: {
							'Content-Type': 'application/json',
						},
						withCredentials: true,
					},
				)
				.then((result) => {
					if (result.status === 200) {
						if (setSuccess) {
							setSuccess(true)
						}
						dispatch({
							type: UPDATE_REGISTRATION,
							payload: result.data.registration,
						})
						dispatch({
							type: UPDATE_CLIENT,
							payload: result.data.client,
							isClient: true,
						})
					}
				})
				.catch((error) => {
					if (error.response.status === 400) {
						return error.response.data.errors
					}
					errorHandling(
						error,
						"la mise à jour de l'inscription",
						LINK_REGISTRATION_TO_CLIENT,
					)
				})
				.finally(() => {
					inProgressRemove(
						LINK_REGISTRATION_TO_CLIENT,
						listInProgress,
					)
				})
		}
	} else {
		return async () => {}
	}
}

const errorHandling = (error, title, actionType) => {
	let errorTitle = ''
	let errorMessage = ''

	if (error.response) {
		switch (error.response.status) {
			case 401:
				errorTitle = 'Erreur de connexion'
				errorMessage = error.response.data.message
				break

			case 404:
				errorTitle = 'Erreur pour ' + title
				errorMessage = "La requête n'existe pas"
				break

			case 500:
				errorTitle = 'Erreur serveur pour ' + title
				errorMessage = error.response.data.message
				break

			default:
				errorTitle = 'Erreur pour ' + title
				errorMessage = 'Erreur inconnue'
				break
		}
	} else if (error.request) {
		errorTitle = 'Erreur serveur pour ' + title
		errorMessage = 'Le serveur ne répond pas'
	} else {
		errorTitle = 'Erreur pour ' + title
		errorMessage = error.message
	}

	throw new Error(errorMessage, {
		cause: { type: actionType, title: errorTitle },
	})
}
