import { GET_BLOG, UPDATE_TEXT_FROM_BLOG } from '../actions/blog.action'

const initialState = { list: {} }

export default function imageReducer(state = initialState, action) {
	let blogId = null
	let blog = null

	switch (action.type) {
		case GET_BLOG:
			blog = action.payload
			blog.images = JSON.parse(blog.images)
			blog.texts = JSON.parse(blog.texts)
			blogId = blog.id
			return {
				...state,
				list: updateById(state.list, blogId, blog),
			}
		case UPDATE_TEXT_FROM_BLOG:
			blogId = action.payload.blogId
			blog = state.list[blogId]
			blog['texts'][action.payload.textId] = action.payload.text
			return {
				...state,
				list: updateById(state.list, blogId, blog),
			}
		default:
			return { ...state }
	}
}

function updateById(blogList, blogId, updatedBlog) {
	let newBlogList = { ...blogList }
	newBlogList[blogId] = updatedBlog
	return newBlogList
}
