import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getPage } from '../../actions/page.action'
import { getBlog } from '../../actions/blog.action'

import { getTextFromBlogList, getTextFromPageList } from '../../utils'

import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'

import QuillTextEditor from '../QuillTextEditor'
import StringToNode from '../StringToNode'

import './index.scss'

function TextEdit({ pageId, textId, isBlog }) {
	const pageList = useSelector((state) => state.pageReducer.list)
	const blogList = useSelector((state) => state.blogReducer.list)
	const [isEdit, setIsEdit] = useState(false)
	const dispatch = useDispatch()

	const textData = isBlog
		? getTextFromBlogList(blogList, pageId, textId)
		: getTextFromPageList(pageList, isBlog ? 31 : pageId, textId)

	if (textData === null && isBlog) {
		getTextFromPageList(pageList, pageId, textId)
	}

	useEffect(() => {
		if (pageId != null) {
			dispatch(getPage(pageId))
			dispatch(getBlog(pageId))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			{!isEdit && (
				<>
					<StringToNode string={textData} />
					<FontAwesomeIcon
						icon={faEdit}
						className="SOUKIASSIAN-TextEdit-icon-edit"
						onClick={() => {
							setIsEdit(true)
						}}
					/>
				</>
			)}
			{isEdit && (
				<>
					<QuillTextEditor
						text={textData}
						pageId={pageId}
						textId={textId}
						isBlog={isBlog}
						setIsEdit={setIsEdit}
					/>
				</>
			)}
		</>
	)
}

TextEdit.propTypes = {
	pageId: PropTypes.number,
	textId: PropTypes.number,
	isBlog: PropTypes.bool,
}

TextEdit.defaultProps = {
	isBlog: false,
}

export default TextEdit
