import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { addImage } from '../../../actions/image.action'

import Button from '../../Button'
import Input from '../../Input'

function AddImageRow() {
	const [doReset, setDoReset] = useState(false)
	const [errors, setErrors] = useState({})
	const dispatch = useDispatch()

	const addImageHandling = function (e) {
		e.preventDefault()
		const form = new FormData(e.target)

		dispatch(addImage(form, setDoReset)).then((errors) => {
			if (errors) {
				setErrors(errors)
			}
		})
	}

	return (
		<form
			action=""
			encType="multipart/form-data"
			onSubmit={addImageHandling}
			className="SOUKIASSIAN-AdminImageTable-AddImageRow"
		>
			<div className="SOUKIASSIAN-AdminImageTable-AddImageRow-image">
				<Input
					type="file"
					name="image"
					title="Image"
					errorDirect={errors.image ? errors.image : null}
					reset={[doReset]}
					required
					admin
				/>
			</div>
			<div className="SOUKIASSIAN-AdminImageTable-AddImageRow-data">
				<div>
					<span>Rôle de l'image :</span>
					<Input
						type="text"
						name="role"
						title="Role"
						errorDirect={errors.role ? errors.role : null}
						reset={[doReset]}
						admin
					/>
				</div>
				<div>
					<span>Auteur de l'image :</span>
					<Input
						type="text"
						name="author"
						title="Auteur"
						errorDirect={errors.author ? errors.author : null}
						reset={[doReset]}
						admin
					/>
				</div>
				<div>
					<span>Site de l'image :</span>
					<Input
						type="text"
						name="site"
						title="Site"
						errorDirect={errors.site ? errors.site : null}
						reset={[doReset]}
						admin
					/>
				</div>
				<div>
					<span>Lien de l'auteur :</span>
					<Input
						type="text"
						name="author_link"
						title="Lien auteur"
						errorDirect={
							errors.author_link ? errors.author_link : null
						}
						reset={[doReset]}
						admin
					/>
				</div>
				<div>
					<span>Lien de l'image originale :</span>
					<Input
						type="text"
						name="source_link"
						title="Lien source"
						errorDirect={
							errors.source_link ? errors.source_link : null
						}
						reset={[doReset]}
						admin
					/>
				</div>
				<div>
					<span>Modification effectuée :</span>
					<Input
						type="text"
						name="change_made"
						title="Modification"
						errorDirect={
							errors.change_made ? errors.change_made : null
						}
						reset={[doReset, setDoReset]}
						admin
					/>
				</div>
				<Button>Ajouter l'image</Button>
				<Button
					onClick={(e) => {
						e.preventDefault()
						setDoReset(true)
					}}
				>
					Vider les données
				</Button>
			</div>
		</form>
	)
}

export default AddImageRow
