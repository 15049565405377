import './index.scss'

function ImageTextFilletPowder({ children }) {
	return (
		<section className="SOUKIASSIAN-ImageTextFilletPowder col-max-blocked">
			{children}
		</section>
	)
}

export default ImageTextFilletPowder
