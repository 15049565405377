import { useSelector } from 'react-redux'

import { isRightAccess } from '../../../utils'
import { RIGHTS_CLIENT_LIST } from '../../../utils/env'

import Login from '../../../components/Login'
import Header from '../../../components/Header'
import AdminClientTable from '../../../components/admin/ClientTable'

import Error403 from '../../Error/403'

function AdminClientManagement() {
	const user = useSelector((state) => state.userReducer)

	return (
		<>
			{user.isConnected ? (
				<>
					{isRightAccess(user, RIGHTS_CLIENT_LIST) ? (
						<div className="SOUKIASSIAN-AdminImageHandler">
							<Header type="admin" />
							<AdminClientTable />
						</div>
					) : (
						<div className="SOUKIASSIAN-AdminImageHandler">
							<Header type="admin" />
							<Error403 redirectLink="/admin/home" />
						</div>
					)}
				</>
			) : (
				<Login />
			)}
		</>
	)
}

export default AdminClientManagement
