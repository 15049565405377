import { useEffect, useRef } from 'react'

import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown } from '@fortawesome/free-solid-svg-icons'

import './index.scss'

function Collapse({ title, children, open }) {
	const collapseElement = useRef()
	const collapseContentElement = useRef()

	const toggleIsOpenedCollapse = (e) => {
		const titleCollapse = e.currentTarget

		titleCollapse.classList.toggle('SOUKIASSIAN-Collapse-title-opened')
		refreshCollapse()
	}

	const refreshCollapse = () => {
		const collapse = collapseElement.current
		const titleCollapse = collapse.querySelector(
			'.SOUKIASSIAN-Collapse-title',
		)

		if (
			titleCollapse.classList.contains(
				'SOUKIASSIAN-Collapse-title-opened',
			)
		) {
			collapse.style.height = `${collapse.scrollHeight}px`
		} else {
			collapse.style.height = `${titleCollapse.scrollHeight}px`
		}
	}

	useEffect(() => {
		if (open) {
			const collapse = collapseElement.current
			const titleCollapse = collapse.querySelector(
				'.SOUKIASSIAN-Collapse-title',
			)
			titleCollapse.classList.add('SOUKIASSIAN-Collapse-title-opened')
		}
		refreshCollapse()

		if (!collapseContentElement.current) return

		const resizeObserver = new ResizeObserver(() => {
			refreshCollapse()
		})
		resizeObserver.observe(collapseContentElement.current)
		return () => resizeObserver.disconnect()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<section className="SOUKIASSIAN-Collapse" ref={collapseElement}>
				<h2
					className="SOUKIASSIAN-Collapse-title"
					onClick={toggleIsOpenedCollapse}
				>
					{`${title} `}
					<FontAwesomeIcon
						className="SOUKIASSIAN-Collapse-title-icon"
						icon={faSortDown}
					/>
				</h2>
				<div
					className="SOUKIASSIAN-Collapse-content"
					ref={collapseContentElement}
				>
					{children || 'Actuellement en évolution'}
				</div>
			</section>
			<hr />
		</>
	)
}

Collapse.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.any,
	open: PropTypes.bool.isRequired,
}

Collapse.defaultProps = {
	open: false,
}

export default Collapse
