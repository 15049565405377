import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { logout } from '../../actions/user.action'

import { isRightAccess } from '../../utils'
import {
	RIGHTS_BLOG_LIST,
	RIGHTS_CLIENT_LIST,
	RIGHTS_IMAGE_LIST,
	RIGHTS_REGISTRATION_LIST,
} from '../../utils/env'

import Img from '../Img'
import Button from '../Button'

function HeaderMenuAdmin() {
	const user = useSelector((state) => state.userReducer)
	const dispatch = useDispatch()

	return (
		<div className="SOUKIASSIAN-Header-menuContainer">
			<div className="SOUKIASSIAN-Header-menuContainer-logoTitle col-4">
				<Img
					pageId={1}
					entityId={1}
					className="SOUKIASSIAN-Header-menuContainer-logoLCC col-3"
				/>
				<p className="SOUKIASSIAN-Header-menuContainer-companyName col-9">
					ADMINISTRATION
				</p>
			</div>
			<nav className="col-8">
				<li>
					<NavLink to="/admin/home">Accueil</NavLink>
				</li>
				{isRightAccess(user, RIGHTS_CLIENT_LIST) && (
					<li>
						<NavLink to="/admin/client-management">
							Gestion client
						</NavLink>
					</li>
				)}
				{isRightAccess(user, RIGHTS_IMAGE_LIST) && (
					<li>
						<NavLink to="/admin/image-handler">
							Gestion image
						</NavLink>
					</li>
				)}
				{isRightAccess(user, RIGHTS_REGISTRATION_LIST) && (
					<li>
						<NavLink to="/admin/registration-handler">
							Gestion inscription
						</NavLink>
					</li>
				)}
				{isRightAccess(user, RIGHTS_BLOG_LIST) && (
					<li>
						<NavLink to="/admin/blog-handler">
							Gestion actualité
						</NavLink>
					</li>
				)}
				<li>
					<NavLink to="/" target="_blank">
						Voir le site
					</NavLink>
				</li>
				<li>
					<Button onClick={() => dispatch(logout())}>
						Déconnexion
					</Button>
				</li>
			</nav>
		</div>
	)
}

export default HeaderMenuAdmin
