import PropTypes from 'prop-types'

import PictureTextLink from '../PictureTextLink'

import './index.scss'

function BannerThreePicturesLink({ picture1, picture2, picture3, bordered }) {
	const imgBordered = bordered
		? ' SOUKIASSIAN-BannerThreePicturesLink-bordered'
		: ''

	return (
		<div className={`SOUKIASSIAN-BannerThreePicturesLink${imgBordered}`}>
			<PictureTextLink
				link={picture1['link']}
				title={picture1['title']}
				pageId={picture1['pageId']}
				entityId={picture1['entityId']}
				flipped={picture1['flipped']}
			/>
			<PictureTextLink
				link={picture2['link']}
				title={picture2['title']}
				pageId={picture2['pageId']}
				entityId={picture2['entityId']}
				flipped={picture2['flipped']}
			/>
			<PictureTextLink
				link={picture3['link']}
				title={picture3['title']}
				pageId={picture3['pageId']}
				entityId={picture3['entityId']}
				flipped={picture3['flipped']}
			/>
		</div>
	)
}

BannerThreePicturesLink.propTypes = {
	picture1: PropTypes.object.isRequired,
	picture2: PropTypes.object.isRequired,
	picture3: PropTypes.object.isRequired,
	bordered: PropTypes.bool.isRequired,
}

BannerThreePicturesLink.defaultProps = {
	bordered: false,
}

export default BannerThreePicturesLink
