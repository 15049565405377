import { useSelector } from 'react-redux'

import { isRightAccess } from '../../../utils'
import { RIGHTS_BLOG_LIST, RIGHTS_MODIFY_OR_ADD_BLOG } from '../../../utils/env'

import PropTypes from 'prop-types'

import Login from '../../../components/Login'
import Header from '../../../components/Header'
import BannerText from '../../../components/BannerText'
import TextEdit from '../../../components/TextEdit'

import Error403 from '../../Error/403'

import '../../Blog/index.scss'

function AdminBlogHandler({ modifyId }) {
	const user = useSelector((state) => state.userReducer)

	return (
		<>
			{user.isConnected ? (
				<div className="SOUKIASSIAN-Blog">
					<Header type="admin" />
					{modifyId === 0 && isRightAccess(user, RIGHTS_BLOG_LIST) ? (
						<></>
					) : (
						<>
							{modifyId === 0 && (
								<Error403 redirectLink="/admin/home" />
							)}
						</>
					)}
					{modifyId !== 0 &&
						isRightAccess(user, RIGHTS_MODIFY_OR_ADD_BLOG) && (
							<>
								<Header
									type="blog"
									title={<TextEdit pageId={31} textId={1} />}
									subTitle={
										<TextEdit pageId={31} textId={2} />
									}
									description={
										<TextEdit pageId={31} textId={3} />
									}
									pageId={2}
									entityId={5}
								/>
								<BannerText
									className="SOUKIASSIAN-Blog-BannerText"
									title={<TextEdit pageId={31} textId={4} />}
								/>
								<section className="SOUKIASSIAN-Blog-section col-max-blocked center">
									<TextEdit pageId={31} textId={5} />
								</section>
							</>
						)}
				</div>
			) : (
				<Login />
			)}
		</>
	)
}

AdminBlogHandler.propTypes = {
	modifyId: PropTypes.number,
}

AdminBlogHandler.defaultProps = {
	modifyId: 0,
}

export default AdminBlogHandler
