import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import { initPage } from '../../utils'

import {
	pathIntermediatePages,
	pathEventPages,
	pathStagePages,
	pathTrainerTrainingPages,
	pathPresentationPages,
	pathTrainingPages,
	pathSessionPages,
} from './mainPages'

import { pathAdminPages } from './adminPages'

import { pathUtilsPages } from './utilsPages'

import Error404 from '../../pages/Error/404'

import Home from '../../pages/Home'

function Index() {
	const router = createBrowserRouter([
		{
			path: '/',
			element: <Home />,
			loader: () => initPage('Accueil'),
		},

		...pathUtilsPages,

		...pathIntermediatePages,
		...pathEventPages,
		...pathStagePages,
		...pathTrainerTrainingPages,
		...pathPresentationPages,
		...pathTrainingPages,
		...pathSessionPages,

		...pathAdminPages,

		// Path : Error
		{
			path: '*',
			element: <Error404 />,
			loader: () => initPage(),
		},
		// Path END : Error
	])

	return <RouterProvider router={router} />
}

export default Index
