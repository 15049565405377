import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getPage } from '../../actions/page.action'
import { getImages } from '../../actions/image.action'

import { getUrlFromImageList, getImageIdFromPageList } from '../../utils'

import PropTypes from 'prop-types'

import ImageInfo from '../ImageInfo'

import './index.scss'

function BannerImageText({ pageId, entityId, text, flipped }) {
	const pageList = useSelector((state) => state.pageReducer.list)
	const imageList = useSelector((state) => state.imageReducer.list)
	const dispatch = useDispatch()

	const imgBackgroundId = getImageIdFromPageList(pageList, pageId, entityId)

	const classNoText = text ? '' : ' SOUKIASSIAN-BannerImageTextNoText'
	const isFlippedClass = flipped ? ' SOUKIASSIAN-BannerImageTextFlipped' : ''

	useEffect(() => {
		if (pageId != null) {
			dispatch(getPage(pageId))
		}
		dispatch(getImages())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div
			className={`SOUKIASSIAN-BannerImageText${classNoText}${isFlippedClass}`}
			style={{
				backgroundImage: `url(${getUrlFromImageList(imgBackgroundId, imageList)})`,
			}}
		>
			<ImageInfo imgData="GET" pageId={pageId} entityId={entityId} />
			<p className="col-max-blocked">{text}</p>
		</div>
	)
}

BannerImageText.propTypes = {
	pageId: PropTypes.number.isRequired,
	entityId: PropTypes.number.isRequired,
	text: PropTypes.any,
	flipped: PropTypes.bool.isRequired,
}

BannerImageText.defaultProps = {
	flipped: false,
}

export default BannerImageText
