import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getPage } from '../../actions/page.action'
import { getImages } from '../../actions/image.action'

import { getUrlFromImageList, getImageIdFromPageList } from '../../utils'

import PropTypes from 'prop-types'

import HarmoniousTitle from '../HarmoniousTitle'
import Button from '../Button'
import Img from '../Img'
import ImageInfo from '../ImageInfo'

import './index.scss'

function TestimoniesHome({ pageId, entityId, entityIdList }) {
	const pageList = useSelector((state) => state.pageReducer.list)
	const imageList = useSelector((state) => state.imageReducer.list)
	const dispatch = useDispatch()

	const imgBackgroundId = getImageIdFromPageList(pageList, pageId, entityId)

	const handlingScaleTestimonie = (e) => {
		const testimonieList = e.currentTarget.parentElement.querySelectorAll(
			'.SOUKIASSIAN-TestimoniesHome-testimonie',
		)

		testimonieList.forEach((testimonie) => {
			testimonie.classList.remove('scaleUp')
		})

		e.currentTarget.classList.add('scaleUp')
	}

	useEffect(() => {
		if (pageId != null) {
			dispatch(getPage(pageId))
		}
		dispatch(getImages())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div
			className="SOUKIASSIAN-TestimoniesHome"
			style={{
				backgroundImage: `url(${getUrlFromImageList(imgBackgroundId, imageList)})`,
			}}
		>
			<HarmoniousTitle
				className="SOUKIASSIAN-TestimoniesHome-HarmoniousTitle"
				title="LES TÉMOIGNAGES"
				subTitle="FORMATIONS ET SÉANCES"
			>
				<ImageInfo imgData="GET" pageId={pageId} entityId={entityId} />
				<div className="SOUKIASSIAN-TestimoniesHome-testimonie-container">
					<div
						className="SOUKIASSIAN-TestimoniesHome-testimonie"
						onClick={handlingScaleTestimonie}
						onMouseEnter={handlingScaleTestimonie}
					>
						<div className="SOUKIASSIAN-TestimoniesHome-testimonie-imgTitle">
							<div className="SOUKIASSIAN-TestimoniesHome-testimonie-imgTitle-imgContainer">
								<Img
									pageId={pageId}
									entityId={entityIdList[0]}
									flipped
								/>
							</div>
							<h3>Chantal Lagneau</h3>
						</div>

						<p>
							Avec la communication consciente, j'ai découvert et
							affirmé ma véritable identité.
							<br />
							<br />
							<b>Je gère désormais ma vie</b> en pleine
							conscience, harmonisant chaque jour mes émotions en
							gérant mes pensées, paroles et actes. Je vis
							désormais une relation épanouissante avec moi-même,
							en corrélation avec les autres et le monde.
						</p>
					</div>

					<div
						className="SOUKIASSIAN-TestimoniesHome-testimonie scaleUp"
						onClick={handlingScaleTestimonie}
						onMouseEnter={handlingScaleTestimonie}
					>
						<div className="SOUKIASSIAN-TestimoniesHome-testimonie-imgTitle">
							<div className="SOUKIASSIAN-TestimoniesHome-testimonie-imgTitle-imgContainer">
								<Img
									pageId={pageId}
									entityId={entityIdList[1]}
								/>
							</div>
							<h3>Lisa Soukiassian</h3>
						</div>

						<p>
							Je suis <b>certifié coach</b> par l'école L.C.C. ©
							Je m'épanouis dans cette profession.
							<br />
							<br />
							Mes compétences acquises me permettent d'accompagner
							dans l'authenticité les réalisations de vie. Je
							maîtrise mon destin guidant naturellement les autres
							vers leurs réussites dans tous les domaines.
						</p>
					</div>

					<div
						className="SOUKIASSIAN-TestimoniesHome-testimonie"
						onClick={handlingScaleTestimonie}
						onMouseEnter={handlingScaleTestimonie}
					>
						<div className="SOUKIASSIAN-TestimoniesHome-testimonie-imgTitle">
							<div className="SOUKIASSIAN-TestimoniesHome-testimonie-imgTitle-imgContainer">
								<Img
									pageId={pageId}
									entityId={entityIdList[2]}
								/>
							</div>
							<h3>Christophe Champion</h3>
						</div>

						<p>
							Grâce aux outils de communication de Christophe
							Soukiassian, je gère le stress professionnel avec
							succès, atteignant mes objectifs plus facilement.
							<br />
							<br />
							Son coaching aide à surmonter les obstacles,
							contribuant ainsi à la{' '}
							<b>croissance des entreprises</b> et au
							développement des professionnels.
						</p>
					</div>
				</div>
			</HarmoniousTitle>

			<Button
				className="SOUKIASSIAN-TestimoniesHome-ButtonCTA SOUKIASSIAN-TestimoniesHome-ButtonCTA-contact col-xxs-11 col-xs-11 col-6"
				link="/contact"
			>
				PRENDRE CONTACT AVEC L'ÉCOLE
			</Button>
		</div>
	)
}

TestimoniesHome.propTypes = {
	pageId: PropTypes.number.isRequired,
	entityId: PropTypes.number.isRequired,
}

export default TestimoniesHome
