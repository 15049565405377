import {
	GET_PAGE,
	UPDATE_IMAGE_FROM_PAGE,
	UPDATE_TEXT_FROM_PAGE,
} from '../actions/page.action'

const initialState = { list: {} }

export default function imageReducer(state = initialState, action) {
	let pageId = null
	let page = null
	let imageNew = null

	switch (action.type) {
		case GET_PAGE:
			page = action.payload
			page.images = JSON.parse(page.images)
			page.texts = JSON.parse(page.texts)
			pageId = page.id
			return {
				...state,
				list: updateById(state.list, pageId, page),
			}
		case UPDATE_IMAGE_FROM_PAGE:
			pageId = action.payload.pageId
			page = { ...state.list[pageId] }
			imageNew = {}
			imageNew[action.payload.entityId] = action.payload.imageId
			page['images'] = { ...page['images'], ...imageNew }
			return {
				...state,
				list: updateById(state.list, pageId, page),
			}
		case UPDATE_TEXT_FROM_PAGE:
			pageId = action.payload.pageId
			page = state.list[pageId]
			page['texts'][action.payload.textId] = action.payload.text
			return {
				...state,
				list: updateById(state.list, pageId, page),
			}
		default:
			return { ...state }
	}
}

function updateById(pageList, pageId, updatedPage) {
	let newPageList = { ...pageList }
	newPageList[pageId] = updatedPage
	return newPageList
}
