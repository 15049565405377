import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'

import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import Button from '../../../../components/Button'
import RegistrationFinalPageShow from '../../../../components/RegistrationList/FinalPageShow'

function Conferences() {
	const pageId = 17

	return (
		<div className="SOUKIASSIAN-Conferences">
			<Header
				type="finalPage"
				title="CONFÉRENCE"
				subTitle={
					<>
						<br />
						Animée par Christophe Soukiassian
						<br />
						<br />
						“Trouvez votre potentiel véritable. Osez une vie
						authentique. Transcendez vos difficultés. Brillez en
						conscience.”
					</>
				}
			/>

			<div className="col-max-blocked center margined-y">
				<Button link="/events">
					<FontAwesomeIcon icon={faReply} />
					{' REVENIR AUX ÉVÉNEMENTS'}
				</Button>
			</div>

			<RegistrationFinalPageShow
				pageId={pageId}
				category="event"
				subcategory="conferences"
			/>

			<BannerTestimonie
				pageId={pageId}
				entityId={1}
				name="Marie Rivière"
				commentary={
					<>
						C'est si enrichissant et revivifiant cette conférence,
						qu'après j'ai eu plaisir de parler de mes projets avec
						Christophe Soukiassian.
						<br />
						<br />
						Son humanisme est très appréciable.
					</>
				}
			/>

			<BannerImageText
				pageId={pageId}
				entityId={2}
				text={
					<>
						<span>CONFÉRENCE</span>
						<br />
						Lyon et autres villes de France
					</>
				}
			></BannerImageText>

			<Footer />
		</div>
	)
}

export default Conferences
