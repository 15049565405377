import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'

import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import Button from '../../../../components/Button'
import RegistrationFinalPageShow from '../../../../components/RegistrationList/FinalPageShow'

function Tradeshows() {
	const pageId = 16

	return (
		<div className="SOUKIASSIAN-Tradeshows">
			<Header
				type="finalPage"
				title="SALON"
				subTitle="Animé par Christophe Soukiassian"
			/>

			<div className="col-max-blocked center margined-y">
				<Button link="/events">
					<FontAwesomeIcon icon={faReply} />
					{' REVENIR AUX ÉVÉNEMENTS'}
				</Button>
			</div>

			<RegistrationFinalPageShow
				pageId={pageId}
				category="event"
				subcategory="tradeshows"
			/>

			<BannerTestimonie
				pageId={pageId}
				entityId={1}
				name="Gabriel Fontaine"
				commentary={
					<>
						Ce salon est accueillant. Au stand de l'école LCC, j'ai
						été encouragé et inspiré.
						<br />
						Je ressens que mes rêves peuvent prendre vie avec le
						soutien et les accompagnements de Christophe
						Soukiassian.
					</>
				}
			/>

			<BannerImageText
				pageId={pageId}
				entityId={2}
				text={
					<>
						<span>SALON</span>
						<br />
						Lyon et autres villes de France
					</>
				}
			></BannerImageText>

			<Footer />
		</div>
	)
}

export default Tradeshows
