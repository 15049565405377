import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import {
	LINK_REGISTRATION_TO_CLIENT,
	linkRegistrationToClient,
} from '../../actions/registration.action'

import Button from '../../components/Button'
import Success from '../../components/Success'
import ClientInfo from '../ClientInfo'
import Info from '../Info'
import Error from '../Error'

function RegistrationForm({ registrationId, clientId, dataNameClientList }) {
	const [registrationSending, setRegistrationSending] = useState(false)
	const [errors, setErrors] = useState({})
	const [success, setSuccess] = useState(false)
	const dispatch = useDispatch()

	const errorHandling = (error, errorList) => {
		let errorContainer = { ...errorList, message: error.message }

		if (error.cause?.type === LINK_REGISTRATION_TO_CLIENT) {
			errorContainer.title = error.cause.title
		} else {
			errorContainer.title = 'Erreur des inscriptions'
		}
		setErrors(errorContainer)
	}

	const linkRegistrationToClientHandling = function (e) {
		e.preventDefault()

		if (!registrationSending) {
			setRegistrationSending(true)
			let errorList = {}

			dispatch(
				linkRegistrationToClient(registrationId, clientId, setSuccess),
			)
				.then((error) => {
					setErrors({})
					if (error) {
						errorList = error
						setErrors(error)
					}
				})
				.catch((e) => {
					errorHandling(e, errorList)
				})
				.finally(() => {
					setRegistrationSending(false)
				})
		}
	}

	return (
		<form
			className="SOUKIASSIAN-RegistrationList-Form"
			onSubmit={linkRegistrationToClientHandling}
		>
			<ClientInfo requiredList={dataNameClientList} />
			<Info
				className="col-11 center"
				info={
					<>
						Merci de bien vouloir vérifier que vos informations
						soient corrects pour l'inscription
						<br />
						<br />
						Vous pouvez modifier vos informations dans :{' '}
						<Link to="/client-account">Mon compte client</Link>
					</>
				}
			/>
			{errors.id_registration_type && (
				<Error
					className="col-11 center"
					error={errors.id_registration_type}
					deleteError={() => {
						setErrors({})
					}}
				/>
			)}
			{errors.id_client && (
				<Error
					className="col-11 center"
					error={errors.id_client}
					deleteError={() => {
						setErrors({})
					}}
				/>
			)}
			{success ? (
				<Success
					className="col-11 center"
					success="L'inscription a été confirmée"
				/>
			) : (
				(errors.message || errors.title) && (
					<Error
						className="col-11 center"
						error={`${errors.title} : ${errors.message}`}
						deleteError={() => {
							setErrors({})
						}}
					/>
				)
			)}
			<Button className="SOUKIASSIAN-RegistrationList-Form-buttonSubscribe col-xxs-11 col-xs-11 col-4">
				VALIDER L'INSCRIPTION
			</Button>
		</form>
	)
}

RegistrationForm.propTypes = {
	registrationId: PropTypes.number.isRequired,
	clientId: PropTypes.number.isRequired,
	dataNameClientList: PropTypes.array.isRequired,
}

export default RegistrationForm
