import reactStringReplace from 'react-string-replace'

import PropTypes from 'prop-types'

function StringToNode({ string }) {
	// To <br />
	var node = reactStringReplace(string, '<br>', (match, i) => (
		<br key={`br_${i}`} />
	))
	node = reactStringReplace(node, '\r\n', (match, i) => (
		<br key={`br_${i}`} />
	))
	node = reactStringReplace(node, '\r', (match, i) => <br key={`br_${i}`} />)
	node = reactStringReplace(node, '\n', (match, i) => <br key={`br_${i}`} />)

	return node
}

StringToNode.propTypes = {
	string: PropTypes.string,
}

StringToNode.defaultProps = {
	string: '',
}

export default StringToNode
