import axios from 'axios'

import { inProgressCheck, inProgressAdd, inProgressRemove } from '../utils'
import { BASE_URL_API } from '../utils/env'

const baseUrl = BASE_URL_API + 'blog'

let listInProgress = []

export const GET_BLOG = 'GET_BLOG'
export const UPDATE_TEXT_FROM_BLOG = 'UPDATE_TEXT_FROM_BLOG'

export const getBlog = (id) => {
	const inProgessName = GET_BLOG + id
	if (!inProgressCheck(inProgessName, listInProgress)) {
		inProgressAdd(inProgessName, listInProgress)
		return async (dispatch) => {
			return axios
				.get(`${baseUrl}/${id}`, {
					withCredentials: true,
				})
				.then((result) => {
					if (result.status === 200) {
						dispatch({
							type: GET_BLOG,
							payload: result.data,
						})
					} else {
						inProgressRemove(inProgessName, listInProgress)
					}
				})
				.catch((error) => {
					inProgressRemove(inProgessName, listInProgress)
					errorHandling(
						error,
						"récupérer le contenu de l'actualité",
						GET_BLOG,
					)
				})
		}
	} else {
		return async () => {}
	}
}

export const updateTextFromBlog = (blogId, textId, text, setSuccess) => {
	if (!inProgressCheck(UPDATE_TEXT_FROM_BLOG, listInProgress)) {
		inProgressAdd(UPDATE_TEXT_FROM_BLOG, listInProgress)
		return async (dispatch) => {
			return axios
				.put(`${baseUrl}/${blogId}/${textId}`, text, {
					headers: {
						'Content-Type': 'text/html; charset=utf-8',
					},
					withCredentials: true,
				})
				.then((result) => {
					if (result.status === 200) {
						setSuccess(true)
						dispatch({
							type: UPDATE_TEXT_FROM_BLOG,
							payload: {
								blogId: blogId,
								textId: textId,
								text: text,
							},
						})
					}
				})
				.catch((error) => {
					if (error.response.status === 400) {
						return error.response.data
					}
					errorHandling(
						error,
						'modifier un texte dans une actualité',
						UPDATE_TEXT_FROM_BLOG,
					)
				})
				.finally(() => {
					inProgressRemove(UPDATE_TEXT_FROM_BLOG, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

const errorHandling = (error, title, actionType) => {
	let errorTitle = ''
	let errorMessage = ''

	if (error.response) {
		switch (error.response.status) {
			case 404:
				errorTitle = 'Erreur pour ' + title
				errorMessage = "La requête n'existe pas"
				break

			case 500:
				errorTitle = 'Erreur serveur pour ' + title
				errorMessage = error.response.data.message
				break

			default:
				errorTitle = 'Erreur pour ' + title
				errorMessage = 'Unknown error'
				break
		}
	} else if (error.request) {
		errorTitle = 'Erreur serveur pour ' + title
		errorMessage = 'Le serveur ne répond pas'
	} else {
		errorTitle = 'Erreur pour ' + title
		errorMessage = error.message
	}

	throw new Error(errorMessage, {
		cause: { type: actionType, title: errorTitle },
	})
}
