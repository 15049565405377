import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons'

import Img from '../Img'
import ImageInfo from '../ImageInfo'

import './index.scss'

function BannerTestimonie({
	pageId,
	entityId,
	name,
	commentary,
	flippedImage,
}) {
	return (
		<div className="SOUKIASSIAN-BannerTestimonie-container">
			<div className="SOUKIASSIAN-BannerTestimonie col-max-blocked">
				<div className="SOUKIASSIAN-BannerTestimonie-imgTitle">
					{pageId && entityId && (
						<div className="SOUKIASSIAN-BannerTestimonie-imgTitle-imgContainer">
							<Img
								pageId={pageId}
								entityId={entityId}
								noInfoData
								flipped={flippedImage}
							/>
						</div>
					)}
					<ImageInfo
						imgData="GET"
						pageId={pageId}
						entityId={entityId}
					/>
					<h3>{name}</h3>
				</div>

				<p>
					<FontAwesomeIcon
						icon={faQuoteLeft}
						className="SOUKIASSIAN-BannerTestimonie-quoteLeft"
					/>
					{commentary}
					<FontAwesomeIcon
						icon={faQuoteRight}
						className="SOUKIASSIAN-BannerTestimonie-quoteRight"
					/>
				</p>
			</div>
		</div>
	)
}

BannerTestimonie.propTypes = {
	pageId: PropTypes.number,
	entityId: PropTypes.number,
	name: PropTypes.string,
	commentary: PropTypes.any.isRequired,
	flippedImage: PropTypes.bool.isRequired,
}

BannerTestimonie.defaultProps = {
	flippedImage: false,
}

export default BannerTestimonie
