import { useState, Fragment } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { registrationDataNameFormat } from '../../../utils'

import {
	ADD_REGISTRATION,
	addRegistration,
	UPDATE_REGISTRATION,
	updateRegistration,
} from '../../../actions/registration.action'

import Button from '../../Button'
import Input from '../../Input'
import Success from '../../Success'
import Error from '../../Error'

import '../../RegistrationList/index.scss'

function RegistrationForm({
	registrationId,
	registrationTypeId,
	dataNameList,
	dataValueList,
	setIsEdit,
}) {
	const [registrationSending, setRegistrationSending] = useState(false)
	const [doReset, setDoReset] = useState(false)
	const [errors, setErrors] = useState({})
	const [addRegistrationSuccess, setAddRegistrationSuccess] = useState(false)

	const dispatch = useDispatch()

	const errorHandling = (error, errorList) => {
		let errorContainer = { ...errorList, message: error.message }

		if (
			error.cause?.type === ADD_REGISTRATION ||
			error.cause?.type === UPDATE_REGISTRATION
		) {
			errorContainer.title = error.cause.title
		} else {
			errorContainer.title = 'Erreur des inscriptions'
		}
		setErrors(errorContainer)
	}

	const registrationHandling = function (e) {
		e.preventDefault()

		if (!registrationSending) {
			setRegistrationSending(true)
			let errorList = {}

			let registration = new FormData(e.target)

			registration.append('id_registration_type', registrationTypeId)

			if (registrationId !== 0) {
				const pairList = registration.entries()
				registration = {}

				for (const pair of pairList) {
					registration[pair[0]] = pair[1]
				}
			}

			dispatch(
				registrationId === 0
					? addRegistration(
							registration,
							setAddRegistrationSuccess,
							setDoReset,
						)
					: updateRegistration(
							registrationId,
							registration,
							setIsEdit,
						),
			)
				.then((error) => {
					setErrors({})
					if (error) {
						errorList = error
						setErrors(error)
					}
				})
				.catch((e) => {
					errorHandling(e, errorList)
				})
				.finally(() => {
					setRegistrationSending(false)
				})
		}
	}

	return (
		<form
			className="SOUKIASSIAN-RegistrationList-Form"
			onSubmit={registrationHandling}
		>
			<span>
				{dataNameList.map((data_name, key) => (
					<Fragment
						key={`${registrationDataNameFormat(data_name)}${key}`}
					>
						{data_name === 'Date' ? (
							<>
								<Input
									className="col-12"
									type="date"
									name="event_date"
									message="Date premier jour"
									title="Date premier jour"
									value={
										dataValueList.event_date
											? dataValueList.event_date
											: ''
									}
									errorDirect={
										errors['event_date']
											? errors['event_date']
											: null
									}
									reset={[doReset]}
								/>
								<Input
									className="col-12"
									type="textarea"
									name="data_Date"
									message="Date complète"
									title="Date complète"
									value={
										dataValueList['Date']
											? dataValueList['Date']
											: ''
									}
									reset={[doReset, setDoReset]}
								/>
							</>
						) : (
							<Input
								className="col-12"
								type="textarea"
								name={`data_${registrationDataNameFormat(data_name)}`}
								message={data_name}
								title={data_name}
								value={
									dataValueList[
										registrationDataNameFormat(data_name)
									]
										? dataValueList[
												registrationDataNameFormat(
													data_name,
												)
											]
										: ''
								}
								errorDirect={
									errors[
										registrationDataNameFormat(data_name)
									]
										? errors[
												registrationDataNameFormat(
													data_name,
												)
											]
										: null
								}
								reset={[doReset, setDoReset]}
							/>
						)}
					</Fragment>
				))}
			</span>
			<span>
				{errors.id_registration_type && (
					<Error
						className="col-11 center"
						error={errors.id_registration_type}
						deleteError={() => {
							setErrors({})
						}}
					/>
				)}
				{addRegistrationSuccess ? (
					<Success
						className="col-11 center"
						success="L'inscription a été créée."
					/>
				) : (
					(errors.message || errors.title) && (
						<Error
							className="col-11 center"
							error={`${errors.title} : ${errors.message}`}
							deleteError={() => {
								setErrors({})
							}}
						/>
					)
				)}

				<div className="align">
					{registrationId !== 0 && (
						<Button
							isDisabled={registrationSending}
							onClick={() => setIsEdit(0)}
						>
							RETOUR
						</Button>
					)}
					<Button isDisabled={registrationSending}>EVOYER</Button>
				</div>
			</span>
		</form>
	)
}

RegistrationForm.propTypes = {
	registrationId: PropTypes.number,
	registrationTypeId: PropTypes.number,
	dataNameList: PropTypes.array,
	dataValueList: PropTypes.object,
	setIsEdit: PropTypes.func,
}

RegistrationForm.defaultProps = {
	registrationId: 0,
	dataValueList: {},
	setIsEdit: () => 0,
}

export default RegistrationForm
