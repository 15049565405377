import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { addClient } from '../../../actions/client.action'

import Button from '../../Button'
import Input from '../../Input'
import Select from '../../Select'

function AddClientRow({ setClientFilter }) {
	const [doReset, setDoReset] = useState(false)
	const [errors, setErrors] = useState({})
	const dispatch = useDispatch()

	let actualIdShift = 0

	const addClientHandling = function (e) {
		const dataContainer = e.target.parentElement.parentElement

		let dataList = {}

		let dataNameList = [
			'type',
			'firstName',
			'secondName',
			'phone',
			'email',
			'address',
			'zip',
			'city',
			'country',
			'note',
		]
		dataNameList.forEach((dataName) => {
			dataList[dataName] = dataContainer.querySelector(
				`[name="${dataName}"]`,
			).value
		})

		dispatch(addClient(dataList, setDoReset, setClientFilter)).then(
			(errors) => {
				if (errors) {
					setErrors(errors)
				}
			},
		)
	}

	const filterShowClient = function (dataName, value) {
		actualIdShift++

		const timeShift = (idShift) => {
			setTimeout(() => {
				if (actualIdShift === idShift) {
					setClientFilter({ dataName: dataName, value: value })
				}
			}, 1000)
		}

		timeShift(actualIdShift)
	}

	return (
		<tr className="SOUKIASSIAN-AdminClientTable-AddClientRow">
			<td>
				<Button onClick={addClientHandling}>Ajouter</Button>
				<Button onClick={() => setDoReset(true)}>Vider</Button>
			</td>
			<td>0</td>
			<td>
				<Select
					name="type"
					multiple
					options={[
						{ title: 'Tous publics' },
						{ title: 'Entreprise' },
					]}
					reset={[doReset]}
					admin
				/>
			</td>
			<td>
				<Input
					name="firstName"
					title="Prénom"
					errorDirect={errors.firstName ? errors.firstName : null}
					onChange={filterShowClient}
					reset={[doReset]}
					admin
				/>
			</td>
			<td>
				<Input
					name="secondName"
					title="Nom"
					errorDirect={errors.secondName ? errors.secondName : null}
					onChange={filterShowClient}
					reset={[doReset]}
					admin
				/>
			</td>
			<td>
				<Input
					name="phone"
					title="Téléphone"
					message="Séparé par un ;"
					errorDirect={errors.phone ? errors.phone : null}
					onChange={filterShowClient}
					reset={[doReset]}
					admin
				/>
			</td>
			<td>
				<Input
					name="email"
					title="Email"
					message="Séparé par un ;"
					errorDirect={errors.email ? errors.email : null}
					onChange={filterShowClient}
					reset={[doReset]}
					admin
				/>
			</td>
			<td>
				<Input
					name="address"
					title="Numéro et Adresse"
					errorDirect={errors.address ? errors.address : null}
					onChange={filterShowClient}
					reset={[doReset]}
					admin
				/>
				<Input
					name="zip"
					title="Code postal"
					errorDirect={errors.zip ? errors.zip : null}
					onChange={filterShowClient}
					reset={[doReset]}
					admin
				/>
				<Input
					name="city"
					title="Ville"
					errorDirect={errors.city ? errors.city : null}
					onChange={filterShowClient}
					reset={[doReset]}
					admin
				/>
				<Input
					name="country"
					title="Pays"
					errorDirect={errors.country ? errors.country : null}
					onChange={filterShowClient}
					reset={[doReset]}
					admin
				/>
			</td>
			<td>
				<Input
					type="textarea"
					name="note"
					title="Note"
					errorDirect={errors.note ? errors.note : null}
					onChange={filterShowClient}
					reset={[doReset, setDoReset]}
					admin
				/>
			</td>
		</tr>
	)
}

export default AddClientRow
