import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import ImageTextContainer from '../../../components/ImageTextContainer'
import ImageText from '../../../components/ImageTextContainer/ImageText'
import BannerTestimonie from '../../../components/BannerTestimonie'
import BannerImageText from '../../../components/BannerImageText'
import PercentTextContainer from '../../../components/PercentTextContainer'
import PercentText from '../../../components/PercentTextContainer/PercentText'

import './index.scss'

function Method() {
	const pageId = 7

	return (
		<div className="SOUKIASSIAN-Method">
			<Header
				type="finalPage"
				title="LA PRÉSENTATION DE LA MÉTHODE L.C.C. ©"
			/>

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={1}
					title="La méthode L.C.C. ©"
					text={
						<>
							<span>
								créée par Christophe Soukiassian entre 2009 et
								2016, la méthode introduit une approche
								innovante en communication non blessante.
							</span>
							<span>
								En 2014, elle évolue pour inclure la
								communication d'interprétation et de
								transformation des états internes inconscients.
							</span>
							<span>
								En 2016, la méthode atteint son apogée avec la
								gestion des états inconscients par les
								ressentis.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={2}
					title="La méthode L.C.C. © repose sur,"
					text={
						<>
							<span>
								des années d'études anthropologiques en Espagne
								et en France, ainsi que sur des recherches
								menées avec des personnes de différents
								continents.
								<br />
								Elle s'applique naturellement à notre langage du
								quotidien.
							</span>
						</>
					}
					left
				/>
				<ImageText
					pageId={pageId}
					entityId={3}
					title="La méthode L.C.C. © offre"
					text={
						<>
							<span>
								une compréhension unique de notre monde
								intérieur en relation avec notre environnement,
								facilitant ainsi l'élévation du potentiel
								humain.
								<br />
								<br />
								Les participants développent des capacités
								naturelles en communication, améliorant leur
								bien-être général.
								<br />
								<br />
								La méthode L.C.C. © les aide à aboutir à la
								concrétisation de leurs objectifs et à la
								réalisation de leurs projets dans tous les
								domaines.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={4}
					title="Les trois concepts clés de communication en coaching"
					text={
						<>
							<span>
								<b>E.E.N.B.</b> © - Communication Intuitive d'
								<b>É</b>coute et d'<b>E</b>xpression <b>N</b>on-
								<b>B</b>lessantes
								<br />
								<br />
								<b>I.T.</b> © - Communication Intuitive d'
								<b>I</b>nterprétation et de <b>T</b>
								ransformation
								<br />
								<br />
								<b>R.C.G.</b> © - Communication Intuitive de
								gestion des états inconscients
							</span>
							<span>La méthode est accessible à tous</span>
						</>
					}
					left
				/>
			</ImageTextContainer>

			<PercentTextContainer
				title={
					<>
						QUELQUES ÉTUDES SCIENTIFIQUES
						<br />
						sur 10 ans d'activité de l'école avec la méthode L.C.C.
						©
					</>
				}
				description={
					<>
						Réussites significatives observées
						<br />
						(résolutions de problématiques et accomplissements
						d'objectifs)
					</>
				}
				textBottom={
					<>
						<b>
							L'École a accompagné avec succès de nombreuses
							personnes,
						</b>
						<br />
						jeunes et adultes dans tous les domaines de vie,
						spécialistes de la relation d'aide en développement
						d'activité et divers professionnels d'entreprises.
					</>
				}
			>
				<PercentText
					percent="95%"
					text="95% de réussite scolaire, incluant une insertion professionnelle en cohérence avec l'orientation souhaitée."
				/>
				<PercentText
					percent="80%"
					text="80% d'amélioration avec des soulagements, du bien-être et  des évolutions positives concernant des malaises, un burn-out, des maladies, etc."
				/>
				<PercentText
					percent="98%"
					text="98% d'évolution de la qualité de vie avec une gestion efficace du stress."
				/>
				<PercentText
					percent="74%"
					text="74% atteignent l'équilibre dans tous les domaines : personnel, conjugal, familial, social, et professionnel avec une dimension humaine spirituelle."
				/>
				<PercentText
					percent="79%"
					text="79% des personnes souhaitant devenir coachs déclarent et développent leur activité professionnelle."
				/>
				<PercentText
					percent="99%"
					text="99% de résolution des conflits relationnels en entreprise."
				/>
			</PercentTextContainer>

			<BannerTestimonie
				pageId={pageId}
				entityId={5}
				name="Sylvie Bazin"
				commentary={
					<>
						Superbe méthode, je suis plus en harmonie avec moi-même,
						et avec mon environnement. J'intègre facilement les
						outils que j'applique dans ma communication quotidienne.
						<br />
						<br />
						J'ose traverser mes difficultés. Je trouve plus
						facilement mes orientations. Je développe mon potentiel
						et je réussis humainement en conscience tout ce que
						j'entreprends.
					</>
				}
			/>

			<BannerImageText
				pageId={pageId}
				entityId={6}
				text={
					<>
						“L'épanouissement relationnel donne à chacun la
						possibilité de trouver son propre langage pour atteindre
						ses propres succès”
						<br />
						<br />
						-Christophe Soukiassian-
					</>
				}
			></BannerImageText>

			<Footer />
		</div>
	)
}

export default Method
