import { useState } from 'react'
import { useDispatch } from 'react-redux'

import {
	NEW_PASSWORD_CLIENT,
	newPasswordClient,
} from '../../actions/client.action'

import PropTypes from 'prop-types'

import Button from '../Button'
import Input from '../Input'
import Success from '../Success'
import Error from '../Error'

import './index.scss'

function ClientNewPassword({ noOldPassword, email, token }) {
	const [doReset, setDoReset] = useState(false)
	const [newPasswordSending, setNewPasswordSending] = useState(false)
	const [errors, setErrors] = useState({})
	const [newPasswordSuccess, setNewPasswordSuccess] = useState(false)
	const dispatch = useDispatch()

	const errorHandling = (error) => {
		let errorContainer = { ...errors, message: error.message }

		if (error.cause?.type === NEW_PASSWORD_CLIENT) {
			errorContainer.title = error.cause.title
		} else {
			errorContainer.title = 'Erreur mot de passe'
		}
		setErrors(errorContainer)
	}

	const newPasswordHandling = function (e) {
		e.preventDefault()

		if (!newPasswordSending) {
			setNewPasswordSending(true)
			setErrors({})

			const formData = new FormData(e.target)

			const oldPassword = formData.get('oldPassword')
			const newPassword = formData.get('newPassword')

			dispatch(
				newPasswordClient(
					email,
					token,
					oldPassword,
					newPassword,
					setNewPasswordSuccess,
				),
			)
				.then((errors) => {
					if (errors) {
						setErrors(errors)
					}
				})
				.catch(errorHandling)
				.finally(() => {
					setNewPasswordSending(false)
				})
		}
	}

	return (
		<form
			className="SOUKIASSIAN-ClientNewPassword"
			onSubmit={newPasswordHandling}
		>
			<div className="SOUKIASSIAN-ClientNewPassword-row col-11">
				{!noOldPassword && (
					<Input
						className="col-5 col-xs-12 col-xxs-12"
						type="password"
						name="oldPassword"
						message="Mot de passe actuel"
						title="Votre mot de passe"
						minLength="7"
						maxLength="255"
						errorDirect={
							errors.oldPassword ? errors.oldPassword : null
						}
						reset={[doReset]}
					/>
				)}
				<Input
					className="col-5 col-xs-12 col-xxs-12"
					type="password"
					name="newPassword"
					message="Nouveau mot de passe (7 caractères minimums)"
					title="Votre nouveau mot de passe"
					minLength="7"
					maxLength="255"
					autoComplete="new-password"
					errorDirect={errors.newPassword ? errors.newPassword : null}
					reset={[doReset, setDoReset]}
				/>
			</div>
			{(errors.message || errors.title) && (
				<Error
					className="col-11"
					error={`${errors.title} : ${errors.message}`}
					deleteError={() => {
						setErrors({})
					}}
				/>
			)}
			{newPasswordSuccess && (
				<Success
					className="col-11"
					success={
						<>
							Votre mot de passe a été mis à jour
							<br />
							<br />
							{noOldPassword && (
								<>
									Vous pouvez à présent continuer votre
									navigation.
									<br />
									Si vous venez de créer un compte, n'hésitez
									pas à retourner sur l'onglet où vous avez
									créé le compte, pour vous y connecter et
									continuer votre démarche.
								</>
							)}
						</>
					}
				/>
			)}
			<Button
				className="col-xxs-11 col-xs-11 col-4"
				isDisabled={newPasswordSending}
			>
				CHANGER LE MOT DE PASSE
			</Button>
		</form>
	)
}

ClientNewPassword.propTypes = {
	noOldPassword: PropTypes.bool.isRequired,
	email: PropTypes.string,
	token: PropTypes.string,
}

ClientNewPassword.defaultProps = {
	noOldPassword: false,
}

export default ClientNewPassword
